<template>
    <div class="badge-vanilla" @click="onClick">
        <div class="wrapper">
            <img class="icon" src="@/assets/images/app/heart.png" />
            <span class="vanilla">{{ heart }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BadgeHeart',
    computed: {
        heart() {
            return (this.$store.getters.badges || {}).heart
        },
    },
    methods: {
        onClick() {
            this.$emit('click')
        },
    },
}
</script>

<style lang="scss" scoped>
.badge-vanilla {
    overflow: hidden;
    border-radius: 6px;

    .wrapper {
        display: flex;
        align-items: center;
        color: $grey-08;
        font-size: 14px;

        @include f-medium;

        .icon {
            margin-right: 4px;
            text-align: center;
            display: inline-block;
            width: 15px;
            flex: none;
        }

        .vanilla {
            color: $grey-08;
            flex: auto;
            @include spoqa-f-medium;
        }
    }
}
</style>
