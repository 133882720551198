<template>
    <div class="empty f-bold">
        <div>
            <div class="icon-wrapper m-b-16">
                <img v-if="image.type === 'image'" :src="image.url" alt="" />
                <i v-else class="f-39 material-icons">{{ image.url }}</i>
            </div>
            <div class="empty-title" v-html="title" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlankPage',
    props: {
        image: Object,
        title: String,
    },
}
</script>

<style scoped lang="scss">
.empty {
    text-align: center;
    height: 100%;
    @include spoqa-f-regular;
    @include center;
    color: $grey-06;
    font-size: 14px;

    i {
        color: $grey-04;
    }
    img {
        width: 39px;
        height: 39px;
    }
}
</style>
