<template>
    <div class="other-app">
        <div class="question" v-html="question" />
        <div class="desc" v-html="'* 복수 선택 가능'" />
        <div class="options"
             v-for="option in options"
             :key="option.id"
             :class="{ selected: selected.includes(option.id) }"
             @click="onClick(option.id)"
             v-html="option.title">
        </div>
        <div class="bottom-cont">
            <div class="button" :class="{ activated: selected.length > 0 }" v-html="'완료'" @click="onNext()"></div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'OtherAppPage',
    data: () => ({
        question: '사용해본 서비스를<br>모두 선택해 주세요!',
        options: [],
        selected: [],
    }),
    mounted() {
        this.options = (this.$store.getters.categories || []).filter(c => c.category === 'other_app')
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_UsedServicePage',
            },
        })
    },
    beforeDestroy() {
    },
    methods: {
        onClick(id) {
            if (this.selected.includes(id)) {
                const idx = this.selected.indexOf(id)
                this.selected.splice(idx, 1)
            } else {
                this.selected.push(id)
            }
            // this.selected = id
        },
        async onNext() {
            if (this.selected.length === 0) this.$toast.error('답변을 선택해주세요!')
            else {
                try {
                    this.$loading(true)
                    const referrer = this.$route.params.referrer
                    const otherApp = this.selected
                    const payload = {
                        referrer,
                        other_app: otherApp,
                    }
                    await userService.referrers(payload)
                    this.$toast.success('가입 완료! 이제 반쪽을 찾아보세요!')
                    this.$router.push({ name: 'FeedPage' })
                } catch (e) {
                    this.$toast.error(e.data)
                } finally {
                    this.$loading(false)
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    .other-app {
        padding-bottom: 80px;

        .question {
            margin: 0 20px 8px 20px;
            font-size: 24px;
            line-height: normal;
            color: black;
            font-weight: 500;
            @include f-medium;
        }

        .desc {
            margin: 0 20px 40px 20px;
            color: $purple-primary;
            font-size: 14px;

        }

        .options {
            margin: 0 20px 12px 20px;
            height: 48px;
            line-height: 48px;
            border: solid 1px $grey-02;
            border-radius: 8px;
            font-size: 15px;
            color: $grey-09;
            text-align: center;

            &.selected {
                color: $purple-primary;
                border: solid 1px $purple-primary;
            }
        }

        .bottom-cont {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 80px;
            padding: 16px;
            border-top: solid 1px $grey-02;

            .button {
                width: 100%;
                height: 48px;
                font-size: 15px;
                text-align: center;
                line-height: 48px;
                background: $grey-02;
                color: $grey-05;
                border-radius: 8px;
                background: white;

                &.activated {
                    background: $purple-primary;
                    color: white;
                }
            }
        }
    }
</style>
