<template>
    <div class="lounge">
        <button v-show="selectedTab === 'all'" class="edit-btn" @click="onClickMakePost">
            <i class="material-icons m-b-6">edit</i>
            <span>글쓰기</span>
        </button>
        <nav class="tabs">
            <div
                class="tab"
                :class="{ selected: item.selected }"
                v-for="item in tabItems"
                :key="item.label"
                @click="onClickTab(item)"
            >
                <div class="content">
                    <p class="label" v-html="$translate(item.label)" />
                    <div v-if="item.type !== 'all' && showNewBadge(item.type)" class="new-badge" />
                </div>
            </div>
        </nav>
        <main class="main" v-if="posts.length" @scroll="onScroll">
            <div class="posts">
                <img v-if="selectedTab === 'all'" class="lounge-banner" @click="onClickBanner()" :src="banner.src" />
                <lazy-component
                    :is="`LoungePost`"
                    v-for="(post, idx) in posts"
                    :key="idx"
                    :post="post"
                    :type="selectedTab"
                    @click.native="onClickPost(post)"
                />
            </div>
        </main>
        <main class="blank" v-else>
            <BlankPage :image="blankProps.image" :title="blankProps.title" />
        </main>
    </div>
</template>

<script>
import loungeService from '@/services/lounge'
import LoungePost from '@/routes/lounge/components/LoungePost'

export default {
    name: 'LoungePage',
    components: {
        LoungePost,
    },
    data: () => ({
        timer: null,
        selectedTab: 'all',
        tabItems: [
            {
                type: 'all',
                label: 'ALL',
                selected: true,
                event: 'All',
            },
            {
                type: 'myPost',
                label: 'MY_POST',
                selected: false,
                event: 'Mine',
            },
            {
                type: 'interested',
                label: 'INTERESTED',
                selected: false,
                event: 'Interest',
            },
        ],
        banner: {
            src: require('@/assets/images/lounge/lounge_banner.png'),
            url: 'https://bit.ly/3t19rT8',
        },
        scrollLimit: 20,
        lastId: null,
    }),
    computed: {
        blankProps() {
            if (this.selectedTab === 'all') {
                return {
                    image: {
                        type: 'image',
                        url: require('@/assets/images/lounge/empty-lounge.png'),
                    },
                    title: '게시된 글이 없어요.',
                }
            } else if (this.selectedTab === 'myPost') {
                return {
                    image: {
                        type: 'image',
                        url: require('@/assets/images/lounge/empty-lounge.png'),
                    },
                    title: '내가 쓴 글이 없어요.',
                }
            } else {
                return {
                    image: {
                        type: 'image',
                        url: require('@/assets/images/lounge/empty-lounge.png'),
                    },
                    title: '내가 신청한 글이 없어요.',
                }
            }
        },
        lounge() {
            return this.$store.getters.lounge
        },
        allPosts() {
            return this.lounge.allPosts || []
        },
        myPosts() {
            return this.lounge.myPosts || []
        },
        interestedPosts() {
            return this.lounge.interestedPosts || []
        },
        posts() {
            if (this.selectedTab === 'all') return this.allPosts
            if (this.selectedTab === 'myPost') return this.myPosts
            if (this.selectedTab === 'interested') return this.interestedPosts

            return []
        },
        hasUncheckedMyPost() {
            if (!this.myPosts.length) return false

            // 활성 유저인지 체크
            const isActiveUser = request => request.enabled && !request.hidden && !request.is_dormant

            return this.myPosts.some(post => {
                if ((post.requests || []).length === 0) return false

                const activeReqs = (post.requests || []).filter(req => isActiveUser(req))
                return activeReqs.some(p => p.accept_status === 'request')
            })
        },
        hasUncheckedInterestedPost() {
            if (!this.interestedPosts.length) return false

            return this.interestedPosts.some(post => {
                const { host_checked: hostChecked, participant_checked: participantChecked } = post.my_request
                return hostChecked === true && participantChecked === false
            })
        },
    },
    async created() {
        this.$bus.$on('completeMakePost', () => {
            this.onClickTab(this.tabItems.find(item => item.type === 'myPost'))
        })
    },
    async mounted() {
        await this.init()
    },
    beforeDestroy() {
        this.$bus.$off('completeMakePost')
    },
    methods: {
        async init() {
            localStorage.setItem('enter-lounge-page', 'true')

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Enter_LoungeFeedPage`,
                },
            })
            try {
                if (!this.posts.length) {
                    this.$loading(true)
                    await this.$store.dispatch('loadLoungePosts')
                }
                const dom = document.querySelector('.main')
                dom.scrollTo({ top: this.$store.getters.loungeLastScrollTop })
            } catch (e) {
                console.log(e)
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        onClickTab(item) {
            item.selected = true

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Click_Lounge_${item.event}_Tab`,
                },
            })

            this.tabItems.forEach(tab => {
                if (tab.type !== item.type) {
                    tab.selected = false
                }
            })

            this.selectedTab = item.type
        },
        onClickPost(post) {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Click_Lounge_Feed`,
                },
            })
            this.$stackRouter.push({
                name: 'LoungePostDetailPage',
                props: {
                    post,
                    from: this.selectedTab,
                },
            })

            loungeService.updateViewCount(post.id)
        },
        onClickMakePost() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Click_Lounge_Feed_CTA`,
                },
            })
            if (!this.$isVerifiedUser()) {
                this.$modal
                    .basic({
                        body: '프로필 등록 완료 후 신청하실 수 있습니다.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(() => {
                        this.$goProfileRegister()
                    })
                return
            }

            this.$stackRouter.push({
                name: 'LoungeFeedCreatePage',
            })
        },
        showNewBadge(type) {
            if (type === 'myPost') {
                return this.hasUncheckedMyPost
            }
            if (type === 'interested') {
                return this.hasUncheckedInterestedPost
            }
        },
        onClickBanner() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `LoungeFeedPage_Click_Instruction`,
                },
            })

            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: this.banner.url,
            })
        },
        throttle(handler, delay) {
            if (!this.timer) {
                this.timer = setTimeout(() => {
                    this.timer = null
                }, delay)

                handler()
            }
        },
        onScroll({ target }) {
            this.scrollLimit = this.posts.length
            if (this.posts.length < this.scrollLimit) return

            const { scrollHeight, clientHeight, scrollTop } = target
            this.$store.commit('setLoungeLastScrollTop', scrollTop <= 0 ? 0 : scrollTop)

            if (scrollTop + clientHeight > scrollHeight - 300) {
                this.throttle(async () => {
                    await this.loadMore()
                }, 400)
            }
        },
        async loadMore() {
            this.$loading(true)
            this.lastId = this.posts[this.posts.length - 1].id

            try {
                let res
                if (this.selectedTab === 'all') {
                    res = await loungeService.all(15, this.lastId)
                    this.$store.commit('setLoungeAllPosts', [...this.posts, ...res])
                } else if (this.selectedTab === 'interested') {
                    res = await loungeService.interest(15, this.lastId)
                    this.$store.commit('setLoungeInterestedPosts', [...this.posts, ...res])
                } else {
                    res = await loungeService.mine(15, this.lastId)
                    this.$store.commit('setLoungeMyPosts', [...this.posts, ...res])
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            } finally {
                this.$loading(false)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.lounge {
    //position: relative;
    .blank {
        height: 80%;
        @include center;
    }
    .main {
        height: calc(100vh - 120px - 50px);
        overflow-y: auto;

        .posts {
            .lounge-banner {
                width: calc(100vw - 32px);
                margin: 16px 16px 0 16px;
                border-radius: 12px;
            }
        }
    }
    .tabs {
        display: flex;
        width: 100%;
        position: sticky;
        top: 0;
        background-color: white;

        & > .tab {
            height: 48px;
            flex: 1;

            @include center;

            &.selected {
                color: black;
                border-color: black;
            }

            .content {
                position: relative;
            }

            .new-badge {
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: $pink-deep;
                top: 0;
                right: -6px;
            }
        }
    }
    .edit-btn {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: transparent;
        bottom: 71px;
        right: 16px;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1), 2px 2px 8px 0 rgba(0, 0, 0, 0.15);
        background-image: linear-gradient(135deg, #ba90ff, $purple-primary);

        .material-icons {
            font-size: 18px;
            color: white;
        }
        span {
            font-size: 12px;
            color: white;
            @include spoqa-f-bold;
        }
    }
}
</style>
