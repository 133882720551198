<template>
    <div @click.stop="onClickButton" class="accept-button-container">
        <div class="accept-button">
            {{ cta }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'AcceptButton',
    props: {
        like: Object && null,
    },
    computed: {},
    watch: {
        like: {
            deep: true,
            handler() {
                this.initBtnStatus()
            },
        },
    },
    data: () => ({
        cta: '',
        disable: false,
    }),
    methods: {
        initBtnStatus() {
            if (!this.like) {
                this.cta = '호감 보내기'
            } else {
                // 나한테 메시지가 왔다면
                if (this.like.target_id === this.$store.getters.me.id) {
                    if (this.like.accepted) {
                        this.cta = '채팅방 이동'
                    } else {
                        this.cta = '호감 수락하기'
                    }
                } else {
                    if (this.like.accepted) {
                        this.cta = '채팅방 이동'
                    } else {
                        this.cta = '호감 보냄'
                        this.disable = true
                    }
                }
            }
        },
        onClickButton() {
            if (this.disable) return
            this.$unregisterBackHandler()
            this.$emit('click')
        },
    },
    mounted() {
        this.initBtnStatus()
    },
}
</script>

<style scoped lang="scss">
.accept-button-container {
    width: 100%;
    height: 48px;

    .accept-button {
        padding: 10px 0;
        height: 100%;
        color: white;
        background: $purple-primary;
        text-align: center;
        border-radius: 8px;
        @include f-medium;
        @include center;

        &.clicked {
            background: $grey-02;
            color: $grey-05;
            @include f-regular;
        }
    }
}
</style>
