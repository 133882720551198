// ROUTE-SPECIFIC
import center from './routes/center'
import myPage from './routes/my-page'
import store from './routes/store'
import lounge from './routes/lounge.json'

import common from './common'
import messages from './messages'
import modals from './modals'
import profiles from './profiles'
import toast from './toasts'
import titles from './titles'

const result = {}
const translations = [
    common,
    messages,
    modals,
    profiles,
    toast,
    titles,
    // ROUTE-SPECIFIC
    center,
    myPage,
    store,
    lounge,
]
translations.forEach(json =>
    Object.keys(json).forEach(key => {
        result[key] = json[key]
    })
)

export default result
