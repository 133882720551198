<template>
    <div class="user-profile">
        <SelfIntroduction v-if="intro" class="section section-intro" :intro="intro" />

        <BasicProfile v-if="profile" class="section" :userId="userId" :profile="profile" />

        <template v-if="user.keywords.length > 0">
            <Keywords v-if="$store.getters.myProfileCanSee.keywords" class="section" :keywords="user.keywords" />
            <div v-else class="keyword-container">
                <h3 class="title c-black m-l-16 m-b-24" v-html="`취향 태그`" />
                <div class="keyword-blur" v-lazy:background="require('@/assets/images/user-detail/keyword_blur.png')">
                    <div class="add-keyword-container">
                        <p class="m-b-12 f-15 f-medium">내 취향을 입력해야 볼 수 있어요</p>
                        <div @click="onClickAddKeyword" class="btn btn-black">취향태그 입력하기</div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import SelfIntroduction from './SelfIntroduction'
import BasicProfile from './BasicProfile'
import Keywords from './Keywords'

export default {
    name: 'UserProfile',
    components: {
        SelfIntroduction,
        BasicProfile,
        Keywords,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        from: String,
    },
    computed: {
        intro() {
            const removeBlank = intro => (intro || '').trim()

            let intro = ''
            if (this.user) intro = this.user.profile.intro

            return removeBlank(intro)
        },
        userId() {
            return this.user.id
        },
        profile() {
            return this.user.profile
        },
    },
    methods: {
        onClickAddKeyword() {
            this.$stackRouter.push({
                name: 'EditProfilePage',
                props: {
                    scrollDown: 2000,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.user-profile {
    .section {
        padding: 32px 16px 0;
        z-index: 4;

        &.self-introduction {
            padding-bottom: 16px;
        }
    }

    hr {
        border-bottom: solid 1px $grey-02;
    }
    .keyword-blur {
        position: relative;
        height: 300px;
        width: auto;
        background-position: center center;
        @include center;

        .add-keyword-container {
            color: $grey-09;
        }
        .btn {
            height: 40px;
        }
    }
}
</style>
