<template>
    <SubProfileOptionSelector :model="'pet'" :nextRoute="() => nextRoute" />
</template>

<script>
export default {
    name: 'PetPage',
    data: () => ({
        nextRoute: 'TagPage',
    }),
    mounted() {},
}
</script>

<style scoped></style>
