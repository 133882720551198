<template>
    <div class="user-detail-photos">
        <Carousel
            :per-page="1"
            :pagination-position="'bottom-overlay'"
            :pagination-size="8"
            :pagination-padding="4"
            :pagination-active-color="'#FFFFFF'"
            :pagination-color="'#808080'"
            :navigateTo="[initialPage, true]"
        >
            <Slide class="p-relative" :key="photo.id" v-for="(photo, idx) in photoArray">
                <div class="overlay top"></div>
                <img
                    class="img-cover"
                    :class="{
                        blur: photos.length && idx >= myProfileCanSee.photoCount,
                    }"
                    :src="photo.url"
                />
                <div class="black-cover" v-if="photos.length && idx >= myProfileCanSee.photoCount">
                    <div class="add-photo-container">
                        <p class="c-white f-medium f-15 m-b-12">내 프로필 사진 수 만큼 볼 수 있어요</p>
                        <div class="btn btn-brd c-black f-medium" @click="onClickAddPhoto">사진 추가하기</div>
                    </div>
                </div>
                <div class="overlay bottom"></div>
            </Slide>
        </Carousel>
    </div>
</template>

<script>
export default {
    name: 'UserDetailPhotos',
    props: {
        photos: Array,
        gender: Number,
    },
    computed: {
        myProfileCanSee() {
            return this.$store.getters.myProfileCanSee
        },
        phoneNumber() {
            return this.$store.getters.me.phone_number
        },
        photoArray() {
            if (this.photos.length) return this.photos

            return [{ url: this.$alternativeImg(this.gender) }, ...this.photos]
        },
    },
    data: () => ({
        initialPage: 0,
    }),
    methods: {
        onClickAddPhoto() {
            this.$stackRouter.push({
                name: 'EditProfilePage',
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.watermark {
    color: rgba(255, 255, 255, 0.15);
    position: absolute;
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
    font-size: 14px;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: rotate(-45deg);

    .triangle {
        display: flex;
        flex-direction: column;

        .one {
            display: flex;
            justify-content: center;
        }

        .two {
            display: flex;
            justify-content: center;
        }
    }
}
.user-detail-photos {
    position: relative;

    .VueCarousel {
        margin-bottom: 8px;

        .overlay {
            height: $header-height;

            &.top {
                z-index: 1;
                top: 0;
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
            }

            &.bottom {
                top: initial;
                z-index: 1;
                bottom: 0;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2) 95%);
            }
        }

        .img-cover {
            width: 100%;
            position: relative;
            //padding-top: 100%;

            &.blur {
                filter: blur(8px);
                -webkit-filter: blur(8px);
            }
        }
        .black-cover {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            background-color: rgba(0, 0, 0, 0.1);
            @include center;
        }

        ::v-deep .VueCarousel-pagination {
            display: initial;
            // bottom: 8px;
            margin-bottom: 16px;
        }
    }
}
</style>
