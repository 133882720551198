<template>
    <div class="customer-center">
        <div class="page-header">
            <div class="title" v-html="$translate('CUSTOMER_CENTER')" />
        </div>
        <div class="section" :key="idx" v-for="(section, idx) in sections">
            <div
                @click="handler(item)"
                class="item flex-row items-center flex-between"
                :key="item.key"
                v-for="item in section"
            >
                <div class="title flex-row">
                    <span v-html="$translate($case.toConst(item.key))" />
                    <span v-if="item.anyChange" class="any-change" />
                </div>
                <i class="material-icons">chevron_right</i>
            </div>
        </div>
        <div class="item m-t-12">
            <div class="title" v-html="$translate('PLUS_FRIEND')" />
            <div class="desc">
                <span v-html="$translate('PLUS_FRIEND_DESC')" />
                <i @click="showInfo" class="material-icons-outlined"> info </i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomerCenterPage',
    computed: {
        sections() {
            return [
                [
                    {
                        key: 'refunds',
                        handler: () => this.$router.push({ name: 'Refund1Page' }),
                    },
                    {
                        key: 'refundHistories',
                        replace: 'RefundHistoriesPage',
                        anyChange: (this.$store.getters.refunds || []).some(
                            refund => [0, 2, 4].indexOf(refund.status) !== -1
                        ),
                    },
                ],
                [
                    {
                        key: 'faq',
                        replace: 'FAQPage',
                    },
                ],
                [
                    {
                        key: 'reportAgents',
                        replace: 'ReportAgentPage',
                    },
                ],
            ]
        },
    },
    mounted() {
        this.$store.dispatch('loadRefunds')
    },
    methods: {
        showInfo() {
            this.$alert(this.$translate('PLUS_FRIEND_BODY'))
        },
        handler(item) {
            if (item.key === 'refunds') {
                item.handler()
                return
            }

            this.$stackRouter.push({
                name: item.replace,
            })
        },
    },
}
</script>
