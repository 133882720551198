<template>
    <div class="new-toggle-container">
        <div class="left-container">
            <div
                class="most-acceptable-button m-r-8"
                @click="onClickMostAcceptableButton()"
                v-html="'수락률 높은 회원'"
            />
            <div class="new-button-container">
                <div class="new-button" @click="onClickNewButton()" v-html="`신규회원`" />
                <div class="new-count" v-if="newFeedsLength !== 0" v-html="newFeedsLength" />
            </div>
        </div>
        <div class="hor-separated" />
        <div class="filter new-button" :class="{ filtered: stateId.length }" @click="onClickFilter">
            <i class="material-icons f-20">filter_list</i>
            <span>필터</span>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'FeedTopButtons',
    props: {
        filterOptions: Object,
        stateId: Array,
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        newUsers() {
            return this.$store.getters.newFeeds
        },
        newFeedsLength() {
            return (this.newUsers || []).filter(u => u.created_at >= this.me.last_seen_new).length
        },
    },
    methods: {
        async onClickMostAcceptableButton() {
            const { heart: currentHeart } = this.$store.getters.badges
            const {
                heart: { show_most_acceptable: showMostAcceptableHeart },
            } = this.$store.getters.settings

            if (currentHeart < showMostAcceptableHeart) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'FeedPage_Click_AcceptHigh',
                        option: {
                            user_id: this.$store.getters.me.id,
                            label: 0,
                        },
                    },
                })
            } else {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'FeedPage_Click_AcceptHigh',
                        option: {
                            user_id: this.$store.getters.me.id,
                            label: 1,
                        },
                    },
                })
            }

            const { res, msg } = await userService.checkMostAcceptable(this.$store.getters.me.id)
            if (msg && msg === 'refund') {
                this.$store.dispatch('loadBadges')
                await this.$modal.basic({
                    title: '안내',
                    body:
                        '죄송합니다. 상대가 휴면 신청을 하였습니다. 사용하신 하트 10개는 환급되었으며, 다시 이용해주세요.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else if (res && res.length > 0) {
                this.$stackRouter.push({ name: 'RecommendFeedPage' })
            } else {
                const idx = await this.$modal.basic({
                    body: '호감 수락률이 높은 회원을 보여줍니다. 하트 10개를 사용합니다.',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })

                if (idx === 1) {
                    if (currentHeart < showMostAcceptableHeart) {
                        const idx = await this.$modal.basic({
                            body: `하트가 ${
                                showMostAcceptableHeart - currentHeart
                            }개 부족합니다. 하트를 충전 후 이용해주세요.`,
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'GO_TO_STORE',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        if (idx === 1) this.$stackRouter.push({ name: 'InAppStorePage', props: { from: 'alert' } })
                    } else {
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'FeedPage_Done_AcceptHigh',
                                option: {
                                    user_id: this.$store.getters.me.id,
                                },
                            },
                        })
                        this.$stackRouter.push({ name: 'RecommendFeedPage' })
                    }
                }
            }
        },
        async onClickNewButton() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FeedPage_Click_NewMember',
                },
            })
            const me = await userService.updateSeenNew(this.$store.getters.me.id)
            this.$store.commit('setMe', { ...this.$store.getters.me, ...{ last_seen_new: me.last_seen_new } })
            this.$stackRouter.push({ name: 'NewFeedPage' })
        },
        async onClickFilter() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FeedPage_Click_Filter',
                },
            })
            const res = await this.$modal.custom({
                component: 'ModalFeedFilter',
                options: {
                    filterOptions: this.filterOptions,
                },
            })

            if (res) {
                this.lastId = null
                this.$store.commit('setFilter', { ...res.filterOptions })

                const feeds = await userService.all(20, '', 1, {
                    state_ids: res.stateId,
                    max_age: res.filterOptions.age[1],
                    min_age: res.filterOptions.age[0],
                    marry_type: res.marryType,
                    feed_sort_type: this.feedSortType,
                })
                this.$store.commit('setFeeds', feeds.users)
                this.$store.commit('setPage', feeds.page)
                this.$store.commit('setFeedSortType', feeds.feed_sort_type)
                const dom = document.querySelector('.feed-box')
                dom.scrollTo(0, 0)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.new-toggle-container {
    height: 36px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    align-items: center;
    margin-bottom: 8px;

    .left-container {
        display: flex;
    }

    .hor-separated {
        width: 1px;
        height: 16px;
        background: $grey-03;
    }

    .most-acceptable-button {
        width: fit-content;
        right: 0;
        height: 36px;
        border-radius: 18px;
        border: solid 1px $grey-03;
        background-color: $grey-01;
        padding: 8px 16px;

        @include spoqa-f-regular;
        font-size: 14px;
        color: black;

        &.off-new-user {
            background-color: rgba(138, 116, 255, 0.08);
        }
    }
    .new-button-container {
        position: relative;
        .new-count {
            position: absolute;
            right: -5px;
            top: 0;
            padding: 2px 6px 2px 6px;
            width: fit-content;
            height: 18px;
            border-radius: 9px;
            background-color: $pink-deep;

            color: white;
            font-size: 11px;
            @include spoqa-f-bold;
            text-align: center;
        }
    }
    .new-button {
        width: fit-content;
        right: 0;
        height: 36px;
        border-radius: 18px;
        border: solid 1px $grey-03;
        background-color: $grey-01;
        padding: 8px 16px;
        @include center;
        @include spoqa-f-regular;
        font-size: 14px;
        color: black;
    }

    .filter {
        @extend .new-button;
        background: white;
        padding: 6px 12px;
        i {
            font-size: 20px;
            margin-right: 4px;
        }

        &.filtered {
            border: 1px solid $purple-primary;
            box-shadow: 0 0 4px 0 rgba(84, 72, 203, 0.15);

            i {
                color: $purple-primary;
            }
        }
    }
}
</style>
