<template>
    <div class="signup additional-profiles height">
        <SignupProgressBar :step="$case.toSnake('height')" />
        <div class="content">
            <div class="title">키</div>
            <div class="body">
                <div class="input-wrapper">
                    <input
                        ref="height"
                        type="number"
                        pattern="\d*"
                        class="flex-fill"
                        placeholder="키를 입력해주세요"
                        v-model="height"
                    />
                    <i v-if="height" class="material-icons flex-wrap" @click="height = null">cancel</i>
                </div>
                <div class="error" v-if="disabled && height">
                    <i class="material-icons">error_outline</i>
                    <p v-html="error"></p>
                </div>
            </div>
        </div>
        <BottomButton :disabled="disabled" @click="next" :label="$translate('NEXT')" />
    </div>
</template>

<script>
const MIN_HEIGHT = 140
const MAX_HEIGHT = 199

export default {
    name: 'HeightPage',
    data: () => ({
        height: null,
        error: `${MIN_HEIGHT}cm ~ ${MAX_HEIGHT}cm 사이의 키를 입력해주세요`,
    }),
    created() {
        this.height = JSON.parse(window.localStorage.getItem('signupData') || '{}').height
    },
    mounted() {
        this.$refs.height.focus()

        this.$hackleClient.onReady(() => {
            const hackleUser = this.$store.getters.hackleUser
            this.$hackleClient.track(`Enter_HeightPage`, hackleUser)
        })

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_HeightPage',
            },
        })
    },
    computed: {
        disabled() {
            return !this.height || this.height < MIN_HEIGHT || this.height > MAX_HEIGHT
        },
    },
    methods: {
        async next() {
            const signupData = JSON.parse(window.localStorage.getItem('signupData') || '{}')
            signupData.height = this.height
            window.localStorage.setItem('signupData', JSON.stringify(signupData))
            this.$router.push({ name: 'ShapesPage' })
        },
    },
}
</script>
