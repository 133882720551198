<template>
    <div class="find-password">
        <div class="page-header">
            <div class="title" v-html="$translate('FIND_PASSWORD')" />
        </div>
        <div class="body">
            <div class="form-control">
                <label v-html="$translate('EMAIL')" />
                <input
                    :placeholder="$translate('PLACEHOLDER_EMAIL')"
                    v-model="email"
                    type="email"
                    @focus="onFocus"
                    @blur="onBlur"
                />
            </div>
            <div class="form-control">
                <label v-html="$translate('NAME')" />
                <input :placeholder="$translate('PLACEHOLDER_NAME')" v-model="name" @focus="onFocus" @blur="onBlur" />
            </div>
            <div class="fb-desc">
                <div>
                    <span class="f-bold" style="color: #1877f2">- 페이스북 계정</span>으로 가입하신 경우, 비밀번호 없이
                    로그인 가능합니다. 로그인 화면에서 페이스북으로 로그인해주세요.
                </div>
                <div>
                    - 가입한 이력이 있는 것 같은데, 회원 정보가 없는 경우 ‘이메일 찾기’ 기능을 사용해주세요. 이메일
                    찾기로 계정이 없다고 나오는 경우, 탈퇴한 계정이오니 재가입 후 이용 부탁드립니다.
                </div>
                <div>- 입력한 정보는 본인 및 회원가입 여부 확인 용도로 사용됩니다.</div>
            </div>
        </div>
        <div v-if="showButton" class="bottom">
            <p class="error m-b-12">오류 발생 시, 카카오톡 플러스친구 <span>"반쪽"</span>으로 문의 바랍니다.</p>
            <button
                @click="onClickVerify"
                class="btn btn-primary btn-block"
                :disabled="disabled"
                v-html="'본인인증하기'"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'FindPasswordPage',
    data: () => ({
        name: null,
        email: null,
        showButton: true,
    }),
    computed: {
        disabled() {
            return !this.name || !this.email
        },
    },
    mounted() {
        this.$bus.$on('resetPassword', this.handleResetPassword)
    },
    methods: {
        onClickVerify() {
            this.$nativeBridge.postMessage({
                action: 'findPassword',
                value: {
                    name: this.name,
                    email: this.email,
                },
            })
        },
        handleResetPassword(url) {
            if (!url) return

            let userId
            let responseId
            let status
            let body
            url = url.replace(/&amp;/g, '&')
            const params = url.split('?')[1].split('&')
            params.forEach(param => {
                const [key, value] = param.split('=')
                if (key === 'id') userId = value
                if (key === 'response_id') responseId = value
                if (key === 'status') status = value
                if (key === 'msg') body = decodeURI(value)
            })

            if (status === 'normal') {
                this.$router.push({
                    name: 'ResetPasswordPage',
                    params: { email: this.email, userId, responseId },
                })
                return
            }

            this.$modal.basic({
                title: 'MODAL_DEFAULT_TITLE',
                body,
                buttons: [
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
        },
        onFocus() {
            this.showButton = false
        },
        onBlur() {
            setTimeout(() => {
                this.showButton = true
            }, 100)
        },
    },
}
</script>
