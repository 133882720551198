<template>
    <div class="my-page">
        <div class="padding" />
        <div class="basic-profile">
            <div class="img-wrapper" @click="onClickMyProfile">
                <div class="img-profile flex-wrap" v-img-cover="this.mainPhoto" />
                <i v-if="profileConfirmed" class="material-icons">search</i>
            </div>
            <div class="profile-cont flex-between">
                <div class="name-nickname" :class="profileConfirmed ? '' : 'm-t-12'">
                    <div v-if="profileConfirmed">
                        <div v-if="me.name">
                            <span class="name" v-html="me.name" />
                            <span class="nickname" v-html="`(${(me.profile || {}).nickname})`" />
                        </div>
                        <div v-else>
                            <span class="name" v-html="(me.profile || {}).nickname" />
                        </div>
                    </div>
                    <div v-else>
                        <span class="name" v-html="'프로필을 등록해주세요'" />
                    </div>
                    <div v-if="profileConfirmed" class="age-height-gender" v-html="ageHeightGender" />
                </div>
                <div
                    class="profile-btn"
                    @click="onEditMyProfile"
                    v-html="profileConfirmed ? '프로필 수정' : '프로필 등록'"
                />
            </div>
        </div>

        <MyPageMenu />
    </div>
</template>

<script>
import MyPageMenu from './components/MyPageMenu'

export default {
    name: 'MyPage',
    components: {
        MyPageMenu,
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        mainPhoto() {
            if (this.$store.getters.mainPhoto) {
                return this.$store.getters.mainPhoto
            } else {
                if (!this.$isVerifiedUser()) {
                    return require('@/assets/images/blank_profile.svg')
                }
                return require(`@/assets/images/no_photo_${this.$store.getters.me.gender}.jpg`)
            }
        },
        isVerifiedUser() {
            return (this.$store.getters.myVerificationBadges || []).some(badge => badge.verification_badge_id === 19)
        },
        gender() {
            return this.me.gender
        },
        ageHeightGender() {
            const { profile } = this.me
            if (!profile) return

            const age = this.$options.filters.asAge(profile.birthday)
            const gender = this.gender === 0 ? '남성' : '여성'

            return `${age}세<span>ㅣ</span>${profile.height}cm<span>ㅣ</span>${gender}`
        },
        profileConfirmed() {
            return (this.me.profile || {}).status !== 'created'
        },
    },
    created() {
        this.$store.dispatch('loadMe')
    },
    methods: {
        onClickMyProfile() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'ProfileMenuPage_Click_Myprofile',
                    option: {
                        status: this.profileConfirmed ? '1' : '0',
                    },
                },
            })
            if (!this.profileConfirmed) return

            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    userId: this.me.id,
                    from: 'MyPage',
                },
            })
        },
        async onEditMyProfile() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'ProfileMenuPage_Click_ProfileUpdate',
                    option: {
                        status: this.profileConfirmed ? '1' : '0',
                    },
                },
            })
            if (this.profileConfirmed) {
                this.$stackRouter.push({
                    name: 'EditProfilePage',
                    props: { initialTab: 'PROFILE' },
                })
            } else {
                this.$goProfileRegister()
            }
        },
        isTrustBadge(badge) {
            return badge.id >= 15 && badge.id <= 19
        },
        trustBadgePercent() {
            return (
                (this.me.verification_badges.filter(b => {
                    return this.isTrustBadge(b) && b.confirmed
                }).length /
                    5) *
                100
            )
        },
    },
}
</script>

<style lang="scss" scoped>
.my-page {
    .padding {
        height: 8px;
    }

    .top-border {
        position: absolute;
        top: 48px;
        height: 1px;
        width: 100vw;
        background-color: $grey-02;
    }

    .hr {
        height: 1px;
        background: $grey-02;

        &.thick {
            height: 8px;
        }
    }

    .basic-profile {
        display: flex;
        align-items: center;
        margin: 16px;
        margin-bottom: 32px;
        border-radius: 20px;

        .img-wrapper {
            position: relative;
            margin-right: 20px;
            flex-grow: 0;

            .img-profile {
                width: 88px;
                height: 88px;
                border: solid 1px $grey-03;
                // background-color: $grey-01;
            }

            .material-icons {
                position: absolute;
                border: solid 2px $grey-01;
                border-radius: 50%;
                background-color: $grey-08;
                color: white;
                font-size: 18px;
                bottom: 0;
                right: 0;
            }
        }

        .profile-cont {
            flex-grow: 1;
            height: 88px;
            display: flex;
            flex-direction: column;

            .name-nickname {
                margin-bottom: 2px;
            }

            .name {
                color: black;
                font-size: 16px;

                @include f-medium;
            }

            .nickname {
                margin-left: 4px;
                font-size: 16px;
                color: $grey-08;

                @include f-demilight;
            }

            .age-height-gender {
                font-size: 14px;
                color: $grey-08;

                ::v-deep span {
                    color: $grey-04;
                }
            }

            .profile-btn {
                border: solid 1px $grey-03;
                border-radius: 8px;
                height: 32px;
                padding-top: 4px;
                padding-bottom: 4px;
                // line-height: 20px;
                text-align: center;
                font-size: 14px;
                color: $grey-09;
            }
        }
    }

    .verify-banner {
        margin: 0 16px 16px 16px;
        padding: 13px 16px;
        height: 48px;
        background-image: linear-gradient(278deg, #ba90ff, $purple-primary);
        border-radius: 12px;

        @include between;

        &.b {
            // display: block;
            background-color: rgba(138, 116, 255, 0.08);
            background-image: none;

            display: block;
            align-items: center;
            justify-content: normal;

            .text {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .achive {
                    float: right;
                    min-width: 150px;
                    text-align: right;
                    .point {
                        // margin-left: 50%;
                        @include spoqa-f-bold;
                    }

                    .arrow {
                        vertical-align: top;
                        color: $purple-primary;
                        margin-right: -6px;
                    }
                }
            }

            .bar {
                &.p0 {
                    width: 0px;
                }
                &.p20 {
                    width: calc((100% + 24px) / 5);
                }
                &.p40 {
                    width: calc((100% + 24px) / 5 * 2);
                }
                &.p60 {
                    width: calc((100% + 24px) / 5 * 3);
                }
                &.p80 {
                    width: calc((100% + 24px) / 5 * 4);
                }
                // &.100 {
                //     width: calc((100% + 24px)/5 * 5);
                // }
                height: 4px;
                width: calc(100% + 24px);
                margin-top: 6px;
                margin-left: -12px;
                border-radius: 2px;
                background-image: linear-gradient(271deg, #b475ff, $purple-primary) !important;
            }
        }
    }
}
</style>
