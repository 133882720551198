<template>
    <div class="marry-state" :class="[marry ? 'marry' : '', size]" v-html="stateText()" />
</template>

<script>
export default {
    name: 'MarryState',
    props: ['marry', 'size', 'from', 'haveChild'],
    methods: {
        stateText() {
            if (this.marry) {
                if (this.haveChild) {
                    if (this.from === 'UserDetailBase') {
                        return `재혼`
                    } else {
                        return '재혼'
                    }
                } else {
                    return '재혼'
                }
            } else {
                return '초혼'
            }
        },
    },
}
</script>

<style scoped lang="scss">
.marry-state {
    padding: 4px 8px;
    color: white;
    font-size: 13px;
    background: #1cc6c6;
    border-radius: 6px;
    @include f-medium;

    &.marry {
        background: #ffb300;
    }

    &.small {
        padding: 2px 8px;
        font-size: 14px;
        border-radius: 8px;
    }
}
</style>
