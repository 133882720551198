<template>
    <div class="tag-page">
        <div class="back-button-wrapper sub-profile">
            <i class="back-button material-icons" @click="onClickClose">{{ 'chevron_left' }}</i>
            <div @click="onSubmit" class="pass-button flex-wrap m-r-16" v-html="'건너뛰기'" />
        </div>
        <ProgressBar :from="'subProfile'" :step="$case.toSnake('tag')" />
        <div class="top">
            <div class="title" v-html="'관심사나 취향을 입력해주세요'" />
        </div>

        <div class="content">
            <EditKeywords :from="'subProfile'" />
        </div>
        <BottomButton :disabled="!hasKeyword" @click="onSubmit" :label="'완성하기'" />
    </div>
</template>

<script>
import EditKeywords from '@/routes/my-page/components/EditKeywords'
import profileService from '@/services/profile'
import ProgressBar from '@/routes/signup/components/ProgressBar'

export default {
    name: 'TagPage',
    components: {
        EditKeywords,
        ProgressBar,
    },
    data: () => ({
        // nextRoute: 'TagPage',
    }),
    computed: {
        hasKeyword() {
            return ((this.$store.getters.me || {}).keywords || []).length !== 0
        },
    },
    mounted() {},
    methods: {
        async onSubmit() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Popup_ProfileAdd_click_cta',
                },
            })

            try {
                const subProfileData = window.localStorage.getItem('subProfileData') || '{}'
                const payload = JSON.parse(subProfileData)
                // payload.origin_profile_option_ids = this.$store.getters.me.profile_options.map(p => p.profile_option_id)
                const res = await profileService.updateSubProfile(this.$store.getters.me.id, payload)

                if (res) {
                    this.$toast.success('프로필이 완성되었어요!')
                    this.$router
                        .push({ name: 'FeedPage', params: { isDirect: true } })
                        .then(() => this.$stackRouter.pop())
                }
            } catch (e) {
                console.log(e)
            }
        },
        onClickClose() {
            this.$router.go(-1)
        },
    },
}
</script>

<style scoped lang="scss">
.tag-page {
    .back-button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        &.sub-profile {
            justify-content: space-between;
        }
        justify-content: flex-start;
        padding: 14px $header-left-padding 18px;

        .back-button {
            font-size: 24px;
            color: $grey-08;
            margin: 0;
        }

        .pass-button {
            font-size: 16px;
            color: $grey-08;
            // position: absolute;
            right: 0;
        }
    }

    .top {
        padding: 0 20px;
        margin-bottom: 10px;

        .title {
            font-size: 24px;
            letter-spacing: -0.4px;
            color: black;

            @include f-medium;
        }

        .desc {
            margin-top: 12px;
            font-size: 16px;
            color: $grey-09;
        }
    }

    .content {
        height: calc(100vh - 52px - 81px - 30px);
        margin-bottom: 30px;
        overflow-y: auto;
    }
}
</style>
