<template>
    <div></div>
</template>

<script>
export default {
    name: 'ProfileOptionSelector',
    props: ['model', 'nextRoute'],
    mounted() {
        this.openModal()
    },
    computed: {
        signupData() {
            const signupData = window.localStorage.getItem('signupData') || '{}'
            return JSON.parse(signupData)
        },
    },
    methods: {
        openModal() {
            this.$modal
                .custom({
                    component: 'ModalProfileItemSelector',
                    options: {
                        model: this.model,
                        icon: 'chevron_left',
                        profile: this.signupData,
                    },
                })
                .then(result => {
                    if (!result) {
                        this.$router.go(-1)
                        return
                    }

                    switch (this.model) {
                        case 'marry':
                            this.$nativeBridge.postMessage({
                                action: 'sendFirebaseEvent',
                                value: {
                                    category: 'MarriageStatusPage_Click_Option',
                                    option: {
                                        click: result.id,
                                    },
                                },
                            })
                            break
                    }

                    const signupData = this.signupData

                    if (['shape', 'kind', 'religion', 'income', 'asset'].indexOf(this.model) !== -1) {
                        // signupData = this.$store.getters.payloads.signupData

                        if (!signupData.profile_option_ids) signupData.profile_option_ids = []
                        // 일단 하드코딩
                        if (this.model === 'shape') {
                            signupData.profile_option_ids[0] = result.id
                        } else if (this.model === 'kind') {
                            signupData.profile_option_ids[1] = result[0].id
                            signupData.profile_option_ids[2] = result[1].id
                        } else if (this.model === 'religion') {
                            signupData.profile_option_ids[3] = result.id
                        } else if (this.model === 'income') {
                            signupData.profile_option_ids[4] = result.id
                        } else if (this.model === 'asset') {
                            signupData.profile_option_ids[5] = result.id
                        }
                        signupData[this.model] = result.id
                    } else if (this.model === 'marry') {
                        if (result.id === 0) {
                            signupData.have_child = { id: 0, name: '없음' }
                        }
                        signupData[this.$case.toSnake(this.model)] = result
                    } else {
                        signupData[this.$case.toSnake(this.model)] = result
                    }
                    // this.$store.commit('setPayloads', { signupData })
                    window.localStorage.setItem('signupData', JSON.stringify(signupData))
                    this.$router.push({ name: this.nextRoute(result) })
                })
        },
    },
}
</script>
