<template>
    <div class="section">
        <div class="title">
            <span>받은 높은 평가</span>
            <div class="red-dot" v-if="showRedDot" />
        </div>
        <div
            class="received-rating"
            :class="{ checked: !rating.checked }"
            @click="onClick(rating)"
            v-for="rating in ratings"
            :key="rating.id"
        >
            <div v-if="!rating.opened" class="icon-wrapper" :class="{ dormant: !isEnableUser(rating) }">
                <i v-if="isEnableUser(rating)" class="material-icons c-white">lock</i>
                <img v-else v-lazy="$myDatingUserImg(rating)" alt="" />
            </div>
            <div v-else class="blur-box">
                <div :class="rating.opened && $blurOtherProfile(rating.target) && 'blur'" />
                <img class="left" v-lazy="$myDatingUserImg(rating)" alt="" />
            </div>
            <div class="right flex-between">
                <div v-if="!rating.opened" class="top-before-open">
                    <div class="info-before-open">
                        <div class="name-marry-before-open">
                            <span
                                class="name-before-open"
                                v-html="isEnableUser(rating) ? rating.target.nickname : `휴면 회원`"
                            />
                            <MarryState :marry="rating.target.profile.marry" />
                        </div>
                    </div>
                    <div class="day-before-open" v-html="dDay(rating)" />
                </div>
                <BaseProfileInfo v-else :like="rating" />
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'ReceivedRatings',
    props: {
        ratings: Array,
        goUserDetail: Function,
    },
    data: () => ({
        heartCost: 5,
    }),
    computed: {
        showRedDot() {
            return this.ratings.some(l => !l.checked)
        },
    },
    methods: {
        isEnableUser(rating) {
            const {
                target: { enabled, is_dormant: dormant, hidden },
            } = rating

            return !(!enabled || dormant || hidden)
        },
        dDay(rating) {
            const expire = 10 - this.$moment().diff(rating.created_at, 'days')

            return `D-${expire}`
        },
        async onClick(rating) {
            if (!rating.checked) {
                await userService.starRateCheck(rating.id)
                this.$store.dispatch('updateReceivedRating', { rating })
            }
            if (rating.opened) {
                if (this.$isDormant(rating.target)) {
                    this.$modal.basic({
                        title: '안내',
                        body: `죄송합니다. 상대가 휴면 신청을 하여 프로필을 열람할 수 없습니다.`,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    return
                }
                this.$stackRouter.push({
                    name: 'UserDetailPage',
                    props: {
                        userId: rating.target.id,
                        from: 'ReceivedRatings',
                    },
                })
            } else {
                if (this.$isDormant(rating.target)) {
                    this.$modal.basic({
                        title: '안내',
                        body: `죄송합니다. 상대가 휴면 신청을 하여 프로필을 열람할 수 없습니다.`,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                } else {
                    const openRating = async () => {
                        const result = await userService.starRateOpen(rating.id)
                        if (result.user_rating.opened === true) {
                            this.$stackRouter.push({
                                name: 'UserDetailPage',
                                props: {
                                    userId: rating.target.id,
                                    from: 'ReceivedRatings',
                                },
                            })
                            this.$store.dispatch('updateReceivedRating', { rating, open: true })
                        }
                        this.$store.dispatch('loadBadges')
                    }
                    if (this.$store.getters.me.gender === 1) {
                        openRating()
                    } else {
                        const idx = await this.$modal.basic({
                            title: '높게 평가한 회원 확인',
                            body: `하트 ${this.heartCost}개를 사용하여, 나를 높게 평가한 회원을 확인합니다.`,
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        if (idx === 1) {
                            const myHeart = (this.$store.getters.badges || {}).heart || 0
                            if (myHeart >= this.heartCost) {
                                openRating()
                            } else {
                                const idx2 = await this.$modal.basic({
                                    body: `하트가 ${
                                        this.heartCost - myHeart
                                    }개 부족합니다. 하트를 충전 후에 이용해주세요 :)`,
                                    buttons: [
                                        {
                                            label: 'CANCEL',
                                            class: 'btn-default',
                                        },
                                        {
                                            label: 'FILL',
                                            class: 'btn-primary',
                                        },
                                    ],
                                })
                                if (idx2 === 1) {
                                    this.$stackRouter.push({
                                        name: 'InAppStorePage',
                                        props: { from: 'alert' },
                                    })
                                }
                            }
                        }
                    }
                }
            }
        },
    },
}
</script>

<style scoped lang="scss">
.received-rating {
    display: flex;
    padding: 16px;
    &:not(:last-child) {
        padding-bottom: 16px;
        border-bottom: 1px solid $grey-01;
    }

    &.checked {
        background: rgba($purple-primary, 0.05);
    }

    .icon-wrapper {
        background-image: linear-gradient(to bottom, #b475ff, $purple-primary);
        min-width: 60px;
        max-width: 60px;
        height: 60px;
        border-radius: 24px;
        font-size: 21px;
        color: white;
        margin-right: 16px;
        @include center;

        &.dormant {
            background-image: none;
            min-width: unset;
            max-width: unset;
            margin-right: 0;
        }
    }

    .left {
        max-width: 60px;
        min-width: 60px;
    }

    .right {
        align-self: center;
        width: 100%;

        .top-before-open {
            display: flex;
            justify-content: space-between;

            .info-before-open {
                .name-marry-before-open {
                    display: flex;
                    align-items: center;

                    .name-before-open {
                        @include spoqa-f-medium;
                        font-size: 16px;
                        color: black;
                        margin-right: 8px;
                    }
                }
            }
            .marry-state {
                max-width: 40px;
                text-align: center;
                padding: 2px 6px 2px;
                color: white;
                font-size: 12px;
            }
            .day-before-open {
                text-align: center;
                padding: 0 6px;
                max-height: 16px;
                border-radius: 8px;
                background-color: rgb(0, 0, 0);
                opacity: 0.35;
                font-size: 11px;
                color: white;
                @include f-medium;
            }
        }
        .bottom {
            margin-top: 10px;
            border-radius: 0 12px 12px 12px;
            background-color: $grey-01;
            padding: 10px 12px;
            font-size: 13px;
            @include spoqa-f-regular;

            .content {
                color: black;
            }
            .more {
                margin-top: 2px;
                text-decoration: underline;
                color: $purple-primary;
            }
        }
    }

    .blur-box {
        position: relative;

        .blur {
            position: absolute;
            backdrop-filter: blur(6px);
            -webkit-backdrop-filter: blur(6px);
            width: 60px;
            height: 60px;
            border-radius: 24px;
            border: 1px solid #e9e9ed;
            margin-right: 16px;
        }
    }
}
</style>
