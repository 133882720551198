<template>
    <div class="manager-introduction">
        <i class="material-icons close-btn" @click="back">chevron_left</i>
        <div class="container">
            <img :src="imgUrl" alt="" />
        </div>
        <BottomButton :label="buttonText()" @click="onClickButton" />
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'ManagerWhatIsPage',
    props: {
        agent: {
            type: Object,
            default: null,
        },
        beforeBackgroundColor: {
            type: String,
            default: '#FFFFFF',
        },
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        imgUrl() {
            const gender = this.$store.getters.me.gender
            return require(`@/assets/images/manager/premium_${gender}.png`)
        },
    },
    methods: {
        back() {
            // this.$stackRouter.pop()
            this.$router.push({
                name: 'FeedPage',
            })
        },
        buttonText() {
            return '프리미엄 프로필 받아보기'
        },
        async onClickButton() {
            try {
                const res = await userService.createManager({
                    user_id: this.$store.getters.me.id,
                })

                if (res) {
                    await this.$modal.basic({
                        body: '신청이 완료되었습니다. 가입 자격 여부를 확인한 후 안내드리겠습니다.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    userService
                        .applySignup({
                            user_id: this.me.id,
                            name: this.me.name || '',
                        })
                        .then(async () => {
                            await this.$store.dispatch('loadMe')
                            this.$router.push({ name: 'Manager2ConfirmStatusPage' })
                        })

                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'Click_Manager_Introduction_CTA',
                            option: {
                                user_id: this.$store.getters.me.id,
                                gender: this.$store.getters.me.gender,
                            },
                        },
                    })
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
    },
    mounted() {
        // this.init()
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: this.beforeBackgroundColor,
        })
    },
}
</script>

<style lang="scss" scoped>
.manager-introduction {
    height: 100vh;
    overflow: auto;

    .close-btn {
        position: fixed;
        left: 16px;
        top: 16px;
        color: $grey-05;
        z-index: 1;
        width: 28px;
        height: 28px;
        padding: 2px;
        border-radius: 14px;
    }

    .btn-bf {
        width: calc(100vw - (24px * 2));
        height: 48px;
        border-radius: 8px;
        border: none;
        font-size: 15px;
        color: #211d76;
    }

    .container {
        position: relative;
        height: calc(100vh - 52px);
        overflow-y: auto;

        img {
            width: 100vw;
        }

        .top-img {
            position: inherit;
            display: flex;
        }

        .btn-bf {
            position: absolute;
            left: 24px;
            bottom: 68px;
            background-color: $yellow-intensive;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        }

        .middle-img {
            position: inherit;
            display: flex;
        }

        .bottom-img {
            position: inherit;
            display: flex;
        }

        .blank {
            position: inherit;
            display: flex;
            width: 100vw;
            height: 80px;
        }
    }

    ::v-deep button {
        background: none;
        color: white;
        padding-top: 16px;
        padding-bottom: 16px;
        background-image: $purple-primary-grad;
        font-size: 15px;
        border: 1px solid $white;

        @include center;
        @include f-medium;
    }
}
</style>
