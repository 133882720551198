<template>
    <div class="front">
        <div class="gradient" />
        <div class="entrance">
            <div class="head f-light" @click="increaseCounter">
                <img :src="require('@/assets/images/app/frontpage-heart.png')" alt="" />
            </div>

            <div class="foot">
                <!-- v-if="['c69001473d3f166f', 'ac7aa3dfc4a3e251','6e1b57b4268d2a58', '96ece557d1624b64','467cda3dbb8bcfc7'].includes($store.getters.device.id)"  -->
                <button type="button" class="btn btn-primary m-b-12 signup-kakao" @click="signupWithKakao">
                    <img class="img-kakao" src="@/assets/images/kakaotalk.svg" />
                    <span>{{ 'SIGNUP_KAKAO' | translate }}</span>
                </button>
                <div class="flex m-t-20">
                    <button type="button" ref="signupButton" class="btn signup-email" @click="startWithPhone">
                        <span>{{ 'START_PHONE_NUMBER' | translate }}</span>
                    </button>
                    <button v-if="showEmail" type="button" ref="signupButton" class="btn signup-email" @click="login">
                        <span>이메일 로그인</span>
                    </button>
                </div>
                <div class="hr m-t-16" />
                <div class="consent-text m-t-16">
                    <span class="text" v-html="'로그인하시면 '" />
                    <span class="text-bold" @click="openConsent('service')" v-html="'이용약관'" />
                    <span class="text" v-html="', '" />
                    <span class="text-bold" @click="openConsent('privacy')" v-html="'개인정보처리방침에 '" />
                    <span class="text" v-html="'<br>'" />
                    <span class="text" v-html="'동의하는 것으로 간주합니다.'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import commonService from '@/services/common'
import userService from '@/services/user'
// import axios from '@/modules/axios'

export default {
    name: 'FrontPage',
    data: () => ({
        counter: 0,
        counterInterv: null,
        provider: null,
        terms: null,
        showEmail: false,
    }),
    watch: {
        counter(newVal) {
            if (newVal !== 5) return

            this.$modal
                .custom({
                    component: 'ModalSelectEnv',
                    options: {
                        preventCloseOnMousedownMask: true,
                    },
                })
                .then(() => {
                    this.showEmail = JSON.parse(window.localStorage.getItem('showEmailLogin') || 'false')
                })
        },
        '$store.getters.thirdPartySignInData': {
            handler(newVal) {
                this.checkAlreadySignup(newVal)
            },
            deep: true,
        },
        '$store.getters.deviceToken': {
            handler() {
                this.registerTempDevice()
            },
        },
    },
    methods: {
        async checkAlreadySignup(data) {
            if (!data.phone_number) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'FrontPage_Click_KakaoStart',
                        option: {
                            result: 'to_sms',
                            gender: data.gender === 'female' ? 1 : 0,
                        },
                    },
                })
                this.$router.push({ name: 'StartWithPhonePage' })
                return
            }
            this.$loading(true)
            try {
                const res = await userService.checkSignup(data.email, data.phone_number)

                if (res.phone_number === null) {
                    this.$modal.basic({
                        body: '카카오톡에 등록된 휴대폰 번호가 유효하지 않습니다. 다른 방법으로 가입해 주세요.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'Alert_ErrorPhoneNumber',
                        },
                    })
                    return
                }

                if (res.res_email || res.res_phone) {
                    await this.$store.dispatch('signInThirdParty', {
                        user: {
                            email: res.email,
                            token: res.token,
                        },
                        noRedirect: false,
                    })
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'FrontPage_Click_KakaoStart',
                            option: {
                                result: 'login',
                                gender: data.gender === 'female' ? 1 : 0,
                            },
                        },
                    })
                } else {
                    console.log('signup kakao')
                    // this.$router.push({ name: 'NicknamePage', params: { provider: data.provider } })
                    this.checkThirdParty(data)
                }
            } catch (e) {
                this.$toast.error(e)
            }
            this.$loading(false)
        },
        registerTempDevice() {
            const payload = {
                platform: this.$store.getters.deviceToken.platform,
                registration_id: this.$store.getters.deviceToken.registration_id,
                page_status: 0,
            }

            commonService.create(payload)
        },
        watchCounter() {
            this.counterInterv = setInterval(() => {
                if (this.counter > 0) this.counter -= 1
            }, 1000)
        },
        increaseCounter() {
            if (this.counter < 5) this.counter++
        },
        signupWithEmail() {
            // this.$nativeBridge.postMessage({
            //     action: 'sendFirebaseEvent',
            //     value: {
            //         category: 'FrontPage_Click_Option',
            //         option: {
            //             click: 'Signup',
            //         },
            //     },
            // })
            this.sendAirbridgeEvent()
            this.provider = 'email'

            this.$store.commit('setPayloads', { signupData: { provider: this.provider } })
            this.$router.push({ name: 'EmailPage' })
        },
        signupWithKakao() {
            this.sendAirbridgeEvent()
            this.provider = 'kakao'
            this.$nativeBridge.postMessage({ action: 'signupWithKakao' })
        },
        signupWithFB() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FrontPage_Click_FacebookStart',
                },
            })
            this.sendAirbridgeEvent()
            this.provider = 'facebook'
            this.$nativeBridge.postMessage({ action: 'signupWithFB' })
        },
        signupWithApple() {
            // const { app_version: version } = this.$store.getters.device

            // if (version < '1.0.3') {
            //     this.$toast.error('앱 업데이트 후 사용 가능합니다.')
            //     return
            // }

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FrontPage_Click_AppleStart',
                },
            })
            this.sendAirbridgeEvent()
            this.provider = 'apple'
            this.$nativeBridge.postMessage({ action: 'signupWithApple' })
        },
        login() {
            // this.$nativeBridge.postMessage({
            //     action: 'sendFirebaseEvent',
            //     value: {
            //         category: 'FrontPage_Click_Option',
            //         option: {
            //             click: 'Login',
            //         },
            //     },
            // })
            this.$router.push({ name: 'LoginPage' })
        },
        async initTerms() {
            await this.$store.dispatch('loadTerms')
            const { terms } = this.$store.getters.terms || {}
            this.terms = terms
        },
        async checkThirdParty(data) {
            try {
                data.provider = this.provider

                if (data.user_id) {
                    data.uid = data.user_id
                }
                // this.$toast.success(data.email)

                // if (!data.email && data.secondEmail) data.email = data.secondEmail
                data.phone_number = this.$store.getters.thirdPartySignInData.phone_number
                if (this.$store.getters.device) data.device_id = this.$store.getters.device.id

                const { user, login_status: status } = await userService.checkThirdParty(data)

                if (status === 'sign_up') {
                    await this.startSignup(data)
                    this.$nativeBridge.postMessage({
                        action: 'sendAirbridgeEvent',
                        value: {
                            category: 'complete_tempsignup',
                        },
                    })
                } else if (status === 'sign_in') {
                    this.$toast.success(this.$translate(`LOGIN_WITH_EXISTING_${this.provider.toUpperCase()}_ACCOUNT`))
                    this.$store.dispatch('signInThirdParty', { user: user, noRedirect: false })
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        async startSignup(data) {
            // this.$loading(true)
            await this.initTerms()

            try {
                const payload = this.preparedPayload(data)
                const { user } = await userService.create(payload)

                await this.$store.dispatch('signInThirdParty', {
                    user,
                    noRedirect: false,
                })
                // if (user.id % 2 === 0) {
                //     this.$toast.success('가입 완료! 이제 반쪽을 찾아보세요 😉')
                // }
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'FrontPage_Click_KakaoStart',
                        option: {
                            result: 'complete_tempSignup',
                            gender: data.gender === 'female' ? 1 : 0,
                        },
                    },
                })
            } catch (e) {
                this.$toast.error(e.data)
            }
            // this.$loading(false)
        },
        preparedPayload(data) {
            const formData = new FormData()
            Object.entries(data).forEach(([key, value]) => {
                formData.append(key, value)
            })
            formData.append('service_terms_version', this.terms.service.version)
            formData.append('privacy_terms_version', this.terms.privacy.version)
            formData.append('privacy_third_terms_version', this.terms.privacy_third.version)
            formData.append('optional_consent', 0)

            return formData
        },
        signupProcess() {
            const { provider, email } = this.$store.getters.payloads.signupData

            if (provider.match(/facebook|apple/i) && email) {
                // this.$router.push({ name: 'NicknamePage' })
                this.$router.push({ name: 'GenderPage' })
            } else if (provider.match(/kakao/i) && email) {
                this.$router.push({ name: 'MarryPage' })
                // if (gender === null || gender.name === null) {
                //     this.$router.push({ name: 'GenderPage' })
                //     // } else if (birthyear === null || birthday === null) {
                //     //     this.$router.push({ name: 'BirthdayPage' })
                // } else {
                //     this.$router.push({ name: 'MarryPage' })
                // }
                // this.$router.push({ name: 'NicknamePage' })
            } else {
                // this.$router.push({ name: 'SignupCheckPage' })
                this.$router.push({ name: 'EmailPage' })
            }
        },
        startWithPhone() {
            // this.$toast.success(this.$store.getters.device.id)
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FrontPage_Click_PhoneNumStart',
                },
            })
            this.$router.push({ name: 'StartWithPhonePage' })
        },
        sendAirbridgeEvent() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: 'signup_start',
                },
            })
        },
        signupWithOthers() {
            this.$modal.custom({ component: 'ModalSelectSignupMethod' }).then(button => {
                switch (button) {
                    case 'signupWithEmail':
                        this.signupWithEmail()
                        break
                    case 'signinWithEmail':
                        this.login()
                        break
                    case 'signupWithFacebook':
                        this.signupWithFB()
                        break
                }
            })
        },
        openConsent(type) {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: `https://oj-api.vanillabridge.com/api/users/terms?term_type=${type}`,
                // value: `${axios.defaults.baseURL}users/terms?term_type=${type}`,
            })
        },
    },
    mounted() {
        // 로그아웃 하는 경우에도 여기 오게 되는데, 그 경우에도 loadConstants를 해줘야 회원가입시 문제없음
        this.showEmail = JSON.parse(window.localStorage.getItem('showEmailLogin') || 'false')
        this.$store.dispatch('loadConstants')
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#8A74FF',
                bottom: '#FFFFFF',
            },
        })

        const entered = window.localStorage.getItem('banjjok.FrontPageEntered') || false
        if (!entered) {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Enter_FrontPage',
                },
            })

            window.localStorage.setItem('banjjok.FrontPageEntered', 'true')
        }

        this.watchCounter()
        this.registerTempDevice()
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFFFFF',
        })
        if (this.counterInterv) {
            clearInterval(this.counterInterv)
            this.counterInterv = null
        }
    },
}
</script>

<style lang="scss" scoped>
.front {
    // background-image: linear-gradient(to bottom, $purple-primary 29%, $white 60%) !important;
    // background-image: url('@/assets/images/main-background.jpg') !important;
    background-image: url('../assets/images/main-background.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100vh;
    //height: 100%;
    $footer-height: 152px;
    height: 100vh !important;
    overflow: hidden !important;

    .gradient {
        z-index: 1;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
    }
    .entrance {
        height: 100%;
        padding: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .head {
            color: white;
            padding-top: 122px;
            margin: 0 auto;
            z-index: 1;

            img {
                width: 123px;
                // height: 60px;
            }
        }

        .foot {
            .divider {
                border-bottom: solid 1px rgba(255, 255, 255, 0.25);
            }

            .bar {
                color: $grey-04;
                margin: 10px;
            }

            button {
                height: 48px;
                width: 100%;
                font-size: 15px;
                background: white;

                &.signup-email {
                    color: white;
                    font-size: 14px;
                    background: none;
                    border: none;

                    @include f-medium;

                    .material-icons {
                        margin-right: 10px;
                        color: $purple-primary;
                    }
                }

                &.signup-kakao {
                    color: black;
                    background-color: $yellow-kakao;

                    .img-kakao {
                        margin-left: 16px;
                        left: 0;
                        position: absolute;
                    }
                }

                &.signup-facebook {
                    color: white;
                    background-color: $blue-facebook;

                    .img-facebook {
                        margin-left: 16px;
                        left: 0;
                        position: absolute;
                    }
                }

                &.signup-apple {
                    color: white;
                    background-color: black;

                    .img-apple {
                        margin-left: 16px;
                        left: 0;
                        position: absolute;
                    }
                }
            }

            .others {
                width: 100%;
                text-align: center;

                .text-btn {
                    color: white;
                    font-size: 14px;
                }

                .vertical-bar {
                    margin: 0 32px;
                    color: white;
                }
            }

            .hr {
                width: 100vw;
                margin-left: -20px;
                height: 1px;
                background: rgba(255, 2555, 255, 0.2);
            }

            .consent-text {
                padding: 0 21px 0 21px;
                text-align: center;
                font-size: 12px;
                @include f-medium;
                color: rgba(255, 255, 255, 0.7);

                .text-bold {
                    text-decoration: underline;
                    @include f-bold;
                }
            }
        }
    }
}
</style>
