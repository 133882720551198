<template>
    <div class="manager-main">
        <div class="manager-profile">
            <div class="profile-main">
                <img :src="managerPhoto" alt="" />
                <div class="right">
                    <div class="name-role m-b-13 f-14">
                        <span class="f-medium c-black f-18">{{ managerProfile.name }}</span> ({{
                            managerProfile.nickname
                        }})
                    </div>
                    <div class="btn-brd" @click="onClickChat">
                        <i class="material-icons f-18 m-r-8">sms</i>
                        <span class="f-14">대화하기</span>
                    </div>
                </div>
            </div>
            <div class="manager-say">
                <img class="m-r-14" :src="require('@/assets/images/manager/manager-quote-left.png')" alt="" />
                <div class="say text-center">평생을 함께할 반쪽을 찾아드리기 위해<br />최선을 다하겠습니다.</div>
                <img class="m-l-14" :src="require('@/assets/images/manager/manager-quote-right.png')" alt="" />
            </div>
        </div>
        <div class="manager-menus">
            <div class="menu" v-for="menu in managerMenu" :key="menu.id" @click="menu.handler">
                <span v-html="$translate(menu.key)" />
                <i class="material-icons">chevron_right</i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Manager3MainPage',
    mounted() {
        this.popUpMsg()
    },
    methods: {
        popUpMsg() {
            const alreadyLoaded = localStorage.getItem('manager-main-first')
            if (!alreadyLoaded) {
                this.$modal
                    .basic({
                        body: '회원님께 매니저가 안내 전화를<br>드릴 예정입니다',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(() => localStorage.setItem('manager-main-first', true))
            }
        },
        async onClickChat() {
            await this.$store.dispatch('loadChats')
            const managerChat = this.$store.getters.chats.find(chat => chat.chat_type === 'manager_chat')
            if (managerChat) {
                const unread = this.$store.dispatch('loadChat', { chatId: managerChat.id })
                this.$stackRouter.push({
                    name: 'ChatroomPage',
                    unread,
                })
            } else {
                this.$toast.error('대화방이 없습니다. 플러스친구 문의 바랍니다.')
            }
        },
    },
    computed: {
        managerProfile() {
            return this.$store.getters.me.manager_user
        },
        managerPhoto() {
            return this.managerProfile.photo.url
        },
        managerMenu() {
            return [
                {
                    id: 1,
                    key: 'MANAGER_FEE',
                    handler: () => {
                        this.$stackRouter.push({
                            name: 'ManagerFeeInfoPage',
                        })
                    },
                },
                // {
                //     id: 1,
                //     key: 'MANAGER_VERIFICATIONS',
                //     handler: () => {},
                // },
                {
                    id: 1,
                    key: 'MANAGER_PREFERENCE',
                    handler: () => {
                        this.$stackRouter.push({
                            name: 'EditStylePage',
                        })
                    },
                },
            ]
        },
    },
}
</script>

<style scoped lang="scss">
.manager-main {
    .manager-profile {
        border-bottom: 1px solid $grey-02;
        padding-bottom: 32px;

        .profile-main {
            display: flex;
            align-items: center;
            padding: 16px;

            img {
                width: 80px;
                height: 80px;
                border-radius: 32px;
                border: 1px solid $grey-03;
                flex: none;
                margin-right: 20px;
            }
            .right {
                flex: auto;

                .btn-brd {
                    @include center;
                    padding: 7px 0;
                }
            }
        }
        .manager-say {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            img {
                width: 14px;
                height: 10px;
                margin-top: 3px;
            }

            .say {
                font-size: 15px;
                @include spoqa-f-regular;
                line-height: 24px;
                color: $grey-09;
            }
        }
    }
    .manager-menus {
        padding: 32px 16px;

        .menu {
            padding: 13px 20px;
            color: black;
            background: $grey-01;
            margin-bottom: 12px;
            border-radius: 8px;
            @include between;
        }
    }
}
</style>
