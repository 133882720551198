<template>
    <div class="chat-item-photo" @click="$emit('clickPhoto')">
        <img class="photo" v-lazy="imgObj" />
    </div>
</template>

<script>
export default {
    name: 'ChatItemPhoto',
    props: {
        src: {
            type: String,
        },
        gender: Number,
        isDormant: Boolean,
    },
    computed: {
        imgObj() {
            return {
                src: this.src || this.isDormant ? this.src : this.$alternativeImg(this.gender),
                error: require('@/assets/images/app/blank_profile_big.svg'),
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.chat-item-photo {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 40%;
    border: solid 1px $grey-02;

    .badge-agent-type {
        border-radius: 8px;
        position: absolute;
        padding: 1px 4px;
        bottom: 0;
        right: 0;
        background: white;
        border: 1px solid $purple-primary;
        color: $purple-primary;
        box-shadow: 0 1px 2px 0 rgba(84, 72, 203, 0.1);
    }

    .photo {
        width: 58px;
        height: 58px;
        border-radius: 40%;

        &.suggest {
            width: 36px;
            height: 36px;
            border: 0;
        }
    }
}
</style>
