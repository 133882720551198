<template>
    <div class="referrer">
        <div class="question" v-html="question" />
        <div class="options"
             v-for="option in options"
             :key="option.id"
             :class="{ selected: selected === option.id }"
             @click="onClick(option.id)"
             v-html="option.title">
        </div>
        <div class="bottom-cont">
            <div class="button" :class="{ activated: selected >= 0 }" v-html="'다음'" @click="onNext()"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ReferrerPage',
    data: () => ({
        question: '가입 경로를 알려주세요!',
        options: [],
        selected: -1,
    }),
    mounted() {
        this.$registerBackHandler(this.backHandler)
        this.options = (this.$store.getters.categories || []).filter(c => c.category === 'referrer')
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_SignupRoutePage',
            },
        })
    },
    beforeDestroy() {
        this.$unregisterBackHandler(this.backHandler)
    },
    methods: {
        onClick(id) {
            // if (this.selected.includes(id)) {
            //     const idx = this.selected.indexOf(id)
            //     this.selected.splice(idx, 1)
            // } else {
            //     this.selected.push(id)
            // }
            this.selected = id
        },
        onNext() {
            if (this.selected < 0) this.$toast.error('답변을 선택해주세요!')
            else {
                this.$router.push({ name: 'OtherAppPage', params: { referrer: this.selected } })
            }
        },
        backHandler() {
            this.$toast.success('가입 완료! 이제 반쪽을 찾아보세요!')
            this.$router.push({ name: 'FeedPage' })
        },
    },
}
</script>

<style lang="scss" scoped>
    .referrer {
        padding-bottom: 80px;

        .question {
            margin: 0 20px 40px 20px;
            font-size: 24px;
            line-height: normal;
            color: black;
            font-weight: 500;
            @include f-medium;
        }

        .options {
            margin: 0 20px 12px 20px;
            height: 48px;
            line-height: 48px;
            border: solid 1px $grey-02;
            border-radius: 8px;
            font-size: 15px;
            color: $grey-09;
            text-align: center;

            &.selected {
                color: $purple-primary;
                border: solid 1px $purple-primary;
            }
        }

        .bottom-cont {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 80px;
            padding: 16px;
            border-top: solid 1px $grey-02;
            background: white;

            .button {
                width: 100%;
                height: 48px;
                font-size: 15px;
                text-align: center;
                line-height: 48px;
                background: $grey-02;
                color: $grey-05;
                border-radius: 8px;

                &.activated {
                    background: $purple-primary;
                    color: white;
                }
            }
        }
    }
</style>
