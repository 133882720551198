<template>
    <!--    <ProfileOptionSelector :model="'gender'" :nextRoute="() => nextRoute" />-->
    <div class="signup basics">
        <ProgressBar step="gender" />
        <div class="content">
            <div class="title" v-html="$translate('GENDER')" />
            <div class="options">
                <div
                    class="option"
                    v-for="(item, idx) in profileItems"
                    :key="item.id"
                    :class="{ 'm-r-12': idx === 0, selected: item.selected }"
                    @click="onClickGender(item.id)"
                >
                    <div class="item-wrapper">
                        <img :src="item.src" alt="" />
                        <div class="gender-name" v-html="$translate(item.name)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from '@/routes/signup/components/ProgressBar'
export default {
    name: 'GenderPage',
    mounted() {
        const prevGender = JSON.parse(window.localStorage.getItem('signupData') || '{}').gender
        if (prevGender) {
            this.profileItems = this.profileItems.map(item => ({
                ...item,
                selected: item.id === prevGender.id,
            }))
        }
    },
    data: () => ({
        nextRoute: 'BirthdayPage',
        profileItems: [
            {
                id: 1,
                name: 'FEMALE',
                src: require(`@/assets/images/no_photo_1.jpg`),
                selected: false,
            },
            {
                id: 0,
                name: 'MALE',
                src: require(`@/assets/images/no_photo_0.jpg`),
                selected: false,
            },
        ],
    }),
    components: {
        ProgressBar,
    },
    methods: {
        onClickGender(id) {
            this.profileItems = this.profileItems.map(item => ({
                ...item,
                selected: item.id === id,
            }))

            setTimeout(() => this.next(), 500)
        },
        next() {
            this.sendEvent()

            const signupData = JSON.parse(window.localStorage.getItem('signupData') || '{}')
            signupData.gender = {
                id: this.selectedGender.id,
                name: this.selectedGender.name,
            }
            window.localStorage.setItem('signupData', JSON.stringify(signupData))
            this.$router.push({ name: this.nextRoute })
        },
        sendEvent() {
            const gender = this.selectedGender.name.toLowerCase()

            this.$hackleClient.onReady(() => {
                const hackleUser = this.$store.getters.hackleUser
                this.$hackleClient.track(`Enter_GenderPage`, hackleUser)
            })
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `signup_choose_gender_${gender}`,
                    option: {
                        type: 'custom',
                        label: gender,
                    },
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'GenderPage_Click_Option',
                    option: {
                        gender: this.selectedGender.id,
                    },
                },
            })
        },
    },
    computed: {
        selectedGender() {
            return this.profileItems.find(item => item.selected) || false
        },
    },
}
</script>
<style scoped lang="scss">
.options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 100%;

    .option {
        border: 1px solid $grey-03;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        .item-wrapper {
            padding: 16px 34px;
            img {
                width: 100%;
                height: 100%;
                margin-bottom: 12px;
            }
            .gender-name {
                text-align: center;
                font-size: 16px;
                color: black;
            }
        }

        &.selected {
            border: 1px solid $purple-primary;
            .gender-name {
                color: $purple-primary;
                @include f-bold;
            }
        }
    }
}
</style>
