<template>
    <ProfileOptionSelector
        :model="'jobCategory'"
        :nextRoute="item => (item.verification_required ? 'CompanyPage' : 'JobPage')"
    />
</template>

<script>
export default {
    name: 'JobCategoryPage',
}
</script>
