<template>
    <div class="two-buttons">
        <button
            class="btn btn-block"
            v-for="(button, idx) in buttons"
            v-html="button.label"
            :class="button.class.concat(button.disabled ? 'disabled' : '')"
            :key="button.label"
            @click="onClickBtn(idx)"
        />
    </div>
</template>

<script>
export default {
    name: 'TwoButtons',
    props: {
        buttons: {
            type: Array,
            default() {
                return [
                    {
                        label: this.$translate('NEXT'),
                        class: ['btn-primary', 'm-b-8'],
                        disabled: true,
                    },
                    {
                        label: this.$translate('PASS'),
                        class: 'btn-white',
                        disabled: false,
                    },
                ]
            },
        },
    },
    methods: {
        onClickBtn(idx) {
            const disabled = event.target.classList.contains('disabled')
            if (!disabled) this.$emit('click', idx)
        },
    },
}
</script>

<style scoped lang="scss">
.two-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 20px;
    border-top: 1px solid $grey-02;
    //display: flex;
    //align-items: flex-end;
    background: white;
    z-index: 3;

    button {
        height: 48px;
        font-size: 15px;

        &.btn-social {
            color: #fff;
            background: $social;
        }

        &.btn-primary {
            &.disabled {
                @include f-regular;
            }
        }
        &.btn-normal {
            @include f-regular;
        }
    }
}
</style>
