<template>
    <div class="profile-photo-pass-container">
        <div class="profile-photo-pass" v-html="cta" @click.stop="$emit('click')" />
    </div>
</template>
<script>
export default {
    name: 'ProfilePhotoPass',
    // props: ['opened'],
    computed: {
        cta() {
            // if (this.opened) return '채팅방 이동'
            return '패스'
        },
    },
}
</script>

<style scoped lang="scss">
.profile-photo-pass-container {
    width: 50%;
    height: 48px;
    margin-left: 8px;

    .profile-photo-pass {
        padding: 10px 0;
        height: 100%;
        color: $grey-08;
        background: white;
        border-radius: 8px;
        border: 1px solid #dad9df;

        @include center;
        @include f-medium;

        &.clicked {
            background: $grey-02;
            color: $grey-05;
            @include f-regular;
        }
    }
}
</style>
