export const stackRouterComponents = {
    UserDetailPage: () => import('@/routes/user-detail/UserDetailPage'),

    InAppStorePage: () => import('@/routes/store/InAppStorePage'),
    VirtualAccountPage: () => import('@/routes/store/VirtualAccountPage'),
    InvitationFriendPage: () => import('@/routes/invitationFriend/InvitationFriendPage'),
    ContactsListPage: () => import('@/routes/invitationFriend/ContactsListPage'),
    BlockContactsPage: () => import('@/routes/block-contacts/BlockContactsPage'),
    VerificationBadgesPage: () => import('@/routes/verification-badges/VerificationBadgesPage.vue'),
    VerificationBadgesCreatePage: () => import('@/routes/verification-badges/VerificationBadgesCreatePage.vue'),
    VerificationTrustBadgesPage: () => import('@/routes/verification-badges/VerificationTrustBadgesPage.vue'),
    ReportSelectReasonPage: () => import('@/routes/report/ReportSelectReasonPage'),
    ReportSubmitPage: () => import('@/routes/report/ReportSubmitPage'),

    // 프로필 페이지 스텍라우터
    EditProfilePage: () => import('@/routes/my-page/EditProfilePage'),
    ProfileGuidePage: () => import('@/routes/my-page/ProfileGuidePage'),
    EditStylePage: () => import('@/routes/manager/EditStylePage'),
    EditDatingPreferencePage: () => import('@/routes/my-page/EditDatingPreferencePage'),
    NoticesPage: () => import('@/routes/home/NoticesPage'),
    FAQPage: () => import('@/routes/faq/FAQPage'), //
    SettingsPage: () => import('@/routes/setting/SettingsPage'),
    ChangePasswordPage: () => import('@/routes/setting/ChangePasswordPage'),
    DormantPage: () => import('@/routes/setting/DormantPage'),
    GoodbyeOnsCampaignPage: () => import('@/routes/my-page/GoodbyeOnsCampaignPage'),
    NotificationDisplayPage: () => import('@/routes/setting/NotificationDisplayPage'),
    NewFeedPage: () => import('@/routes/feed/NewFeedPage'),
    RecommendFeedPage: () => import('@/routes/feed/RecommendFeedPage'),
    SearchKeywordsPage: () => import('@/routes/my-page/SearchKeywordsPage'),

    // chatroom
    ChatroomPage: () => import('@/routes/chat/chatroom/ChatroomPage'),

    // lounge
    LoungeFeedCreatePage: () => import('@/routes/lounge/LoungeFeedCreatePage'),
    LoungePostDetailPage: () => import('@/routes/lounge/LoungePostDetailPage'),
    LoungeIntroductionPage: () => import('@/routes/lounge/LoungeIntroductionPage'),
    LoungeRequestIntroductionPage: () => import('@/routes/lounge/LoungeRequestIntroductionPage'),

    // manager-match
    ManagerFeeInfoPage: () => import('@/routes/manager/ManagerFeeInfoPage'),
    WhyRegisterPage: () => import('@/routes/WhyRegisterPage'),
}

export function isStackRoute(name) {
    if (!name) return false

    return Object.keys(stackRouterComponents).findIndex(key => key === name) > -1
}
