<template>
    <div class="chats bg-white">
        <Disconnected v-if="!loading" />
        <Loading :loading="loading" />

        <div v-show="!loading" v-if="chats.length" class="ver-scroll chat-items layout" @scroll="onScroll">
            <!--            <div class="top-border" v-if="scrollTop !== 0" />-->
            <div ref="ver-scroll" class="flex-fill">
                <component v-for="chat in chats" :is="chatType(chat)" :chat="chat" :key="chat.id" />
            </div>
        </div>
        <BlankPage :image="blankImage" :title="$translate('CHAT_EMPTY')" v-else />
    </div>
</template>

<script>
import ChatItem from './components/ChatItem'
import Disconnected from '@/components/common/Disconnected'
import EventBanner from '@/routes/chat/chats/components/EventBanner'

export default {
    name: 'ChatsPage',
    components: {
        ChatItem,
        Disconnected,
        EventBanner,
    },
    data: () => ({
        scrollTop: 0,
        scrollLimit: 10,
        page: 1,
    }),
    computed: {
        chats() {
            const chats = this.$store.getters.chats || []
            return (
                (chats || []).filter(chat => {
                    if (chat.blocked_id === this.$store.getters.me.id) return false

                    return !(
                        (chat.like || {}).like_type === 'message_ticket' &&
                        chat.last_message.mtype === 'open-chat' &&
                        chat.opener_id === chat.target_chat_user.id
                    )
                }) || []
            )
        },
        loading() {
            return this.$store.getters.loading.chats
        },
        connected() {
            return this.$store.getters.connected
        },
        me() {
            return this.$store.getters.me
        },
        blankImage() {
            return {
                type: 'material-icon',
                url: 'person_off',
            }
        },
    },
    methods: {
        async init() {
            if (!this.chats.length) {
                await this.$store.dispatch('loadChats', true)
            }

            if (this.$store.getters.chat && !this.$route.params.preventSetChatNull) {
                this.$store.commit('setChat', { chat: null })
            }

            const dom = document.querySelector('.ver-scroll')
            dom.scrollTo({ top: this.$store.getters.chatLastScrollTop })
        },
        chatType(chat) {
            return 'ChatItem'
        },
        onScroll({ target }) {
            const { scrollTop } = target
            this.$store.commit('setChatLastScrollTop', scrollTop <= 0 ? 0 : scrollTop)
        },
    },
    async mounted() {
        await this.init()
    },
}
</script>

<style lang="scss" scoped>
.chats {
    .layout {
        height: calc(100% - 8px);
        @include flex;

        .padding {
            height: 8px;
        }

        .top-border {
            position: absolute;
            top: 48px;
            height: 1px;
            width: 100vw;
            background-color: $grey-02;
        }
    }

    .chats-tab {
        .count {
            margin-top: -2px;
        }
    }

    .chat-title {
        margin: 0 16px 16px;
        font-size: 24px;
        color: black;
        line-height: normal;
        font-weight: 500;
        letter-spacing: -0.24px;
    }
}
</style>
