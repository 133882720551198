<template>
    <div class="top">
        <div class="name-marry-day">
            <div class="name-marry">
                <div class="name" v-html="userName" />
                <MarryState :marry="like.target.profile.marry" />
            </div>
            <div class="day" v-html="dDay" />
        </div>
        <div class="age-region">
            {{ $options.filters.asAge(like.target.profile.birthday) }}<span style="color: #c8c8cd">ㅣ</span
            >{{ like.target.profile.region_shorten }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseProfileInfo',
    props: ['like'],
    computed: {
        userName() {
            if (this.like.accepted) {
                return this.$nameAndNick(this.like.target)
            } else {
                if (this.$isDormant(this.like.target)) return '휴면 회원'
                return this.like.target.nickname
            }
        },
        dDay() {
            const expire = 10 - this.$moment().diff(this.like.created_at, 'days')

            return `D-${expire}`
        },
        isDormant() {
            return this.like.target.is_dormant || this.like.target.hidden
        },
    },
}
</script>

<style scoped lang="scss">
.top {
    width: 100%;

    .name-marry-day {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;

        .name-marry {
            display: flex;
            align-items: center;

            .name {
                @include spoqa-f-medium;
                font-size: 16px;
                margin-right: 8px;
                color: black;
                text-align: center;
                margin-top: 3px;
            }
            .marry-state {
                max-width: 40px;
                text-align: center;
                padding: 2px 6px 2px;
                color: white;
                font-size: 12px;
            }
        }
        .day {
            text-align: center;
            padding: 0 6px;
            max-height: 16px;
            border-radius: 8px;
            background-color: rgb(0, 0, 0);
            opacity: 0.35;
            font-size: 11px;
            color: white;
            @include f-medium;
        }
    }
    .age-region {
        font-size: 13px;
        color: $grey-07;
        @include spoqa-f-regular;
    }
}
</style>
