<template>
    <header class="root-header-bar m-t-5" v-if="$isRootRoute()">
        <!--        <div class="f-cj-semibold f-22 c-black">{{ $translate(title) }}</div>-->
        <div class="profile-icon" @click="navigate">
            <div class="icon-wrapper">
                <img :src="photo_url" alt="" />
            </div>
        </div>
        <div class="right">
            <BadgeVanilla @click="onClickBadgeVanilla" class="m-r-12" />
            <div class="bell p-relative" @click="onClickNotification">
                <i class="material-icons">notifications_none</i>
                <div v-if="showUnreadNotifications" class="badge" />
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'RootHeaderBar',
    computed: {
        me() {
            return this.$store.getters.me
        },
        photo_url() {
            if (!this.me) return require('@/assets/images/blank_profile.svg')
            if (!this.me.photos.length) return this.$alternativeImg(this.me.gender)

            return this.me.photos[0].url
        },
        title() {
            if (this.$route.name === 'ChatsPage') return 'CHATS_TITLE'
            else if (this.$route.name === 'FeedPage') return 'FEED'
            else if (this.$route.name === 'MyDatingPage') return 'MY_DATING_TITLE'
            else if (this.$route.name === 'MyPage') return 'MY_PAGE_TITLE'
            else if (this.$route.name === 'LoungePage') return 'LOUNGE_TITLE'

            return ''
        },
        showUnreadNotifications() {
            return (this.$store.getters.notifications || []).filter(n => !n.read).length > 0
        },
        gender() {
            return (this.me || {}).gender
        },
        isFemale() {
            return this.gender === 1
        },
        isChatPage() {
            return this.$route.name === 'ChatsPage'
        },
    },
    methods: {
        navigate() {
            this.$router.push({ name: 'MyPage' }).catch(() => {})
        },
        onClickNotification() {
            this.$router.push({ name: 'NotificationsPage' })
        },
        onClickBadgeVanilla() {
            this.$stackRouter.push({ name: 'InAppStorePage', props: { from: 'header' } })
        },
    },
}
</script>

<style lang="scss" scoped>
.root-header-bar {
    width: 100vw;
    padding: 7px 20px 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .profile-icon {
        width: 36px;

        img {
            width: 100%;
            height: auto;
            border-radius: 50%;
        }
    }
    .right {
        display: flex;
        align-items: center;

        .privacy {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            @include center;
            background-color: $grey-02;
            margin-right: 20px;

            &.active {
                background-color: $blue-facebook;
            }
        }
        .material-icons {
            color: $grey-08;
        }

        .bell {
            flex: none;
            padding-top: 3px;
        }
    }
}
</style>
