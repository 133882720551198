<template>
    <div class="manager-register">
        <div class="title" v-html="$translate('MANAGER_CHECK_YOUR_PROFILE')" />
        <div class="profile-abstract m-b-40">
            <div class="top-container">
                <img class="m-r-20" :src="photoUrl" alt="" />
                <div class="base-info">
                    <div class="name-marry">
                        <div class="name-nick f-18 m-r-8 lines-1" v-html="nameAndNick" />
                        <MarryState :marry="profile.marry" />
                    </div>
                    <div class="age-height" v-html="ageHeight" />
                </div>
            </div>
            <div class="p-16">
                <BasicProfile :managerMatchProfile="true" :profile="profile" :userId="me.id" />
                <div @click="$stackRouter.push({ name: 'EditProfilePage' })" class="btn m-t-12">프로필 수정</div>
            </div>
        </div>
        <BottomButton btnType="grad" :certifyCheck="certifyCheck" :label="`가입 신청하기`" @click="next" />
    </div>
</template>

<script>
import BasicProfile from '@/routes/user-detail/components/BasicProfile'
import userService from '@/services/user'

export default {
    name: 'Manager1RegisterPage',
    components: {
        BasicProfile,
    },
    data: () => ({
        realName: null,
    }),
    methods: {
        async next() {
            const needRealName = !this.me.name
            if (needRealName) {
                const realName = await this.$modal.custom({
                    component: 'ModalInput',
                    options: {
                        title: '성함을 입력해주세요',
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    },
                })
                if (!realName) return
                this.realName = realName
            } else {
                this.realName = this.me.name
            }

            const idx = await this.$modal.basic({
                body:
                    '법적으로 싱글인 사람만 신청 가능합니다.<br>이를 위반할 시 강제 탈퇴 및 민형사상 법적 조치가 취해질 수 있습니다.',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'AGREE',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx) {
                await userService.applySignup({
                    user_id: this.me.id,
                    name: this.realName,
                })
                await this.$store.dispatch('loadMe')
                this.$router.push({ name: 'Manager2ConfirmStatusPage' })
            }
        },
    },
    computed: {
        photoUrl() {
            if (!this.me) return ''

            return (this.me.photos[0] || {}).url || this.$alternativeImg(this.me.gender)
        },
        me() {
            return this.$store.getters.me || {}
        },
        profile() {
            return this.me.profile
        },
        nameAndNick() {
            if (!this.me) return ''

            const name = this.me.name
            const nickname = this.profile.nickname

            return `<span class='f-medium c-black'>${nickname}</span> ${name ? `(${name})` : ''}`
        },
        ageHeight() {
            const { profile } = this.me || ''
            if (!profile) return

            const age = this.$options.filters.asAge(profile.birthday)

            return `${age}세<span>ㅣ</span>${profile.height}cm<span>`
        },
        certifyCheck() {
            return {
                text: '위 프로필 내용에 거짓이 없습니다.',
            }
        },
    },
}
</script>

<style scoped lang="scss">
.manager-register {
    padding: 0 16px;
    height: calc(100vh - 52px - 108px) !important;

    .title {
        font-size: 20px;
        color: black;
        margin-bottom: 32px;
        @include f-medium;
    }
    .profile-abstract {
        width: 100%;
        border: 1px solid $grey-02;
        border-radius: 20px;
        padding: 24px 0 16px 0;

        .top-container {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $grey-02;
            padding: 0 16px 20px 16px;

            img {
                width: 72px;
                height: 72px;
                border-radius: 29px;
            }
            .name-marry {
                display: flex;

                .name-nick {
                    flex: auto;
                }
                ::v-deep .marry-state {
                    flex: none;
                }
            }
            .age-height {
            }
        }
    }
    .btn {
        background: $grey-02;
    }
}
</style>
