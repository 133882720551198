<template>
    <ProfileOptionSelector :model="'asset'" :nextRoute="() => nextRoute" />
</template>

<script>
export default {
    name: 'AssetPage',
    data: () => ({
        nextRoute: 'HeightPage',
    }),
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_AssetPage',
            },
        })
    },
}
</script>

<style scoped></style>
