<template>
    <transition name="modal">
        <div class="modal" v-show="!transparent" v-if="!preventModal" @mousedown="closeOnMousedownMask">
            <div
                :class="
                    modal.component === 'ModalBasic'
                        ? 'modal-container-basic'
                        : modal.component === 'ModalContact'
                        ? 'modal-container-contact'
                        : 'modal-container'
                "
            >
                <component @close="onClose" :options="modal.options" :is="modal.component" />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Modal',
    props: ['modal'],
    data: () => ({
        transparent: false,
    }),
    components: {
        ModalBasic: () => import('@/components/modals/ModalBasic'),
        ModalImages: () => import('@/components/modals/ModalImages'),
        ModalAlert: () => import('@/components/modals/ModalAlert'),
        ModalActionSheet: () => import('@/components/modals/ModalActionSheet'),
        ModalCropper: () => import('@/components/modals/ModalCropper'),
        ModalNotice: () => import('@/components/modals/ModalNotice'),
        ModalEditStyleType: () => import('@/components/modals/ModalEditStyleType'),
        ModalDropout: () => import('@/components/modals/ModalDropout'),
        ModalSelectEnv: () => import('@/components/modals/ModalSelectEnv'),
        ModalSelectCoupon: () => import('@/components/modals/ModalSelectCoupon'),
        ModalRequestAppReview: () => import('@/components/modals/ModalRequestAppReview'),
        ModalAlertCustom: () => import('@/components/modals/ModalAlertCustom'),
        ModalAlertNoX: () => import('@/components/modals/ModalAlertNoX'),
        ModalEditDatingPreference: () => import('@/components/modals/ModalEditDatingPreference'),
        ModalPhoto: () => import('@/components/modals/ModalPhoto'),
        ModalSelectPurchaseMethod: () => import('@/components/modals/ModalSelectPurchaseMethod'),
        ModalSelectSignupMethod: () => import('@/components/modals/ModalSelectSignupMethod'),
        ModalProfileItemSelector: () => import('@/components/modals/ModalProfileItemSelector'),
        ModalLoungeRequest: () => import('@/components/modals/ModalLoungeRequest'),
        ModalCompleteProfile: () => import('@/components/modals/ModalCompleteProfile'),

        // test
        ModalBadgeInfo: () => import('@/components/modals/ModalBadgeInfo'),
        ModalEnterCampaign: () => import('@/components/modals/ModalEnterCampaign'),

        ModalLikeMessage: () => import('@/components/modals/ModalLikeMessage'),
        ModalStarRating: () => import('@/components/modals/ModalStarRating'),
        ModalFeedFilter: () => import('@/components/modals/ModalFeedFilter'),
        ModalInAppStore: () => import('@/components/modals/ModalInAppStore'),
        ModalInput: () => import('@/components/modals/ModalInput'),
        ModalFreeHeart: () => import('@/components/modals/ModalFreeHeart'),
    },
    created() {
        document.addEventListener('keydown', this.onKeydown)

        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value:
                ['ModalProfileItemSelector', 'ModalEditStyleType', 'ModalNotice'].indexOf(this.modal.component) > -1
                    ? '#FFFFFF'
                    : '#666666',
        })

        this.$nativeBridge.postMessage({
            action: 'setCanGoBack',
            value: true,
        })
    },
    beforeDestroy() {
        this.modal.resolve()
        document.removeEventListener('keydown', this.onKeydown)
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFFFFF',
        })
        if ((this.modal.options || {}).prevRouter) {
            this.$nativeBridge.postMessage({
                action: 'setCanGoBack',
                value:
                    [
                        'HomePage',
                        'FrontPage',
                        'ChatsPage',
                        'MyDatingPage',
                        'MyPage',
                        'SystemMaintenanceNoticePage',
                    ].indexOf(this.prevRouter) !== -1,
            })
        }
    },
    computed: {
        preventModal() {
            // Modal 뜨는 걸 방지하는 페이지들
            return ['NewTermsPage'].includes(this.$route.name)
        },
    },
    methods: {
        onClose(event) {
            this.transparent = true

            if (this.modal.resolve) {
                this.modal.resolve(event)
            }

            this.$store.commit('popModal')
        },
        closeOnMousedownMask(event) {
            if ((this.modal.options || {}).preventCloseOnMousedownMask) return

            if (this.modal.component === 'ModalIntroduction') {
                window.localStorage.setItem('readIntroduction', 'true')
            }

            if (event.target.classList.contains('modal')) {
                this.onClose()
            }
        },
        onKeydown(event) {
            if (event.key === 'Escape') {
                this.onClose()
            }
        },
    },
}
</script>
