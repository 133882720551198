<template>
    <div class="signup basics phone-number">
        <div class="content">
            <div class="title">휴대폰 번호를 입력하고,<br />회원가입/로그인하세요</div>
            <div class="body">
                <div class="flex-row">
                    <div class="input-wrapper input-phone-number" :class="{ 'border-primary': !showButton }">
                        <input
                            ref="phoneNumber"
                            type="tel"
                            class="flex-fill"
                            :placeholder="$translate('PLACEHOLDER_PHONENUMBER')"
                            v-model="phoneNumber"
                            maxlength="13"
                            @keydown="onPhoneNumberKeydown()"
                            @keydown.backspace="isClickBackSpace"
                            @focus="onFocus"
                            @blur="onBlur"
                        />
                        <i v-if="phoneNumber && !sent" class="material-icons" @click="phoneNumber = ''">cancel</i>
                        <!-- <hr class="bar" :class="{ 'bar-primary': !disabled }" /> -->
                    </div>
                    <div v-if="sent" class="btn btn-resend" @click="sendVerificationCode" v-html="'다시 받기'"></div>
                </div>
                <div class="error" v-if="error">
                    <i class="material-icons">error_outline</i>
                    전화번호 형식이 올바르지 않습니다
                </div>

                <div v-if="sent" class="sent">
                    <div
                        class="flex-row input-wrapper input-code-number"
                        :class="{ 'border-primary': !showButtonCode }"
                    >
                        <input
                            v-model="code"
                            ref="code"
                            type="number"
                            placeholder="인증번호 6자리 입력"
                            maxlength="6"
                            class="flex-fill"
                            @focus="onFocusCode"
                            @blur="onBlurCode"
                            @keydown="onCodeKeydown()"
                        />
                    </div>
                    <div class="left-seconds">
                        <span v-html="`${humanizeLeftSeconds}`" />
                    </div>
                </div>
                <button
                    v-if="sent"
                    class="m-t-40 btn btn-primary"
                    :class="{ disabled: disabledStart }"
                    @click="confirm"
                    v-html="'시작하기'"
                ></button>
                <button
                    v-else
                    class="m-t-40 btn btn-primary"
                    :class="{ disabled: disabled, error: error }"
                    @click="sendVerificationCode"
                    v-html="'인증번호 받기'"
                ></button>
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'StartWithPhonePage',
    data: () => ({
        error: null,
        disabled: true,
        disabledStart: true,
        isBackSpace: false,
        sent: false,
        code: null,
        phoneNumber: null,
        email: null,
        interv: null,
        leftSeconds: null,
        showButton: true,
        showButtonCode: true,
        terms: null,
    }),
    computed: {
        humanizeLeftSeconds() {
            const minutes = '0' + Math.floor(this.leftSeconds / 60).toString()
            let seconds = Math.floor(this.leftSeconds % 60).toString()
            seconds = seconds.length === 2 ? seconds : `0${seconds}`

            return `남은시간 ${minutes}:${seconds}`
        },
    },
    mounted() {
        this.phoneNumber = this.$store.getters.payloads.signupData.phoneNumber
        this.$refs.phoneNumber.focus()
        this.checkPhoneNumberFormat(this.phoneNumber)

        // this.$nativeBridge.postMessage({
        //     action: 'sendFirebaseEvent',
        //     value: {
        //         category: 'EmailPage_Enter_EmailPage',
        //         option: {
        //             provider: this.$store.getters.payloads.signupData.provider || 'email',
        //         },
        //     },
        // })
    },
    methods: {
        onPhoneNumberKeydown() {
            setTimeout(() => {
                // this.email = this.$refs.email.value
                // if (this.email.length === 8) {
                //     this.email = this.email.substring(0,8) + '-' + this.email.substring(8)
                // }

                // if (this.email.length === 4) {
                //     this.email = this.email.substring(0,3) + '-' + this.email.substring(3)
                // }

                if (this.isBackSpace) {
                    this.phoneNumber = this.$refs.phoneNumber.value.substring(0, this.phoneNumber.length)
                } else {
                    if (this.$refs.phoneNumber.value.length === 9) {
                        this.phoneNumber =
                            this.$refs.phoneNumber.value.substring(0, 8) +
                            '-' +
                            this.$refs.phoneNumber.value.substring(8)
                    } else if (this.$refs.phoneNumber.value.length === 4) {
                        this.phoneNumber =
                            this.$refs.phoneNumber.value.substring(0, 3) +
                            '-' +
                            this.$refs.phoneNumber.value.substring(3)
                    } else {
                        this.phoneNumber = this.$refs.phoneNumber.value
                    }
                }

                // if (this.$refs.phoneNumber.value.length === 9 && !this.isBackSpace) {
                //     this.phoneNumber = this.$refs.phoneNumber.value.substring(0, 8) + '-' + this.$refs.phoneNumber.value.substring(8)
                // } else if (this.$refs.phoneNumber.value.length === 9 && this.isBackSpace) {
                //     this.phoneNumber = this.$refs.phoneNumber.value.substring(0, 8)
                // } else if (this.$refs.phoneNumber.value.length === 4 && !this.isBackSpace) {
                //     this.phoneNumber = this.$refs.phoneNumber.value.substring(0, 3) + '-' + this.$refs.phoneNumber.value.substring(3)
                // } else if (this.$refs.phoneNumber.value.length === 4 && this.isBackSpace) {
                //     this.phoneNumber = this.$refs.phoneNumber.value.substring(0, 3)
                // } else {
                //     this.phoneNumber = this.$refs.phoneNumber.value
                // }

                this.isBackSpace = false

                this.checkPhoneNumberFormat()

                if (this.$refs.phoneNumber.value.length >= this.$refs.phoneNumber.maxLength) {
                    this.$refs.phoneNumber.blur()
                }
            })
        },
        onCodeKeydown() {
            setTimeout(() => {
                if (this.$refs.code.value.length > this.$refs.code.maxLength) {
                    this.code = this.$refs.code.value.slice(0, this.$refs.code.maxLength)
                } else {
                    this.code = this.$refs.code.value
                }

                this.checkCodeFormat()
            })
        },
        isClickBackSpace() {
            this.isBackSpace = true
        },
        checkPhoneNumberFormat() {
            if (!this.phoneNumber) {
                this.error = null
                this.disabled = true
                return
            }

            // const regValidEmail = /[a-zA-Z0-9][\w.-]+@[\w-]+\.[a-zA-Z]{2,4}(\.[a-zA-Z]{2,4})?/
            const regValidPhone = /[0-9]{3}-[0-9]{4}-[0-9]{4}/
            if (!regValidPhone.test(this.phoneNumber)) {
                this.error = '전화번호 형식이 올바르지 않습니다'
                this.disabled = true
                return
            }

            this.error = null
            this.disabled = false
        },
        checkCodeFormat() {
            if (!this.code) {
                // this.error = null
                this.disabledStart = true
                return
            }

            // const regValidEmail = /[a-zA-Z0-9][\w.-]+@[\w-]+\.[a-zA-Z]{2,4}(\.[a-zA-Z]{2,4})?/
            const regValidCode = /[0-9]{6}/
            if (!regValidCode.test(this.code)) {
                // this.error = '전화번호 형식이 올바르지 않습니다'
                this.disabledStart = true
                return
            }

            // this.error = null
            this.disabledStart = false
        },

        async initTerms() {
            await this.$store.dispatch('loadTerms')
            const { terms } = this.$store.getters.terms || {}
            this.terms = terms
        },
        async confirm() {
            if ((this.code || '').length < 6) {
                this.$alert('인증번호 형식이 올바르지 않습니다. 숫자 6자리를 입력해주세요.')
                return
            }

            this.$loading(true)
            await this.initTerms()

            try {
                const data = await userService.verificationCode.confirm({
                    code: this.code,
                    phone_number: this.phoneNumber.replace(/-/g, ''),
                    service_terms_version: this.terms.service.version,
                    privacy_terms_version: this.terms.privacy.version,
                    privacy_third_terms_version: this.terms.privacy_third.version,
                    optional_consent: 0,
                    // device_id: this.$store.getters.device.id,
                    device_id: null,
                })
                if (data.msg === 'OK') {
                    // this.phoneNumber = data.phone_number
                    // this.email = data.email

                    await this.$store.dispatch('signInPhoneNumber', {
                        email: data.user.email,
                        token: data.user.token,
                        // noRedirect: true,
                    })
                    if (data.is_sign_up) {
                        // if (data.user.id % 2 === 0) {
                        //     this.$toast.success('가입 완료! 이제 반쪽을 찾아보세요 😉')
                        // }

                        this.$nativeBridge.postMessage({
                            action: 'sendAirbridgeEvent',
                            value: {
                                category: 'complete_tempsignup',
                            },
                        })
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'SMSVerifPage_Click_Start',
                                option: {
                                    result: 'complete_tempSignup',
                                },
                            },
                        })
                    } else {
                        // this.$toast.success('가입 완료! 이제 반쪽을 찾아보세요 😉')
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'SMSVerifPage_Click_Start',
                                option: {
                                    result: 'login',
                                },
                            },
                        })
                    }

                    // this.$router.push({ name: 'HomePage', params: { signup: true } })
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
            this.$loading(false)
        },
        async sendVerificationCode() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'SMSVerifPage_Click_GetVerifNum',
                },
            })
            if ((this.phoneNumber || '').length < 11 || !(this.phoneNumber || '').startsWith('0')) {
                this.$alert('휴대폰 번호 형식이 올바르지 않습니다. 01012341234 형식으로 입력해주세요.')
                return
            }

            try {
                const data = await userService.verificationCode.create({
                    phone_number: this.phoneNumber.replace(/-/g, ''),
                })
                this.$toast.success(data)
                this.sent = true
                this.leftSeconds = 180

                if (this.interv) {
                    clearInterval(this.interv)
                    this.interv = null
                }

                this.interv = setInterval(() => {
                    if (this.leftSeconds <= 0) {
                        clearInterval(this.interv)
                        this.leftSeconds = null
                        this.interv = null
                        this.sent = false
                        this.code = null
                        return
                    }
                    this.leftSeconds--
                }, 1000)
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        onFocus() {
            this.showButton = false
        },
        onBlur() {
            setTimeout(() => {
                this.showButton = true
            }, 100)
        },
        onFocusCode() {
            this.showButtonCode = false
        },
        onBlurCode() {
            setTimeout(() => {
                this.showButtonCode = true
            }, 100)
        },
    },
}
</script>

<style lang="scss" scoped>
.phone-number {
    .content {
        .body {
            .input-phone-number {
                border: none;
                border-radius: 0;
                border-bottom: 1px solid $grey-03;
                padding-left: 8px;

                &.border-primary {
                    border-bottom: 1px solid $purple-primary;
                }

                // .bar {
                //     width: 100%;
                //     opacity: 0.9;
                //     background-color: $grey-03;

                //     &.bar-primary {
                //         background-color: $purple-primary;
                //     }
                // }
            }
            .btn-resend {
                margin-left: 20px;
                border: 1px solid $grey-03;
                border-radius: 8px;
                opacity: 0.9;
                width: 104px;
            }
            .input-code-number {
                border: none;
                border-radius: 0;
                border-bottom: 1px solid $grey-03;
                padding-left: 8px;
                margin-top: 12px;

                &.border-primary {
                    border-bottom: 1px solid $purple-primary;
                }
            }

            .sent {
                .left-seconds {
                    margin-top: 8px;
                    margin-left: 8px;
                    color: $grey-06;
                    font-size: 14px;
                }
            }

            .btn {
                &.error {
                    margin-top: 12px !important;
                }
            }
        }
    }
}
</style>
