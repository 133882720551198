<template>
    <div class="signup upload-photo">
        <ProgressBar step="upload_photo" />
        <div class="content two-btn">
            <div class="title">프로필 사진</div>
            <div class="body">
                <div class="item-desc m-b-32">얼굴이 분명하게 나온 사진을 등록해 주세요!</div>
                <div class="photos m-b-32">
                    <div
                        :key="photo.url"
                        v-for="(photo, i) of photos"
                        class="photo"
                        @click.stop="!photo.url ? onClickPhoto(i) : null"
                        :style="{ backgroundImage: `url('${photo.url}')` }"
                    >
                        <i v-if="!photo.url" class="material-icons">add</i>
                        <i v-if="photo.url" class="material-icons close-icon" @click.stop="removePhoto(i)">close</i>
                        <span v-if="photo.url" class="modify" @click.stop="onClickPhoto(i)">
                            <i class="material-icons modify-icon">create</i>
                        </span>
                    </div>
                    <input
                        ref="imageUploader"
                        type="file"
                        class="image-input display-none"
                        accept="image/*"
                        @change="onChangeImage"
                    />
                </div>
            </div>
        </div>
        <TwoButtons @click="onClick" :buttons="buttons" />
    </div>
</template>

<script>
import userService from '@/services/user'
import ProgressBar from '@/routes/signup/components/ProgressBar'

export default {
    name: 'UploadPhotoPage',
    components: {
        ProgressBar,
    },
    data: () => ({
        photos: null,
        selectedIndex: null,
        loading: false,
        terms: null,
    }),
    computed: {
        disabled() {
            if (!this.photos) return true

            let flag = true
            for (let i = 0; i < 6; i++) {
                if (this.photos[i].blob) {
                    flag = false
                    break
                }
            }

            return flag
        },
        buttons() {
            return [
                {
                    label: this.$translate('NEXT'),
                    class: ['btn-primary', 'm-b-8'],
                    disabled: this.disabled,
                },
                {
                    // label: this.$translate('PASS_START'),
                    label: '건너뛰기',
                    class: 'btn-normal',
                    disabled: false,
                },
            ]
        },
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        onClick(idx) {
            const skipPhoto = idx === 1
            if (skipPhoto) {
                this.$hackleClient.onReady(() => {
                    const hackleUser = this.$store.getters.hackleUser
                    this.$hackleClient.track('UploadPhotoPage_Click_Skip', hackleUser)
                })
            }
            this.next(skipPhoto)
        },
        async next(skipPhoto) {
            // const { provider } = this.$store.getters.payloads.signupData
            if (this.disabled && !skipPhoto) return
            if (skipPhoto) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'UploadPhotoPage_Click_Skip',
                    },
                })
            }
            this.$store.commit('setPayloads', {
                signupData: {
                    photos: skipPhoto ? [] : this.photos.filter(p => p.blob),
                },
            })

            await this.createUser()
        },
        onClickPhoto(index) {
            this.selectedIndex = index
            if (this.$store.getters.device) {
                this.$openGallery()
            } else {
                this.$openGallery()
            }
            let category = ''

            switch (index) {
                case 0:
                    category = 'Click_FirstPhotoUploadBox'
                    break
                case 1:
                    category = 'Click_SecondPhotoUploadBox'
                    break
            }

            if (category) {
                const { provider, gender } = this.$store.getters.payloads.signupData

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category,
                        option: {
                            provider: provider || 'email',
                            gender: gender.name === 'MALE' ? 0 : 1,
                        },
                    },
                })
            }
        },
        removePhoto(index) {
            this.photos[index].blob = null
            this.photos[index].url = null
            this.photos[index].name = null
        },
        onChangeImage(event) {
            const selectedPhoto = this.photos[this.selectedIndex]

            selectedPhoto.name = event.fileName
            const imgFile = event.blob

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    selectedPhoto.url = URL.createObjectURL(croppedFile)
                    selectedPhoto.blob = croppedFile
                    this.selectedIndex = null
                })
        },
        sendEvent({ gender }) {
            if (!gender) return
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Complete_Signup',
                    option: {
                        profile_status: this.$store.getters.profileStatus,
                    },
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `signup_complete_${gender.name.toLowerCase()}`,
                    option: {
                        type: 'custom',
                        label: gender.name.toLowerCase(),
                    },
                },
            })
            this.$hackleClient.onReady(() => {
                const hackleUser = this.$store.getters.hackleUser
                this.$hackleClient.track('Complete_Signup', hackleUser)
            })
        },
        async createUser() {
            try {
                const signupData = JSON.parse(window.localStorage.getItem('signupData') || '{}')
                this.$loading(true)

                const payload = this.preparedPayload(signupData)
                await userService.complete(payload)

                // 사진 업로드하는 시간을 고려하여 사진은 별도로 업로드
                const photoData = this.$store.getters.payloads.signupData
                if (photoData.photos.length) await userService.uploadPhotos(this.photosPayload(photoData.photos))

                window.localStorage.removeItem('signupData')
                await this.$store.dispatch('loadMe')
                this.sendEvent(signupData)

                this.$store.dispatch('loadSettings', false).then(() => {
                    this.$store.dispatch('loadBadges')
                })
                await this.$store.dispatch('loadFeeds')
                this.$stackRouter.push({
                    name: 'WhyRegisterPage',
                })
            } catch (e) {
                if (e.data.key === 'blocked_user') {
                    this.$modal.basic({
                        title: 'MODAL_DEFAULT_TITLE',
                        body: e.data.msg,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                } else {
                    this.$toast.error(e.data)
                }
            } finally {
                this.$loading(false)
            }
        },
        preparedPayload(signupData) {
            const formData = new FormData()
            Object.entries(signupData).forEach(([key, value]) => {
                if (key === 'gender') {
                    formData.append(key, value.name === 'MALE' ? '0' : '1')
                } else if (key === 'smoking') {
                    formData.append(key, value.name === 'SMOKING' ? '1' : '0')
                } else if (['job', 'company', 'university'].indexOf(key) !== -1) {
                    formData.append(`${key}_id`, value.id)
                    formData.append(`${key}_name`, value.name)
                } else if (
                    ['state', 'station', 'region', 'job_category', 'school', 'school_type'].indexOf(key) !== -1
                ) {
                    // 커스텀 인풋 허용 안되는 값들은 id만 넘김
                    formData.append(`${key}_id`, value.id)
                } else if (key === 'profile_option_ids') {
                    formData.append(key, JSON.stringify(value))
                } else if (key === 'marry') {
                    formData.append(key, value.id)
                } else if (key === 'have_child') {
                    formData.append(key, value.id)
                } else if (key === 'income') {
                    formData.append(key, JSON.stringify(value))
                } else if (key === 'asset') {
                    formData.append(key, JSON.stringify(value))
                } else {
                    formData.append(key, value)
                }
            })
            formData.append('device_id', this.$store.getters.device ? this.$store.getters.device.id : '')
            formData.append('phone_number', this.$store.getters.me.phone_number)

            return formData
        },
        photosPayload(photos = []) {
            if (photos.length === 0) return

            const formData = new FormData()

            photos.forEach((photo, i) => {
                formData.append(`photo${i + 1}`, photo.blob, photo.name)
            })

            return formData
        },
        async initTerms() {
            await this.$store.dispatch('loadTerms')
            const { terms } = this.$store.getters.terms || {}
            this.terms = terms
        },
    },
    created() {
        this.photos = Array(6)
            .fill({})
            .map((p, i) => {
                p = { blob: null, name: null, url: null }
                this.$set(p, 'required', i < 1)
                return p
            })

        const storedPhotos = this.$store.getters.payloads.signupData.photos
        if (storedPhotos) {
            ;(storedPhotos || []).forEach((photo, idx) => {
                this.photos[idx] = storedPhotos[idx]
            })
        }
    },
    mounted() {
        this.$bus.$on('clickSkipButton', () => this.next(true))
        this.$bus.$on('onRequestPhotoFromGallery', this.onChangeImage)

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_UploadPhotoPage',
            },
        })
        this.$hackleClient.onReady(() => {
            const hackleUser = this.$store.getters.hackleUser
            this.$hackleClient.track('Enter_UploadPhotoPage', hackleUser)
        })
    },
    beforeDestroy() {
        this.$bus.$off('clickSkipButton')
        this.$bus.$off('onRequestPhotoFromGallery', this.onChangeImage)
    },
}
</script>
