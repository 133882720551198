<template>
    <div v-if="!myProfile" class="two-btns-vertical" :class="{ example }">
        <AcceptButton v-if="showAcceptBtn" :like="userLike" @click="onClickAcceptBtn" />
        <HotChat v-if="showHotChatBtn" :opened="hotChatOpened" @click="onClickHotChatBtn" />
        <ProfilePhotoPass v-if="showProfilePhotoButton" @click="onClickProfilePhotoPass" />
        <ProfilePhotoAccept v-if="showProfilePhotoButton" @click="onClickProfilePhotoAccept" />
    </div>
</template>

<script>
import AcceptButton from '@/routes/user-detail/components/AcceptButton'
import HotChat from '@/routes/user-detail/components/HotChat'
import ProfilePhotoPass from '@/routes/user-detail/components/ProfilePhotoPass'
import ProfilePhotoAccept from '@/routes/user-detail/components/ProfilePhotoAccept'
import userService from '@/services/user'
import chatService from '@/services/chat'
export default {
    name: 'TwobuttonsVertical',
    components: {
        AcceptButton,
        HotChat,
        ProfilePhotoPass,
        ProfilePhotoAccept,
    },
    watch: {
        content: {
            handler() {
                this.selectedProfilePhoto = this.content.accept_status
            },
            deep: true,
        },
    },
    computed: {
        content() {
            return this.$mustParse((this.profilePhotoMessage || {}).content) || {}
        },
        showProfilePhotoButton() {
            if (this.selectedProfilePhoto != null) return false

            if (this.from && this.from === 'ProfilePhoto') return true
            return false
        },
        showHotChatBtn() {
            if (this.from && this.from === 'ProfilePhoto') return false
            if (!this.userLike) return true
            else {
                if (this.userLike.chat_id) return false
                if (this.userLike.target_id === this.$store.getters.me.id) return false
                return !this.userLike || this.userLike.accepted === false || this.userLike.like_type === 'direct'
            }
        },
        showAcceptBtn() {
            if (this.from && this.from === 'ProfilePhoto') return false
            if (!this.userLike) return true
            else {
                if (this.userLike.chat_id) return true

                return this.userLike.like_type === 'normal' && this.userLike.target_id === this.$store.getters.me.id
            }
        },
        hotChatOpened() {
            return (this.userLike || {}).like_type === 'direct'
        },
    },
    data: () => ({
        userLike: null,
        selectedProfilePhoto: null,
    }),
    mounted() {
        this.userLike = this.like || null
        if (this.profilePhotoMessage) {
            this.selectedProfilePhoto = this.content.accept_status
        }
    },
    props: {
        myProfile: Boolean && undefined,
        like: Object && undefined,
        user: Object && undefined,
        example: Boolean && undefined,
        from: String && undefined,
        profilePhotoMessage: Object,
    },
    methods: {
        async onClickHotChatBtn() {
            if (this.example) return

            if (!this.userLike || !this.userLike.accepted) {
                const { heart: currentHeart } = this.$store.getters.badges
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Click_DirectChat',
                        option: {
                            target_user_id: this.user.id,
                            channel: this.from,
                            status: currentHeart < 40 ? 0 : 1,
                            label: this.label,
                        },
                    },
                })

                // 여자는 무료로 열려야 함
                let idx
                if (this.$store.getters.me.gender === 1) {
                    idx = await this.$modal.basic({
                        title: '바로 대화하기',
                        body: `하트 소모 없이 바로 대화를 나눕니다.`,
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'TALK',
                                class: 'btn-primary',
                            },
                        ],
                    })
                } else {
                    idx = await this.$modal.basic({
                        title: '바로 대화하기',
                        body: `하트 40개를 사용해 바로 대화를 나눕니다.`,
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'TALK',
                                class: 'btn-primary',
                            },
                        ],
                    })
                }

                if (idx) {
                    if (currentHeart < 40 && this.$store.getters.me.gender === 0) {
                        const idx = await this.$modal.basic({
                            body: `하트가 ${40 - currentHeart}개 부족합니다. 하트를 충전하고 호감을 보내주세요.`,
                            buttons: [
                                {
                                    label: 'CLOSE',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'GO_TO_STORE',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        if (idx === 1) {
                            this.$unregisterBackHandler()
                            this.$stackRouter.push({ name: 'InAppStorePage', props: { from: 'alert' } })
                        }
                    } else {
                        try {
                            if (this.$store.getters.me.gender === 0) {
                                await userService.payLike({ like_type: 'direct' })
                            }

                            const { like } = await userService.sendRequest({
                                target_id: this.user.id,
                                message: '',
                                like_type: 'direct',
                            })
                            this.$toast.success('채팅방이 오픈되었어요!')
                            this.$store.dispatch('loadBadges')
                            this.$store.dispatch('loadChats', true)
                            this.userLike = like
                        } catch (e) {
                            if (e && e.data) {
                                this.$modal.basic({
                                    title: e.data.title,
                                    body: e.data.msg,
                                    buttons: [
                                        {
                                            label: 'CONFIRM',
                                            class: 'btn-primary',
                                        },
                                    ],
                                })
                            }
                        }
                    }
                }
            } else if (this.userLike.like_type === 'direct') {
                const unreadCount = await this.$store.dispatch('loadChat', {
                    chatId: this.userLike.chat_id,
                    force: true,
                })

                this.$stackRouter.push({
                    name: 'ChatroomPage',
                    props: { unreadCount },
                })
            }
        },
        async onClickAcceptBtn() {
            if (this.example) return

            if (!this.userLike) {
                const { heart: currentHeart } = this.$store.getters.badges
                const {
                    heart: { send_like: sendLIke },
                } = this.$store.getters.settings

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Click_Like',
                        option: {
                            target_user_id: this.user.id,
                            channel: this.from,
                            status: currentHeart < sendLIke ? 0 : 1,
                            profile_status: this.$store.getters.profileStatus,
                            target_profile_status: this.$targetUserProfileStatus(this.user),
                        },
                    },
                })
                if (currentHeart < sendLIke) {
                    const idx = await this.$modal.basic({
                        body: `하트가 ${sendLIke - currentHeart}개 부족합니다. 하트를 충전하고 호감을 보내주세요.`,
                        buttons: [
                            {
                                label: 'CLOSE',
                                class: 'btn-default',
                            },
                            {
                                label: 'GO_TO_STORE',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx === 1) {
                        this.$unregisterBackHandler()
                        this.$stackRouter.push({ name: 'InAppStorePage', props: { from: 'alert' } })
                    }
                } else {
                    const idx = await this.$modal.basic({
                        title: '호감 보내기',
                        body: `하트 ${sendLIke}개를 사용해 호감을 보냅니다.<br>(상대가 수락하면 대화를 나눌 수 있습니다.)`,
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'SEND',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    if (idx) {
                        try {
                            await userService.payLike({ like_type: 'normal' })
                            this.$store.dispatch('loadBadges')
                        } catch (e) {
                            this.$toast.error('처리 중 오류가 발생했어요')
                            return
                        }

                        const res = await this.$modal.custom({
                            component: 'ModalLikeMessage',
                            options: {
                                title: this.$translate('SEND_LIKE_MESSAGE'),
                                content: this.$translate('SEND_LIKE_MESSAGE_CONTENT'),
                                targetId: this.user.id,
                                preventCloseOnMousedownMask: true,
                            },
                        })

                        if (res.like) {
                            this.userLike = res.like

                            this.$nativeBridge.postMessage({
                                action: 'sendFirebaseEvent',
                                value: {
                                    category: 'Done_Like',
                                    option: {
                                        target_user_id: this.user.id,
                                        channel: this.from,
                                        profile_status: this.$store.getters.profileStatus,
                                        target_profile_status: this.$targetUserProfileStatus(this.user),
                                    },
                                },
                            })
                        }
                    }
                }
            } else if (this.userLike.accepted) {
                // 대화방 이동
                const unreadCount = await this.$store.dispatch('loadChat', {
                    chatId: this.userLike.chat_id,
                    force: true,
                })

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'ProfilePage_Click_StartChat',
                        option: {
                            user_id: this.$store.getters.me.id,
                        },
                    },
                })

                this.$stackRouter.push({
                    name: 'ChatroomPage',
                    props: { unreadCount },
                })
            } else {
                try {
                    const data = await userService.acceptRequest(this.userLike.id)
                    this.$toast.success('매칭 성공! 이제 대화를 나눠보세요!')
                    this.userLike.accepted = true
                    this.userLike.chat_id = data.like.chat_id
                    this.$store.dispatch('loadChats', true)

                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'create_chat',
                            option: {
                                target_id: this.userLike.target.id,
                            },
                        },
                    })
                } catch (e) {
                    this.$toast.error(e.data.msg)
                }
            }
        },
        async updateMessage(updateStatus) {
            if (this.content.show_photo === false) {
                this.$toast.error('탈퇴 혹은 휴면했거나 활동이 정지된 회원입니다.')
                return
            }

            try {
                await chatService.acceptProfile(
                    (this.$store.getters.chat || {}).id,
                    this.profilePhotoMessage.id,
                    updateStatus
                )
                const message = (this.$store.getters.chat || {}).$$messages.find(
                    message => message.id === this.profilePhotoMessage.id
                )
                const content = this.$mustParse(message.content)
                // const content = this.$mustParse(this.profilePhotoMessage.content)

                // if (status === 'undo') delete content.accept_status
                // else {
                content.accept_status = updateStatus
                // }

                message.content = JSON.stringify(content)

                // this.updateAccepted(updateStatus)
            } catch (e) {
                this.$toast.error((e.data || {}).msg)
            }
        },
        async onClickProfilePhotoPass() {
            await this.updateMessage('rejected')
        },
        async onClickProfilePhotoAccept() {
            await this.updateMessage('accepted')
        },
    },
}
</script>

<style scoped lang="scss">
.two-btns-vertical {
    &.example {
        position: static;
        background: none;
        border: none;
    }

    padding: 16px;
    height: 80px;
    background: white;
    border-top: 1px solid $grey-02;
    width: 100%;
    z-index: 10;
    position: fixed;
    left: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    flex: 0 0 100%;
    justify-content: space-between;
}
</style>
