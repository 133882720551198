import Vue from 'vue'
import Router from 'vue-router'
import { store as $store } from '@/store'

import FrontPage from '@/routes/FrontPage'
import LoginPage from '@/routes/LoginPage'
import MyPage from '@/routes/my-page/MyPage'
import MyDatingPage from '@/routes/my-dating/MyDatingPage'
import NotificationsPage from '@/routes/notifications/NotificationsPage'
import ChatsPage from '@/routes/chat/chats/ChatsPage.vue'
import UserDetailPage from '@/routes/user-detail/UserDetailPage'
import NotFoundPage from '@/routes/NotFoundPage'
import ChaiEventPage from '@/routes/store/ChaiEventPage'
import ProfileUpdateRequestPage from '@/routes/profile-update-request/ProfileUpdateRequestPage'
import ForceAppUpdatePage from '@/routes/force-app-update/ForceAppUpdatePage'
import FindEmailPage from '@/routes/FindEmailPage'
import FindPasswordPage from '@/routes/FindPasswordPage'
import ResetPasswordPage from '@/routes/ResetPasswordPage'
import SystemMaintenanceNoticePage from '@/routes/SystemMaintenanceNoticePage'
import NPSMainPage from '@/routes/nps/NPSMainPage'
import NPSFeedbackPage from '@/routes/nps/NPSFeedbackPage'
import NTSMainPage from '@/routes/nts/NTSMainPage'
import NTSFeedbackPage from '@/routes/nts/NTSFeedbackPage'
import ReferrerPage from '@/routes/ReferrerPage'
import OtherAppPage from '@/routes/OtherAppPage'
import HomePage from '@/routes/HomePage'
import NewTermsPage from '@/routes/terms/NewTermsPage'
import FeedPage from '@/routes/feed/FeedPage'
import ReuploadPhotoPage from '@/routes/reupload-photo/ReuploadPhotoPage'
import CustomerCenterPage from '@/routes/customer-center/CustomerCenterPage'
import DormantPage from '@/routes/setting/DormantPage'
import LoungePage from '@/routes/lounge/LoungePage'

import signupRoutes from './signup'
import managerRoutes from './manager-match'

Vue.use(Router)

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
        props: true,
    },
    {
        path: '/not-found',
        name: 'NotFoundPage',
        component: NotFoundPage,
    },
    {
        path: '/reupload-photo',
        name: 'ReuploadPhotoPage',
        component: ReuploadPhotoPage,
    },
    {
        path: '/front',
        name: 'FrontPage',
        component: FrontPage,
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/my-dating',
        name: 'MyDatingPage',
        component: MyDatingPage,
    },
    {
        path: '/my-page',
        name: 'MyPage',
        component: MyPage,
    },
    {
        path: '/chats',
        name: 'ChatsPage',
        component: ChatsPage,
    },
    {
        path: '/user-detail',
        name: 'UserDetailPage',
        component: UserDetailPage,
    },
    {
        path: '/notifications',
        name: 'NotificationsPage',
        component: NotificationsPage,
    },
    {
        path: '/store/chai_event',
        name: 'ChaiEventPage',
        component: ChaiEventPage,
    },
    {
        path: '/profile-update-request',
        name: 'ProfileUpdateRequestPage',
        component: ProfileUpdateRequestPage,
    },
    {
        path: '/force-app-update',
        name: 'ForceAppUpdatePage',
        component: ForceAppUpdatePage,
    },
    {
        path: '/find-email',
        name: 'FindEmailPage',
        component: FindEmailPage,
    },
    {
        path: '/find-password',
        name: 'FindPasswordPage',
        component: FindPasswordPage,
    },
    {
        path: '/reset-password',
        name: 'ResetPasswordPage',
        component: ResetPasswordPage,
    },
    {
        path: '/system-maintenance-notice',
        name: 'SystemMaintenanceNoticePage',
        component: SystemMaintenanceNoticePage,
    },
    {
        path: '/nps-main',
        name: 'NPSMainPage',
        component: NPSMainPage,
    },
    {
        path: '/nps-feedback',
        name: 'NPSFeedbackPage',
        component: NPSFeedbackPage,
    },
    {
        path: '/nts-main',
        name: 'NTSMainPage',
        component: NTSMainPage,
    },
    {
        path: '/referrer',
        name: 'ReferrerPage',
        component: ReferrerPage,
    },
    {
        path: '/other-app',
        name: 'OtherAppPage',
        component: OtherAppPage,
    },
    {
        path: '/nts-feedback',
        name: 'NTSFeedbackPage',
        component: NTSFeedbackPage,
    },
    {
        path: '/new-terms',
        name: 'NewTermsPage',
        component: NewTermsPage,
    },
    {
        path: '/feed',
        name: 'FeedPage',
        component: FeedPage,
    },
    {
        path: '/lounge',
        name: 'LoungePage',
        component: LoungePage,
    },
    {
        path: '/customer-center',
        name: 'CustomerCenterPage',
        component: CustomerCenterPage,
    },
    {
        path: '/dormant',
        name: 'DormantPage',
        component: DormantPage,
    },
]

const createRouter = () =>
    new Router({
        routes: [...routes, ...signupRoutes, ...managerRoutes],
    })
const router = createRouter()

router.beforeEach((to, from, next) => {
    const found = router.options.routes.some(r => r.name === to.name)
    if (!found) {
        return next('/not-found')
    }

    window.onpopstate = () => {
        // If the dialog is opened,
        // just close the dialog and don't move route
        if ($store.getters.modals.length > 0) {
            const topCompoName = $store.getters.modals[$store.getters.modals.length - 1].component
            if (topCompoName === 'ModalLikeMessage') {
                return next(false)
            } else {
                $store.commit('popModal')
                return next(false)
            }
        }

        const currentCustomBackHandler = Vue.prototype.$backHandlers[to.name]
        if (currentCustomBackHandler) {
            const allowBack = currentCustomBackHandler()

            const needSomethingBeforeDestroy = () => {
                if (!$store.getters.pageStack.length) return false

                // 백버튼 동작 시 스택라우터 팝 이전에 특정 동작이 필요한 경우 페이지명 배열에 추가
                const arr = ['UserDetailPage', 'EditProfilePage']

                return arr.includes($store.getters.pageStack[$store.getters.pageStack.length - 1].name)
            }

            // 등록된 핸들러가 True여서 라우트 이동을 해야하는데, 스택라우트면 pop
            if (!!allowBack && $store.getters.pageStack.length > 0) {
                if (!needSomethingBeforeDestroy()) {
                    $store.dispatch('stackRouterPop')
                }
                return next(false)
            }

            // 핸들러 동작 이후 스택라우터 팝된 상태에서 백버튼 동작 방지
            if (!!allowBack && !needSomethingBeforeDestroy()) {
                return next(false)
            }

            return next(!!allowBack)
        }

        // 스택 라우터에 등록되있으면
        if ($store.getters.pageStack.length > 0) {
            $store.dispatch('stackRouterPop')
            return next(false)
        }

        if (Vue.prototype.$isRootRoute()) return next(false)
    }

    next()
})

router.afterEach(() => {
    Vue.prototype.$nativeBridge.postMessage({
        action: 'setCanGoBack',
        value: !Vue.prototype.$isRootRoute(),
    })
})

export default router
