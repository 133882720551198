<template>
    <div class="login flex">
        <div class="page-header flex-wrap">
            <div class="title flex-wrap" v-html="$translate('LOGIN')" />
        </div>
        <div class="ver-scroll flex">
            <form class="flex-fill">
                <input
                    class="form-input"
                    v-model="payload.email"
                    type="email"
                    :placeholder="$translate('PLACEHOLDER_EMAIL')"
                    @keydown.enter="login"
                />
                <input
                    class="form-input"
                    v-model="payload.password"
                    type="password"
                    :placeholder="$translate('PLACEHOLDER_PASSWORD')"
                    @keydown.enter="login"
                />
            </form>
            <div class="buttons flex-wrap">
                <button
                    @click="login"
                    class="btn btn-primary"
                    :class="{ disabled: disabled }"
                    v-html="$translate('LOGIN')"
                />
            </div>
            <!--            <div class="forgot flex-row flex-wrap f-medium">-->
            <!--                <div-->
            <!--                    class="flex-fill text-right"-->
            <!--                    v-html="$translate('FIND_EMAIL')"-->
            <!--                    @click="$router.push({ name: 'FindEmailPage' })"-->
            <!--                ></div>-->
            <!--                <div class="divider flex-wrap" v-html="'ㅣ'" />-->
            <!--                <div-->
            <!--                    class="flex-fill text-left"-->
            <!--                    v-html="$translate('FIND_PASSWORD')"-->
            <!--                    @click="$router.push({ name: 'FindPasswordPage' })"-->
            <!--                ></div>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'LoginPage',
    data: () => ({
        payload: {
            email: null,
            password: null,
            provider: null,
        },
    }),
    watch: {
        '$store.getters.thirdPartySignInData': {
            handler(newVal) {
                this.checkThirdParty(newVal)
            },
            deep: true,
        },
        '$store.getters.facebookData': {
            handler(newVal) {
                this.checkThirdParty(newVal)
            },
            deep: true,
        },
    },
    computed: {
        disabled() {
            return !this.payload.email || !this.payload.password
        },
    },
    methods: {
        async login() {
            if (this.disabled) {
                this.$toast.error('INVALID_LOGIN_PAYLOAD')
                return
            }

            try {
                this.$loading(true)
                await this.$store.dispatch('signIn', this.payload)
                this.$nativeBridge.postMessage({
                    action: 'sendAirbridgeEvent',
                    value: {
                        eventType: 'signIn',
                        user: { email: this.payload.email },
                    },
                })
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Login',
                        profile_status: this.$store.getters.profileStatus,
                    },
                })
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        fbLogin() {
            this.provider = 'facebook'
            this.$nativeBridge.postMessage({ action: 'signupWithFB' })
        },
        appleLogin() {
            // const { app_version: version } = this.$store.getters.device
            // if (version < '1.0.3') {
            //     this.$toast.error('앱 업데이트 후 사용 가능합니다.')
            //     return
            // }
            this.provider = 'apple'
            this.$nativeBridge.postMessage({ action: 'signupWithApple' })
        },
        async checkThirdParty(data) {
            try {
                this.$loading(true)
                if (data.user_id) {
                    data.uid = data.user_id
                }
                data.provider = this.provider
                const { user, login_status: status } = await userService.checkThirdParty(data)

                if (user && status === 'sign_in') {
                    await this.$store.dispatch('signInThirdParty', { user })
                } else if (status === 'sign_up') {
                    this.$toast.error('등록된 계정이 없습니다. 회원가입을 해주세요.')
                }
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.login {
    padding-bottom: 40px;

    .page-header {
        margin-left: 20px !important;
        .title {
            font-family: NotoSans-Medium !important;
        }
    }

    input {
        display: block;
        width: 100%;
        padding: 12px 16px;
        margin-bottom: 10px;
        -webkit-appearance: none;
        line-height: normal;
    }

    .ver-scroll {
        min-height: 480px;
    }

    form {
        padding: 32px 20px;
    }

    .buttons {
        padding: 0 20px;

        button {
            width: 100%;
            margin-bottom: 12px;
            height: 48px;
            font-size: 15px;
            box-shadow: none;
        }

        .btn-brd {
            color: $blue-facebook;

            img {
                width: 22px;
                margin-right: 10px;
            }

            &.btn-apple {
                color: black;
                white-space: nowrap;

                img {
                    width: 18px;
                }
            }
        }
    }

    .forgot {
        margin-top: 8px;
        padding: 8px 0;
        color: $grey-07;

        .divider {
            margin: 0 40px;
        }
    }
}
</style>
