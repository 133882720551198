<template>
    <span class="floating-back-button" @click="onClick">
        <i class="material-icons">chevron_left</i>
    </span>
</template>

<script>
export default {
    methods: {
        onClick() {
            this.$emit('back')
        },
    },
}
</script>

<style lang="scss" scoped>
    .floating-back-button {
        position: absolute;
        top: 12px;
        left: 16px;

        .material-icons {
            font-size: 30px;
            color: white;
        }
    }
</style>
