<template>
    <div class="black-screen modal" @click.stop="onClickClose">
        <div class="black-screen-header w-100">
            <i @click="onClickClose" class="material-icons">close</i>
        </div>
        <div class="intro-container w-100">
            <div class="introduction">
                <div class="accept-button-intro">
                    <div class="text">
                        <p><span class="purple">상대가 수락해야</span></p>
                        <p>대화를 나눌 수 있어요!</p>
                    </div>
                    <div class="arrow-container">
                        <img :src="require('@/assets/images/user-detail/purple-curved-arrow.png')" alt="" />
                    </div>
                </div>
                <div class="hotchat-intro">
                    <div class="text">
                        <p><span class="orange">상대의 수락 없이 </span>바로</p>
                        <p>대화를 나눌 수 있어요!</p>
                    </div>
                    <div class="arrow-container">
                        <img :src="require('@/assets/images/user-detail/orange-curved-arrow.png')" alt="" />
                    </div>
                </div>
            </div>
            <TwobuttonsVertical :example="true" />
        </div>
    </div>
</template>

<script>
import TwobuttonsVertical from '@/components/app/TwobuttonsVertical'
export default {
    name: 'BlackScreen',
    components: {
        TwobuttonsVertical,
    },
    mounted() {
        localStorage.setItem('how-to-use', true)
    },
    methods: {
        onClickClose() {
            this.$emit('onClickClose')
        },
    },
}
</script>

<style scoped lang="scss">
.black-screen {
    i {
        color: white;
        font-size: 24px;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .black-screen-header {
        display: flex;
        flex-direction: row-reverse;
        padding: 24px;
    }
    .introduction {
        width: 100%;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;

        .text {
            p {
                font-size: 15px;
                color: white;
                text-align: center;
                line-height: 20px;
                @include spoqa-f-regular;

                span {
                    border-radius: 3px;
                    padding: 1px 2px;
                    &.purple {
                        background: $purple-primary;
                    }
                    &.orange {
                        background: #ffaa00;
                    }
                }
            }
        }
        .arrow-container {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 8px;
            img {
                width: 13px;
                height: 37px;
            }
        }
    }
}
</style>
