import * as $http from 'axios'

export default {
    all: (limit, lastId, page, params = {}) =>
        $http.get(`v2/users?limit=${limit || 20}${lastId ? `&last_id=${lastId}` : ''}&page=${page || 1}`, {
            params: {
                state_ids: params ? params.state_ids : '',
                min_age: params ? params.min_age : '',
                max_age: params ? params.max_age : '',
                marry_type: params ? params.marry_type : '',
                feed_sort_type: params ? params.feed_sort_type : 1,
            },
        }),
    detail: userId => {
        return $http.get(`v2/users/${userId}`)
    },
    me: () => $http.get('v2/users/me'),
    updateTerms: payload => $http.put('v2/users/me', payload),
    updateSeenNew: userId => $http.put(`v2/users/${userId}/update_seen_new`),
    showMostAcceptable: userId => $http.get(`v2/users/${userId}/show_most_acceptable`),
    checkMostAcceptable: userId => $http.get(`v2/users/${userId}/check_most_acceptable`),
    settings: () => $http.get('v2/users/me/settings'),
    chunkedNotifications: offset => $http.get(`users/chunked_notifications?offset=${offset}`),
    registerDevice: payload => $http.post('users/register_device', payload),
    badges: userId => $http.get(`v2/users/${userId}/badge`),
    deleteToken: registrationId => $http.post('users/logout_delete_token', { registration_id: registrationId }),
    checkNickname: nickname => $http.get(`v2/users/check_nickname?nickname=${nickname}`),
    checkEmail: email => $http.get(`v2/users/check_email?email=${email}`),
    checkSignup: (email, phoneNumber) => $http.get(`v2/users/check_signup?email=${email}&phone_number=${phoneNumber}`),
    checkBirth: params => $http.get('v2/users/check_birth', { params }),
    create: payload => $http.post('v2/users', payload),
    complete: payload => $http.put('v2/users/me/complete', payload),
    createStyles: style => $http.post('v2/users/me/styles', style),
    signupFriends: () => $http.post('v2/users/me/signup_friends'),
    update: payload => $http.put('v2/users/me', payload),
    photosWithShorten: (userId, shorten) => $http.get(`v2/users/${userId}/photos?shorten=${shorten}&is_new=true`),
    checkDropout: () => $http.get('users/check_shared_contact'),
    dropout: reason => $http.put('dropout', reason),
    changePassword: payload => $http.post('users/change_password', payload),
    dormant: payload => $http.put('v2/users/me/dormant', payload),
    notices: () => $http.get('v2/notices'),
    updateMainPhoto: photoId => $http.put(`v2/users/me/photos/${photoId}/set_main`),
    getUnabledPhotos: ids => $http.get(`v2/users/me/photos/unabled?ids=${ids}`),
    items: () => $http.get('users/me/items'),
    checkAppVersion: (platform, appVersion) =>
        $http.get(`users/check_app_version?version_number=${appVersion}&platform=${platform}`),
    checkThirdParty: payload => $http.post('v2/users/check_third_party', payload),
    updateProfileUpdateRequest: (id, payload) => $http.put(`v2/users/me/profile_update_requests/${id}`, payload),
    verificationCode: {
        create: payload => $http.post('v2/verification_codes', payload),
        confirm: payload => $http.post('v2/verification_codes/confirm', payload),
    },
    resetPassword: ({ userId, responseId, newPassword }) =>
        $http.put(`v2/users/${userId}/reset_password`, {
            response_id: responseId,
            new_password: newPassword,
        }),
    reportUser: {
        create: (userId, payload) => $http.post(`v2/users/${userId}/report_users`, payload),
        update: (userId, reportUserId, payload) =>
            $http.put(`v2/users/${userId}/report_users/${reportUserId}`, payload),
    },
    blockUser: {
        create: userId => $http.post(`v2/users/${userId}/blocked_users`),
    },
    sendSms: (userId, payload) => $http.post(`v2/users/${userId}/send_sms`, payload),
    giveTicketDiscount: userId => $http.post(`v2/users/${userId}/give_ticket_discount`),
    checkOrderHistory: (userId, { ptype, pay_result }) =>
        $http.post(`v2/users/${userId}/check_order_history`, { ptype, pay_result }),
    useInvitationPoint: (userId, payload) => $http.post(`v2/users/${userId}/use_invitation_point`, payload),
    premiumAgent: (userId, agentId) => $http.get(`v2/users/${userId}/premium_agent?agent_id=${agentId || ''}`),
    premiumCounsel: userId => $http.put(`v2/users/${userId}/premium_counsel`),
    updateNickname: (userId, payload) => $http.put(`v2/users/${userId}/update_nickname`, payload),
    registerContacts: (userId, payload) => $http.post(`v2/users/${userId}/multi_contacts`, payload),
    getContactsLength: userId => $http.get(`v2/users/${userId}/contacts_length`),
    hostCheck: () => $http.get('/v2/users/me/host_checked'),
    registerDeviceId: ({ userId, deviceId, adid }) =>
        $http.post(`/v2/users/${userId}/register_device_id`, { device_id: deviceId, adid }),
    uploadPhotos: payload => $http.post(`v2/users/me/upload_photos`, payload),
    sendInvite: number => $http.post('v2/users/me/send_invite', { number }),
    participateCampaign: () => $http.put(`v2/users/me/no_ons_campaign`),

    // like
    likeAll: () => $http.get('v2/users/me/likes'),
    sendRequest: payload => $http.post('v2/users/me/likes', payload),
    payLike: payload => $http.post('v2/users/me/likes/pay', payload),
    checkRequest: likeId => $http.put(`v2/users/me/likes/${likeId}/check`),
    acceptRequest: likeId => $http.put(`v2/users/me/likes/${likeId}/accept`),
    refundRequest: payload => $http.post('v2/users/me/likes/refund', payload),

    // user_rating
    starRateAll: () => $http.get('v2/users/me/user_ratings'),
    starRate: payload => $http.post('v2/users/me/user_ratings', payload),
    starRateCheck: ratingId => $http.put(`v2/users/me/user_ratings/${ratingId}/check`),
    starRateOpen: ratingId => $http.put(`v2/users/me/user_ratings/${ratingId}/open`),
    referrers: payload => $http.post('v2/users/me/referrers/', payload),

    // manager
    createManager: payload => $http.post('v2/managers', payload),
    clickSignUp: payload => $http.put('v2/managers/click_signup', payload),
    updateManager: payload => $http.put('v2/users/me/managers/update_managers', payload),
    applySignup: payload => $http.put('v2/managers/applicate_signup', payload),
}
