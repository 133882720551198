<template>
    <div
        v-if="
            myReceiveLikes.length !== 0 ||
            mySendLikes.length !== 0 ||
            myReceiveRatings.length !== 0 ||
            mySendRatings.length !== 0
        "
        class="my-dating"
        @scroll="onScroll"
    >
        <div class="top-info" v-html="$translate('MY_DATING_EXPIRE')" />
        <ReceivedLikes :onClick="onClickReceiveLike" v-if="myReceiveLikes.length !== 0" :likes="myReceiveLikes" />
        <ReceivedRatings
            :goUserDetail="goUserDetail"
            v-if="myReceiveRatings.length !== 0"
            :ratings="myReceiveRatings"
        />
        <SendLikes :onClick="onClickSend" v-if="mySendLikes.length !== 0" :likes="mySendLikes" />
        <SendRatings :onClick="onClickSend" v-if="mySendRatings.length !== 0" :ratings="mySendRatings" />
    </div>
    <BlankPage v-else :image="blankImage" :title="$translate('DATING_EMPTY')" />
</template>

<script>
import ReceivedLikes from '@/routes/my-dating/components/ReceivedLikes'
import ReceivedRatings from '@/routes/my-dating/components/ReceivedRatings'
import SendLikes from '@/routes/my-dating/components/SendLikes'
import SendRatings from '@/routes/my-dating/components/SendRatings'
import userService from '@/services/user'

export default {
    name: 'MyDatingPage',
    components: {
        ReceivedLikes,
        ReceivedRatings,
        SendLikes,
        SendRatings,
    },
    data: () => ({
        scrollTop: 0,
        likes: null,
        ratings: null,
    }),
    mounted() {
        this.init()
    },
    computed: {
        myReceiveLikes() {
            return this.$store.getters.myReceivedLikes || []
        },
        mySendLikes() {
            return this.$store.getters.mySendLikes || []
        },
        myReceiveRatings() {
            return this.$store.getters.myReceivedRatings || []
        },
        mySendRatings() {
            return this.$store.getters.mySendRatings || []
        },
        blankImage() {
            return {
                type: 'image',
                url: require('@/assets/images/cross-heart.png'),
            }
        },
    },
    methods: {
        async init() {
            this.likes = await userService.likeAll()
            this.ratings = await userService.starRateAll()

            this.$store.commit('setMyDatings', { likes: this.likes, ratings: this.ratings })
        },
        initMyDatingBadge() {
            window.localStorage.setItem('myDatingBadge', 0)
            this.$store.commit('setMyDatingBadge', false)
        },
        async onScroll({ target }) {
            this.scrollTop = target.scrollTop
        },
        async onClickReceiveLike(like, from) {
            if (!like.checked) {
                await userService.checkRequest(like.id)
                this.$store.dispatch('updateReceivedLike', like)
                this.$store.dispatch('loadBadges')
            }
            this.goUserDetail(like, from)
        },
        onClickSend(like, from) {
            this.goUserDetail(like, from)
        },
        goUserDetail(like, from) {
            if (this.$isDormant(like.target)) {
                this.$modal.basic({
                    title: '안내',
                    body: `죄송합니다. 상대가 휴면 신청을 하여 프로필을 열람할 수 없습니다.`,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else {
                this.$stackRouter.push({
                    name: 'UserDetailPage',
                    props: {
                        userId: like.target.id,
                        from: from,
                    },
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.my-dating {
    .top-info {
        margin: 16px;
        padding: 10px 0px;
        text-align: center;
        font-size: 13px;
        color: $grey-09;
        border-radius: 8px;
        background: rgba($purple-primary, 0.08);
    }

    .empty {
        align-items: center;
        margin-top: 30vh;

        .heart-icon {
            width: 40px;
            margin-bottom: 20px;
        }

        .text {
            font-size: 14px;
            @include spoqa-f-regular;
            color: $grey-06;
            text-align: center;
        }
    }
}
::v-deep .section {
    margin: 40px 0;
    border-bottom: 1px solid $grey-02;

    .title {
        @include f-medium;
        font-size: 20px;
        color: black;
        padding: 0 16px;
        display: flex;

        .red-dot {
            width: 8px;
            height: 8px;
            background: $pink-deep;
            border-radius: 50%;
            margin-top: 4px;
            margin-left: 4px;
        }
    }
    img {
        @include round-image;
    }
}
</style>
