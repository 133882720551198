<template>
    <div class="native-bridge"></div>
</template>

<script>
import axios from 'axios'
import userService from '@/services/user'

export default {
    name: 'NativeBridge',
    mounted() {
        document.addEventListener('message', this.onNativeMessage) // Android
        window.addEventListener('message', this.onNativeMessage) // iOS
    },
    beforeDestroy() {
        document.removeEventListener('message', this.onNativeMessage) // Android
        window.removeEventListener('message', this.onNativeMessage) // iOS
    },
    methods: {
        onNativeMessage(event) {
            const data = this.$mustParse(event.data)

            if (!data || !data.action) return
            const httpHeader = this.$http.defaults.headers.common

            switch (data.action) {
                case 'registration':
                    this.$store.commit('setDeviceToken', {
                        role: 'user',
                        platform: data.platform,
                        registration_id: data.registration_id,
                        adid: data.adid,
                    })
                    break
                case 'stackRouterGoBack':
                    if (
                        this.$store.getters.pageStack[this.$store.getters.pageStack.length - 1].name === 'ChatroomPage'
                    ) {
                        this.$stackRouter.pop()
                        this.$router.push({ name: 'ChatsPage' })
                    }
                    break
                case 'niceData':
                    this.$store.commit('setNiceData', data.params)
                    break
                case 'resetPassword':
                    this.$bus.$emit('resetPassword', data.url)
                    break
                case 'setDevice':
                    if (data.device) {
                        this.setDeviceAndHeader(data.device, httpHeader)
                        this.$store.commit('setDevice', data.device)
                    }
                    break
                case 'toast':
                    this.$toast[data.value.type](data.value.message)
                    break
                case 'thirdPartySignIn':
                    this.$store.commit('setThirdPartySignInData', data.value)
                    break
                case 'facebookData':
                    this.$store.commit('setFacebookData', data.value)
                    break
                case 'changeAppState':
                    if (data.value === 'foreground') {
                        const callbacks = async() => {
                            await this.$store.dispatch('loadChats', true)
                            const isChatroom = ((this.$store.getters.pageStack[0] || []).name || '') === 'ChatroomPage'
                            // 현재 라우트가 chatroom이 아닌데, 스토어에는 chat 값이 남아 있으면 해당 chat을 다시 불러옴
                            if (this.$store.getters.chat && isChatroom) {
                                this.$store.dispatch('loadChat', {
                                    chatId: this.$store.getters.chat.id,
                                    force: true,
                                })
                            } else {
                                // 현재 chatroom이면 메시지 다시 불러오기
                                this.$bus.$emit('reloadMessage')
                            }
                        }

                        setTimeout(() => {
                            this.$bus.$emit('onRequestReconnect')
                            if (this.$store.getters.me) callbacks()
                        }, 200)
                    } else if (data.value === 'background') {
                        this.$bus.$emit('onRequestDisconnect')
                        this.$store.commit('setIsAppFirstLoaded', false)
                    }

                    this.$updateAppIconBadgeCount()
                    break
                case 'kakaoSignIn':
                    this.$toast.success(data.params.status)
                    if (data.params.status === 'signup') {
                        this.$store.commit('setPayloads', {
                            signupData: {
                                email: data.params.email,
                                uid: data.params.uid,
                                birthyear: data.params.birthyear,
                                birthday: data.params.birthday,
                                gender: data.params.gender,
                                phone_number: data.params.phone_number,
                                provider: 'kakao',
                            },
                        })
                        this.$toast.success(
                            data.params.birthyear,
                            data.params.birthday,
                            data.params.phone_number,
                            data.params.gender,
                        )
                        let { provider, email, phone_number } = this.$store.getters.payloads.signupData
                        const agentData = this.$store.getters.signupUserFromAgent
                        provider = 'kakao'
                        if (agentData) {
                            this.$router.push({ name: 'HeightPage' })
                        } else if (provider.match(/facebook|apple/i) && email) {
                            // this.$router.push({ name: 'GenderPage' })
                            this.$router.push({ name: 'NicknamePage' })
                        } else if (provider.match(/kakao/i) && email) {
                            const { res } = this.checkAlreadySignup(email, phone_number)
                            if (res.res_email && res.res_phone) {
                                this.$store.dispatch('signInThirdParty', {
                                    user: { email: data.params.email, token: data.params.token },
                                    uid: data.params.uid,
                                })
                            } else if (res.res_email || res.res_phone) {
                                // email phonenumber 중복 있는지 체크하기
                                this.$router.push({
                                    name: 'SignupCheckPage',
                                    params: { provider, email, phone_number, uid: res.uid },
                                })
                                // this.$router.push({ name: 'NicknamePage' , params: { provider }})
                            } else {
                                // email 이 유효하지 않음
                                // this.$router.push({ name: 'SignupCheckPage' , params: { provider }})
                                this.$router.push({ name: 'NicknamePage', params: { provider } })
                            }
                        } else {
                            this.$router.push({ name: 'EmailPage' })
                        }
                    } else if (data.params.status === 'signin') {
                        this.$store.dispatch('signInThirdParty', {
                            user: { email: data.params.email, token: data.params.token },
                            uid: data.params.uid,
                        })
                    }
                    break
                case 'openPushNotification': {
                    this.onPushNotifications(data.value)
                    break
                }
                case 'checkInAppPurchase': {
                    this.$store.dispatch('purchaseInAppProduct', data.value)
                    break
                }
                case 'finishInAppPurchase': {
                    this.$store.dispatch('finishInAppPurchase')
                    break
                }
                case 'errorInAppPurchase': {
                    this.$loading(false)
                    break
                }
                case 'sendContactList': {
                    this.$store.commit('setContacts', data.value)

                    break
                }
                case 'sendPhoto': {
                    this.imagePreprocessing(data.value).then(blob => {
                        this.$bus.$emit('onRequestPhotoFromGallery', {
                            blob,
                            fileName: data.value.fileNAme,
                        })
                    })

                    break
                }
            }
        },
        setDeviceAndHeader(device, header) {
            // 앱을 삭제하지 않고 업데이트하는 경우 로컬에 저장된 헤더 값을 업데이트 해주어야함
            if (!device || !header) return
            let { 'X-User-Agent': userAgent, 'X-User-OS': userOS } = header

            if (!userAgent || !userOS) return

            if (userAgent && userOS) {
                userAgent = JSON.parse(userAgent)
            }

            let shouldUpdateUserAgent = false
            let shouldUpdateUserOS = false

            for (const prop in device) {
                if (device[prop] !== userAgent[prop]) {
                    userAgent[prop] = device[prop]
                    shouldUpdateUserAgent = true
                }
            }

            if (shouldUpdateUserAgent) axios.defaults.headers.common['X-User-Agent'] = JSON.stringify(userAgent)

            if (device.os !== userOS) {
                userOS = device.os
                axios.defaults.headers.common['X-User-OS'] = userOS
                shouldUpdateUserOS = true
            }

            if (shouldUpdateUserAgent || shouldUpdateUserOS) {
                const newHeader = axios.defaults.headers.common
                window.localStorage.removeItem('header')
                window.localStorage.setItem('header', JSON.stringify(newHeader))
            }
        },
        onPushNotifications(remoteMessage) {
            if (!remoteMessage || !remoteMessage.action) return

            switch (remoteMessage.action) {
                // TO-DO
                case 'chats': {
                    this.$nativeBridge.postMessage({
                        action: 'clickChatPush',
                        value: true,
                    })
                    setTimeout(() => {
                        this.openChat(remoteMessage)
                    }, 1000)
                    break
                }

                case 'feeds': {
                    setTimeout(() => {
                        this.$router.push({ name: 'MyDatingPage' })
                    }, 1000)
                    break
                }

                case 'profiles': {
                    setTimeout(() => {
                        this.$stackRouter.push({ name: 'EditProfilePage' })
                    }, 1000)
                    break
                }

                case 'uncomplete_signup': {
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'Click_Push',
                            option: {
                                user_id: (this.$store.getters.me || {}).id,
                                label: 'signup',
                            },
                        },
                    })

                    setTimeout(() => {
                        this.$router.push({ name: 'FeedPage' })
                    }, 1000)

                    break
                }

                case 'inform_new_user': {
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'Click_Push',
                            option: {
                                user_id: (this.$store.getters.me || {}).id,
                                label: 'newmember',
                            },
                        },
                    })

                    setTimeout(() => {
                        this.$router.push({ name: 'FeedPage' })
                    }, 1000)

                    break
                }

                case 'introduces': {
                    setTimeout(() => {
                        this.openRealFriendPostDetail(remoteMessage)
                    }, 1000)
                    break
                }

                case 'lounges': {
                    setTimeout(() => {
                        this.openLoungePage(remoteMessage)
                    }, 1000)
                    break
                }

                case 'click_suggest': {
                    setTimeout(() => {
                        this.$store.dispatch('loadSuggests').then(() => {
                            this.$router.push({ name: 'SuggestedDatingPage' })
                        })
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'Click_Push_FromSuggest',
                            },
                        })
                    }, 1000)
                    break
                }

                case 'front_page': {
                    setTimeout(() => {
                        this.$router.push({ name: 'FrontPage' })
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'Click_Push_FromCustomerChurn',
                            },
                        })
                    }, 1000)
                    break
                }

                case 'verification_badge': {
                    setTimeout(() => {
                        this.$stackRouter.push({ name: 'VerificationBadgesPage' })
                        // this.$nativeBridge.postMessage({
                        //     action: 'sendFirebaseEvent',
                        //     value: {
                        //         category: 'Click_Push_FromVerificationBadge',
                        //     },
                        // })
                    }, 1000)
                    break
                }

                case 'verification_trust_badge': {
                    setTimeout(() => {
                        this.$stackRouter.push({ name: 'VerificationTrustBadgesPage' })
                        // this.$nativeBridge.postMessage({
                        //     action: 'sendFirebaseEvent',
                        //     value: {
                        //         category: 'Click_Push_FromVerificationBadge',
                        //     },
                        // })
                    }, 1000)
                    break
                }

                case 'invitation_friend': {
                    setTimeout(() => {
                        this.$stackRouter.push({ name: 'InvitationFriendPage' })
                        // this.$nativeBridge.postMessage({
                        //     action: 'sendFirebaseEvent',
                        //     value: {
                        //         category: 'Click_Push_FromInvitationFriend',
                        //     },
                        // })
                    }, 1000)
                    break
                }

                case 'reject_profile': {
                    setTimeout(() => {
                        this.$router.push({ name: 'MyPage' })
                    }, 1000)
                    break
                }

                case 'dating_reviews_page': {
                    setTimeout(() => {
                        this.$stackRouter.push({ name: 'DatingReviewsPage' })
                    }, 1000)
                    break
                }

                case 'dating_candidate_select_page': {
                    setTimeout(() => {
                        this.$stackRouter.push({ name: 'DatingCandidateSelectPage' })
                    }, 1000)
                    break
                }
            }
        },
        async openLoungePage(message) {
            try {
                await this.$store.dispatch('loadLoungePosts')

                if (message.action_id) {
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'Click_Push',
                            option: {
                                label: 'lounge_bestpost',
                            },
                        },
                    })

                    const post = this.$store.getters.lounge.allPosts.find(post => post.id === Number(message.action_id))
                    if (!post) {
                        this.$router.push({ name: 'LoungePage' })
                        return
                    }

                    this.$router.push({ name: 'LoungePage' }).then(() => {
                        this.$stackRouter.push({
                            name: 'LoungePostDetailPage',
                            props: {
                                post,
                                from: 'all',
                            },
                        })
                    })
                } else {
                    this.$router.push({ name: 'LoungePage' })
                }
            } catch (e) {}
        },
        async openRealFriendPostDetail(message) {
            try {
                await this.$store.dispatch('loadRealfriendMeetingPosts')

                const allPosts = this.$store.getters.realfriendMeeting.allPosts || []

                const id = typeof message.action_id === 'number' ? message.action_id : Number(message.action_id)
                const targetPost = allPosts.find(post => post.id === id)

                if (!targetPost) return

                this.$stackRouter.push({
                    name: 'RealFriendMeetingPostDetailPage',
                    props: {
                        post: targetPost,
                        from: 'all',
                    },
                })
            } catch (e) {
                // alert(e.data)
            }
        },
        async openChat(message) {
            const loadChat = async () => {
                try {
                    let chats = this.$store.getters.chats || []

                    if (chats.length === 0) {
                        chats = await this.$store.dispatch('loadChats', true)
                    }

                    const chatId = typeof message.action_id === 'number' ? message.action_id : Number(message.action_id)

                    if (chatId) {
                        const chat = chats.find(c => c.id === chatId)

                        if (!chat) return

                        await this.$store.dispatch('loadChat', {
                            chatId: chatId,
                            force: true,
                        })

                        const unreadCount = message.badge

                        this.$stackRouter.push({
                            name: 'ChatroomPage',
                            props: { unreadCount },
                        })
                    }
                } catch (e) {
                    // alert(e)
                }
            }

            await loadChat()
        },
        async imagePreprocessing({ base64, type }) {
            try {
                const res = await fetch(`data:${type};base64, ${base64}`)
                const blob = await res.blob()

                return blob
            } catch (e) {
                console.error(e)
            }
        },
        async checkAlreadySignup(email, phoneNumber) {
            const { res } = await userService.checkSignup(email, phoneNumber)
            return res
        },
    },
}
</script>
