<template>
    <SubProfileOptionSelector :model="'car_type'" :nextRoute="() => nextRoute" />
</template>

<script>
export default {
    name: 'CarTypePage',
    data: () => ({
        nextRoute: 'HouseStylePage',
    }),
    mounted() {},
}
</script>

<style scoped></style>
