<template>
    <ProfileOptionSelector :model="'region'" :nextRoute="() => 'SchoolPage'" />
</template>

<script>
export default {
    name: 'RegionPage',
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_RegionPage',
            },
        })
    },
}
</script>
