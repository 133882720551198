import './initCompositionAPI'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMoment from 'vue-moment'
import './modules/axios'
import VueLazyLoad from 'vue-lazyload'
import { Carousel, Slide } from 'vue-carousel'
import VueSlider from 'vue-slider-component'
import VueClipboard from 'vue-clipboard2'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import VueAnalytics from 'vue-analytics'

import * as filter from '@/filters'
import * as Global from '@/plugins/global'
import * as directives from '@/directives'
import { store } from '@/store'

import ProfileOptionSelector from '@/routes/signup/components/ProfileOptionSelector'
import SubProfileOptionSelector from '@/routes/signup/components/SubProfileOptionSelector'
import BadgeVanilla from '@/components/common/BadgeHeart'
import AppearanceRating from '@/components/common/AppearanceRating'
import SchoolJob from '@/components/common/SchoolJob'
import StarRating from '@/components/common/StarRating'
import Distance from '@/components/common/Distance'
import Checkbox from '@/components/app/Checkbox'
import Toggler from '@/components/app/Toggler'
import Loading from '@/components/app/Loading'
import Tabs from '@/components/app/Tabs'
import Snackbar from '@/components/app/Snackbar'
import ProgressBar from '@/components/app/ProgressBar'
import BottomButton from '@/components/app/BottomButton'
import TextareaWithX from '@/components/app/TextareaWithX'
import TransitionExpand from '@/components/app/TransitionExpand'
import StackRouterView from '@/components/app/StackRouterView'
import StackRouterHeaderBar from '@/components/app/StackRouterHeaderBar'
import blankProfile from '@/assets/images/blank_profile.svg'
import Slider from '@/components/app/Slider'
import MarryState from '@/components/common/MarryState'
import BaseProfileInfo from '@/routes/my-dating/components/BaseProfileInfo'
import BlankPage from '@/components/common/BlankPage'
import TwoButtons from '@/components/app/TwoButtons'
import signupProgressBar from '@/routes/signup/components/ProgressBar'
import { hackleClient } from '@/modules/hackle'

Vue.use(VueClipboard)
Vue.use(VueLazyLoad, {
    error: blankProfile,
})
Vue.use(VueAnalytics, {
    id: process.env.NODE_ENV === 'production' ? 'UA-92338009-6' : 'UA-92338009-4',
    router,
})

// This $bus must be defined here for this to be able to be accessed in filters, plugins, directives.
Vue.prototype.$bus = new Vue({})
Vue.prototype.$hackleClient = hackleClient

Object.keys(filter).forEach(k => {
    Vue.filter(k, filter[k])
})
Object.keys(Global).forEach(k => {
    Vue.use(Global[k])
})
Object.keys(directives).forEach(k => {
    Vue.directive(k, directives[k])
})
Vue.use(VueMoment)

Vue.component('BadgeVanilla', BadgeVanilla)
Vue.component('AppearanceRating', AppearanceRating)
Vue.component('SchoolJob', SchoolJob)
Vue.component('StarRating', StarRating)
Vue.component('Distance', Distance)
Vue.component('ProfileOptionSelector', ProfileOptionSelector)
Vue.component('SubProfileOptionSelector', SubProfileOptionSelector)
Vue.component('Carousel', Carousel)
Vue.component('Slide', Slide)
Vue.component('VueSlider', VueSlider)
Vue.component('Checkbox', Checkbox)
Vue.component('Toggler', Toggler)
Vue.component('Loading', Loading)
Vue.component('Tabs', Tabs)
Vue.component('Snackbar', Snackbar)
Vue.component('ProgressBar', ProgressBar)
Vue.component('BottomButton', BottomButton)
Vue.component('TextareaWithX', TextareaWithX)
Vue.component('TransitionExpand', TransitionExpand)
Vue.component('StackRouterView', StackRouterView)
Vue.component('StackRouterHeaderBar', StackRouterHeaderBar)
Vue.component('Slider', Slider)
Vue.component('MarryState', MarryState)
Vue.component('BaseProfileInfo', BaseProfileInfo)
Vue.component('BlankPage', BlankPage)
Vue.component('TwoButtons', TwoButtons)
Vue.component('SignupProgressBar', signupProgressBar)

require('@/assets/styles/index.scss')

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app')

if (process.env.NODE_ENV === 'test') {
    Sentry.init({
        dsn: 'https://f6c4e4a2ba9f4ed4bc6e1660c5dd269a@o205628.ingest.sentry.io/1435637',
        integrations: [new VueIntegration({ Vue, attachProps: true })],
    })
}
