import EmailPage from '@/routes/signup/basics/EmailPage'
import PasswordPage from '@/routes/signup/basics/PasswordPage'
import MarryPage from '@/routes/signup/additional-profiles/MarryPage'
import HaveChildPage from '@/routes/signup/additional-profiles/HaveChildPage'
import IncomePage from '@/routes/signup/additional-profiles/IncomePage'
import AssetPage from '@/routes/signup/additional-profiles/AssetPage'
import IntroduceMyselfPage from '@/routes/signup/additional-profiles/IntroduceMyselfPage'
import NicknamePage from '@/routes/signup/basics/NicknamePage'
import StatePage from '@/routes/signup/locations/StatePage'
import StationPage from '@/routes/signup/locations/StationPage'
import RegionPage from '@/routes/signup/locations/RegionPage'
import SchoolPage from '@/routes/signup/schools/SchoolPage'
import SchoolTypePage from '@/routes/signup/schools/SchoolTypePage'
import UniversityPage from '@/routes/signup/schools/UniversityPage'
import JobCategoryPage from '@/routes/signup/jobs/JobCategoryPage'
import CompanyPage from '@/routes/signup/jobs/CompanyPage'
import JobPage from '@/routes/signup/jobs/JobPage'
// import BirthdayPage from '@/routes/signup/additional-profiles/BirthdayPage'
import GenderPage from '@/routes/signup/additional-profiles/GenderPage'
import HeightPage from '@/routes/signup/additional-profiles/HeightPage'
import KindsPage from '@/routes/signup/additional-profiles/KindsPage'
import ReligionPage from '@/routes/signup/additional-profiles/ReligionPage'
import ShapesPage from '@/routes/signup/additional-profiles/ShapesPage'
import SignupCheckPage from '@/routes/signup/additional-profiles/SignupCheckPage'
import SmokingPage from '@/routes/signup/additional-profiles/SmokingPage'
import StartWithPhonePage from '@/routes/signup/StartWithPhonePage'
import UploadPhotoPage from '@/routes/signup/UploadPhotoPage'
import VerificationPage from '@/routes/signup/VerificationPage'
import BirthdayPage from '@/routes/signup/basics/BirthdayPage'

// sub profiles
import HometownPage from '@/routes/signup/additional-profiles/HometownPage'
import CarTypePage from '@/routes/signup/additional-profiles/CarTypePage'
import HouseStylePage from '@/routes/signup/additional-profiles/HouseStylePage'
import HousePaymentPage from '@/routes/signup/additional-profiles/HousePaymentPage'
import ExercisePage from '@/routes/signup/additional-profiles/ExercisePage'
import PetPage from '@/routes/signup/additional-profiles/PetPage'
import TagPage from '@/routes/signup/additional-profiles/TagPage'

export default [
    {
        path: '/signup/basics/birthday',
        name: 'BirthdayPage',
        component: BirthdayPage,
    },
    {
        path: '/signup/basics/email',
        name: 'EmailPage',
        component: EmailPage,
    },
    {
        path: '/signup/basics/password',
        name: 'PasswordPage',
        component: PasswordPage,
    },
    {
        path: '/signup/basics/nickname',
        name: 'NicknamePage',
        component: NicknamePage,
    },
    {
        path: '/signup/basics/marry',
        name: 'MarryPage',
        component: MarryPage,
    },
    {
        path: '/signup/basics/havechild',
        name: 'HaveChildPage',
        component: HaveChildPage,
    },
    {
        path: '/signup/basics/income',
        name: 'IncomePage',
        component: IncomePage,
    },
    {
        path: '/signup/basics/asset',
        name: 'AssetPage',
        component: AssetPage,
    },
    {
        path: '/signup/basics/introducemyself',
        name: 'IntroduceMyselfPage',
        component: IntroduceMyselfPage,
    },
    {
        path: '/signup/locations/state',
        name: 'StatePage',
        component: StatePage,
    },
    {
        path: '/signup/locations/station',
        name: 'StationPage',
        component: StationPage,
    },
    {
        path: '/signup/locations/region',
        name: 'RegionPage',
        component: RegionPage,
    },
    {
        path: '/signup/schools/school',
        name: 'SchoolPage',
        component: SchoolPage,
    },
    {
        path: '/signup/schools/school-type',
        name: 'SchoolTypePage',
        component: SchoolTypePage,
    },
    {
        path: '/signup/schools/university',
        name: 'UniversityPage',
        component: UniversityPage,
    },
    {
        path: '/signup/jobs/job-category',
        name: 'JobCategoryPage',
        component: JobCategoryPage,
    },
    {
        path: '/signup/jobs/company',
        name: 'CompanyPage',
        component: CompanyPage,
    },
    {
        path: '/signup/jobs/job',
        name: 'JobPage',
        component: JobPage,
    },
    {
        path: '/signup/additional-profiles/gender',
        name: 'GenderPage',
        component: GenderPage,
    },
    {
        path: '/signup/additional-profiles/height',
        name: 'HeightPage',
        component: HeightPage,
    },
    {
        path: '/signup/additional-profiles/kinds',
        name: 'KindsPage',
        component: KindsPage,
    },
    {
        path: '/signup/additional-profiles/religion',
        name: 'ReligionPage',
        component: ReligionPage,
    },
    {
        path: '/signup/additional-profiles/shapes',
        name: 'ShapesPage',
        component: ShapesPage,
    },
    {
        path: '/signup/additional-profiles/signup-check',
        name: 'SignupCheckPage',
        component: SignupCheckPage,
    },
    {
        path: '/signup/additional-profiles/smoking',
        name: 'SmokingPage',
        component: SmokingPage,
    },
    {
        path: '/signup/start-with-phone',
        name: 'StartWithPhonePage',
        component: StartWithPhonePage,
    },
    {
        path: '/signup/upload-photo',
        name: 'UploadPhotoPage',
        component: UploadPhotoPage,
    },
    {
        path: '/signup/verification',
        name: 'VerificationPage',
        component: VerificationPage,
    },
    {
        path: '/signup/additional-profiles/hometown',
        name: 'HometownPage',
        component: HometownPage,
    },
    {
        path: '/signup/additional-profiles/car-type',
        name: 'CarTypePage',
        component: CarTypePage,
    },
    {
        path: '/signup/additional-profiles/house-style',
        name: 'HouseStylePage',
        component: HouseStylePage,
    },
    {
        path: '/signup/additional-profiles/house-payment',
        name: 'HousePaymentPage',
        component: HousePaymentPage,
    },
    {
        path: '/signup/additional-profiles/exercise',
        name: 'ExercisePage',
        component: ExercisePage,
    },
    {
        path: '/signup/additional-profiles/pet',
        name: 'PetPage',
        component: PetPage,
    },
    {
        path: '/signup/additional-profiles/tag',
        name: 'TagPage',
        component: TagPage,
    },
]
