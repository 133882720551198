<template>
    <div class="manager-introduction">
        <div class="heart-img">
            <img :src="require('@/assets/images/manager/manager-heart.png')" alt="" />
        </div>
        <div class="title">
            <img :src="require('@/assets/images/manager/manager-title.png')" alt="" />
        </div>
        <div class="contents">
            <p v-for="(content, idx) in contents" v-html="content" :key="idx" />
        </div>
        <BottomButton btnType="grad" :label="$translate('NEXT')" @click="onClickButton" />
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'ManagerIntroductionPage',
    props: {
        agent: {
            type: Object,
            default: null,
        },
        beforeBackgroundColor: {
            type: String,
            default: '#FFFFFF',
        },
    },
    computed: {
        contents() {
            return [
                `매니저 매칭은 외모, 경제력 등`,
                `선호하는 사항을 반영하여`,
                `철저하게 검증된 회원을 매칭해주는`,
                `<span class='f-bold c-black'>전문 매니저 매칭 서비스</span>입니다.`,
            ]
        },
    },
    methods: {
        async onClickButton() {
            await userService.clickSignUp({
                user_id: this.$store.getters.me.id,
            })
            this.$router.push({ name: 'Manager1RegisterPage' })
        },
    },
    mounted() {
        this.init()
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: this.beforeBackgroundColor,
        })
    },
}
</script>

<style lang="scss" scoped>
.manager-introduction {
    .heart-img {
        @include center;
        margin-top: 56px;
        height: 100px;
        img {
            width: 64px;
            height: 64px;
        }
    }
    .title {
        @include center;
        margin-top: 20px;
        img {
            width: 188px;
            height: auto;
        }
    }
    .contents {
        font-size: 17px;
        color: $grey-09;
        margin-top: 50px;
        text-align: center;
        line-height: 28px;
    }
}
</style>
