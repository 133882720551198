import * as $http from 'axios'

export default {
    all: () => $http.get('v2/chats'),
    detail: chatId => $http.get(`v2/chats/${chatId}`),
    messages: (chatId, firstId) => $http.get(`v2/chats/${chatId}/messages?first_id=${firstId}`),
    // payload: { user_id: 대화 상대 (chatUser), source_id: 소개할 사람 (introducingUser) }
    sendPhotoMessage: (chatId, data) => $http.post(`v2/chats/${chatId}/send_photo`, data),
    requestProfile: (chatId, userId) => $http.post(`v2/chats/${chatId}/request_profile`, { source_id: userId }),
    acceptProfile: (chatId, messageId, acceptStatus) =>
        $http.put(`v2/chats/${chatId}/messages/${messageId}/accept_status`, {
            accept_status: acceptStatus,
        }),
    openProfile: (chatId, messageId) => $http.put(`v2/chats/${chatId}/messages/${messageId}/open_profile`),
    leaveChat: payload => $http.put(`v2/chats/leave_chat`, payload),
    closeChat: payload => $http.put(`v2/chats/close_chat`, payload),
    openChat: chatId => $http.put(`v2/chats/${chatId}/open`),
    referral: (chatId, payload) => $http.post(`v2/chats/${chatId}/referral`, payload),
    firstOpenCheck: payload => $http.put(`v2/chats/update_first_checked`, payload),
}
