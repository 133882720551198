<template>
    <div class="chai-event">
        <FloatingBackButton @back="onClickBackButton" />
        <img class="description" src="@/assets/images/store/chai_event.png">
        <div class="bottom flex items-center">
            <img @click="appstore" class="store-img ios m-b-12" src="@/assets/images/store/chai_app_store.png">
            <img @click="googlePlay" class="store-img android" src="@/assets/images/store/chai_google_play.png">
        </div>
    </div>
</template>

<script>
import FloatingBackButton from '@/components/app/FloatingBackButton'

export default {
    name: 'ChaiEventPage',
    components: {
        FloatingBackButton,
    },
    methods: {
        onClickBackButton() {
            this.$router.go(-1)
        },
        appstore() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: 'https://itunes.apple.com/kr/app/id1459979272',
            })
        },
        googlePlay() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: 'https://play.google.com/store/apps/details?id=finance.chai.app',
            })
        },
    },
}
</script>
