import verificationBadgeService from '@/services/verification-badge'

const defaultState = () => ({
    verificationBadges: null,
    myVerificationBadges: null,
})

const state = defaultState()

const getters = {
    verificationBadges: state => state.verificationBadges,
    myVerificationBadges: state => state.myVerificationBadges,
}

const actions = {
    async loadVerificationBadges({ getters, commit }) {
        try {
            const vb = getters.verificationBadges || []

            if (vb.length > 0) {
                return vb
            }

            const badges = await verificationBadgeService.all()
            commit('setVerificationBadges', badges)

            return badges
        } catch (e) {
            return e
        }
    },
    async loadMyVerificationBadges({ getters, commit }) {
        try {
            const badges = await verificationBadgeService.get(getters.me.id)
            commit('setMyVerificationBadges', badges)

            return badges
        } catch (e) {
            return e
        }
    },
}

const mutations = {
    setVerificationBadges(state, badges) {
        state.verificationBadges = [...badges]
    },
    setMyVerificationBadges(state, badges) {
        state.myVerificationBadges = [...badges]
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
