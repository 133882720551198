<template>
    <div class="signup verification white">
        <SignupProgressBar :step="$case.toSnake('verification')" />
        <Loading :loading="loading" :animation="'dot-falling'" />
        <div class="content after">
            <div class="title-bold f-bold">
                마지막으로<br />
                <div class="title f-medium">본인 신원 인증이 필요합니다</div>
            </div>
            <div class="body">
                <div class="item">
                    <span class="f-regular" v-html="'서로 신뢰할 수 있는 만남을 위해<br>모든 회원은 '" />
                    <span class="f-bold verification-color" v-html="'정확한 실명, 나이, 연락처'" />
                    <span class="f-regular" v-html="'를<br>인증해야 합니다.'" />
                </div>
                <button class="btn btn-sub f-13 f-regular" @click="popup">내 명의가 아니에요 ></button>
            </div>
        </div>
        <BottomButton v-if="!loading" label="인증하고 시작하기" @click="next" />
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'VerificationPage',
    watch: {
        '$store.getters.niceData': {
            handler(newVal) {
                this.finish(newVal)
            },
            deep: true,
        },
    },
    data: () => ({
        loading: false,
        terms: null,
    }),
    mounted() {
        this.$registerBackHandler(() => {
            this.$router.push({ name: 'IntroduceMyselfPage' })
        }, 'goBack')

        const { gender } = this.$store.getters.payloads.signupData

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_VerificationPage',
                option: {
                    // provider: provider || 'email',
                    gender: gender.name === 'MALE' ? 0 : 1,
                },
            },
        })
        this.$hackleClient.onReady(() => {
            const hackleUser = this.$store.getters.hackleUser
            this.$hackleClient.track('Enter_VerificationPage', hackleUser)
        })
    },
    beforeDestroy() {
        this.$unregisterBackHandler('goBack')
    },
    methods: {
        popup() {
            this.$modal.basic({
                body: "카카오톡 플러스친구 '반쪽'으로 문의 주세요",
                buttons: [
                    {
                        label: '확인',
                        class: 'btn-default',
                    },
                ],
            })
            const { gender } = this.$store.getters.payloads.signupData

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'VerificationPage_Click_NotMyPhoneCTA',
                    option: {
                        gender: gender.name === 'MALE' ? 0 : 1,
                    },
                },
            })
        },
        next() {
            // 한 번 인증 받아서 인증정보가 스토어에 남아있으면 그냥 진행 다시시도 (사실 이런 경우 자체가 생기면 안되긴 함)
            if (this.$store.getters.niceData) return this.finish(this.$store.getters.niceData)

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'VerificationPage_Click_NextCTA',
                },
            })
            this.$nativeBridge.postMessage({ action: 'verification' })
        },
        finish(niceData) {
            // const signupData = this.$store.getters.payloads.signupData || {}
            const signupData = JSON.parse(window.localStorage.getItem('signupData') || '{}')
            if (this.checkNiceData(signupData, niceData)) {
                signupData.response_id = niceData.response_id
                try {
                    this.createUser(signupData)
                } catch (e) {
                    this.$toast.error('회원 가입 중 오류가 발생했어요')
                }
            }
        },
        checkNiceData(signupData, niceData) {
            const checkStatus = status => {
                let errMsg = ''
                if (status === 'too_young')
                    errMsg = '반쪽에서는 미성년자를 위한 서비스를 제공하고 있지 않습니다. 양해 부탁드립니다.'
                if (status === 'too_old')
                    errMsg =
                        '반쪽은 현재 20~99세의 회원을 대상으로 서비스를 운영중이며, 향후 서비스 범위 확장을 계획중입니다. 감사합니다.'
                if (status === 'duplicated') errMsg = '이미 가입된 핸드폰 번호입니다.'
                if (status === 'fail') errMsg = '본인 인증을 다시 시도해주세요.'

                if (errMsg) {
                    this.$toast.error(errMsg)
                    return false
                }
                return true
            }

            const checkGender = niceGender => {
                const liar =
                    (niceGender === '1' && signupData.gender.name === 'FEMALE') ||
                    (niceGender === '0' && signupData.gender.name === 'MALE')
                if (liar)
                    this.$toast.error('선택한 성별이 본인 인증을 통한 성별과 다릅니다. 처음부터 다시 진행해주세요.')
                return !liar
            }

            return checkStatus(niceData.status) && checkGender(niceData.gender)
        },
        async createUser(signupData) {
            try {
                this.loading = true

                // await this.initTerms()
                const payload = this.preparedPayload(signupData)
                await userService.complete(payload)

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Complete_Signup',
                    },
                })
                // 사진 업로드하는 시간을 고려하여 사진은 별도로 업로드
                const photoData = this.$store.getters.payloads.signupData
                if (photoData.photos.length) await userService.uploadPhotos(this.photosPayload(photoData.photos))

                this.sendEvent(signupData)
                window.localStorage.removeItem('signupData')
                this.$store.dispatch('loadMe')
                this.$store.dispatch('loadBadges')
                this.$modal
                    .basic({
                        body: '프로필 등록이 완료되었어요. 반쪽을 찾아보세요!',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(() => {
                        this.$router.push({ name: 'FeedPage' })
                    })
            } catch (e) {
                console.log(e)
                if (e.data.key === 'auth_validation_expired') {
                    this.$store.commit('setNiceData', null)
                }
                if (e.data.key === 'blocked_user') {
                    this.$modal.basic({
                        title: 'MODAL_DEFAULT_TITLE',
                        body: e.data.msg,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                } else {
                    this.$toast.error(e.data)
                }
            } finally {
                this.loading = false
            }
        },
        async initTerms() {
            await this.$store.dispatch('loadTerms')
            const { terms } = this.$store.getters.terms || {}
            this.terms = terms
        },
        preparedPayload(signupData) {
            const formData = new FormData()
            Object.entries(signupData).forEach(([key, value]) => {
                if (key === 'gender') {
                    formData.append(key, value.name === 'MALE' ? '0' : '1')
                } else if (key === 'smoking') {
                    formData.append(key, value.name === 'SMOKING' ? '1' : '0')
                } else if (['job', 'company', 'university'].indexOf(key) !== -1) {
                    formData.append(`${key}_id`, value.id)
                    formData.append(`${key}_name`, value.name)
                } else if (
                    ['state', 'station', 'region', 'job_category', 'school', 'school_type'].indexOf(key) !== -1
                ) {
                    // 커스텀 인풋 허용 안되는 값들은 id만 넘김
                    formData.append(`${key}_id`, value.id)
                } else if (key === 'profile_option_ids') {
                    formData.append(key, JSON.stringify(value))
                } else if (key === 'marry') {
                    formData.append(key, value.id)
                } else if (key === 'have_child') {
                    formData.append(key, value.id)
                } else if (key === 'income') {
                    formData.append(key, JSON.stringify(value))
                } else if (key === 'asset') {
                    formData.append(key, JSON.stringify(value))
                } else {
                    formData.append(key, value)
                }
            })
            formData.append('device_id', this.$store.getters.device.id)

            return formData
        },
        photosPayload(photos = []) {
            if (photos.length === 0) return

            const formData = new FormData()

            photos.forEach((photo, i) => {
                formData.append(`photo${i + 1}`, photo.blob, photo.name)
            })

            return formData
        },
        sendEvent({ gender }) {
            if (!gender) return
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `signup_complete_${gender.name.toLowerCase()}`,
                    option: {
                        type: 'custom',
                        label: gender.name.toLowerCase(),
                    },
                },
            })
            this.$hackleClient.onReady(() => {
                const hackleUser = this.$store.getters.hackleUser
                this.$hackleClient.track('Complete_Signup', hackleUser)
            })
        },
    },
}
</script>
<style scoped lang="scss">
.content {
    height: calc(100% - 100px);
}
.body {
    height: calc(100% - 125px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.btn-sub {
    color: $grey-08;
}
</style>
