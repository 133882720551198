<template>
    <div class="profile-photo-accept-container">
        <div class="profile-photo-accept" v-html="cta" @click.stop="$emit('click')" />
    </div>
</template>
<script>
export default {
    name: 'ProfilePhotoAccept',
    // props: [],
    computed: {
        cta() {
            // if (this.opened) return '채팅방 이동'
            return '소개받을게요'
        },
    },
}
</script>

<style scoped lang="scss">
.profile-photo-accept-container {
    width: 100%;
    height: 48px;
    margin-left: 8px;

    .profile-photo-accept {
        padding: 10px 0;
        height: 100%;
        color: white;
        background: #8a74ff;
        border-radius: 8px;
        @include center;
        @include f-medium;

        &.clicked {
            background: $grey-02;
            color: $grey-05;
            @include f-regular;
        }
    }
}
</style>
