<template>
    <div class="post">
        <div class="title-view" :class="[isPhoto ? 'm-b-9' : 'm-b-16']">
            <div class="title-profile">
                <div class="age-gender m-r-8" :class="[post.gender === 0 ? 'male' : 'female']">
                    <div class="icon-wrapper">
                        <img :src="require('@/assets/images/person.svg')" alt="" />
                    </div>
                </div>
                <div class="title lines-1" v-html="post.title" />
            </div>
            <div class="view-count">
                <img class="eye" src="@/assets/images/eye-image.png" />
                <span class="count" v-html="post.view_count" />
            </div>
        </div>

        <div class="item-container">
            <div class="content-container" :class="{ 'no-photo': !isPhoto }">
                <div class="content">
                    <p class="text" :class="{ 'no-photo': !isPhoto }" v-html="post.content" />
                </div>
            </div>
            <div v-if="isPhoto" class="img-photo">
                <img v-lazy="post.photo_url" alt="" />
            </div>
        </div>

        <div class="interest-count" v-if="type === 'myPost' && post.requests.length">
            <span class="m-r-3">{{ post.requests.length }} 명이 신청했어요!</span>
            <img v-if="showNewBadge" class="new-badge" src="@/assets/images/lounge/new-badge.png" />
        </div>
        <div class="interest-count" v-else-if="type === 'interested'">
            <span v-html="interestStatusMsg" />
            <img v-if="showNewBadge" class="new-badge" src="@/assets/images/lounge/new-badge.png" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'RealFriendMeetingPost',
    props: {
        post: Object,
        type: {
            type: String,
            required: true,
        },
        states: Array && '',
    },
    computed: {
        isPhoto() {
            return !!this.post.photo_url
        },
        showNewBadge() {
            if (this.type === 'myPost') {
                const { requests } = this.post
                const isActiveUser = request => request.enabled && !request.hidden && !request.is_dormant
                const activeReqs = requests.filter(req => isActiveUser(req))

                return activeReqs.some(p => p.accept_status === 'request')
            }

            if (this.type === 'interested') {
                const { host_checked: hostChecked, participant_checked: participantChecked } = this.post.my_request
                return hostChecked && !participantChecked
            }

            return false
        },
        interestStatusMsg() {
            const { accept_status: status } = this.post.my_request

            if (status === 'accepted') return '매칭성공'
            else return ''
        },
    },
}
</script>

<style scoped lang="scss">
.post {
    padding: 24px 16px;
    border-bottom: 1px solid $grey-02;

    .title-view {
        display: flex;
        justify-content: space-between;
    }

    .title-profile {
        display: flex;
        align-items: center;

        .title {
            color: black;
            font-size: 18px;

            @include f-medium;
        }
    }

    .age-gender {
        .icon-wrapper-default {
            width: 18px;
            height: 18px;
            border-radius: 7px;
            @include center;

            img {
                width: 9px;
                height: 9px;
            }
        }

        &.male {
            color: $blue-man;
            .icon-wrapper {
                @extend .icon-wrapper-default;
                background: $blue-man;
            }
        }
        &.female {
            color: $pink-woman;
            .icon-wrapper {
                @extend .icon-wrapper-default;
                background: $pink-woman;
            }
        }
    }

    .view-count {
        display: flex;
        align-items: center;

        .eye {
            width: 15px;
            height: 10px;
            margin-right: 4px;
        }
        .count {
            white-space: nowrap;
            font-size: 12px;
            color: $grey-05;
            @include f-regular;
        }
    }

    .item-container {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .content-container {
            max-width: 100%;
            margin-right: 16px;
            display: flex;
            flex-direction: column-reverse;
            height: 60px;

            &.no-photo {
                height: fit-content;
            }

            .text {
                line-height: normal;
                font-size: 15px;
                color: $grey-09;

                @include lines-2;

                &.no-photo {
                    width: 100%;
                }
            }
        }

        .img-photo {
            align-self: flex-end;
            img {
                width: 60px;
                height: 60px;
                border-radius: 8px;
            }
        }
    }

    .interest-count {
        margin-top: 16px;
        font-size: 13px;
        color: $purple-primary;
        @include f-bold;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;

        .new-badge {
            height: 18px;
        }
    }
}
</style>
