<template>
    <div class="section">
        <div class="title">
            <span>받은 호감</span>
            <div class="red-dot" v-if="showRedDot" />
        </div>
        <div
            class="received-like"
            :class="{ checked: !like.checked }"
            @click="onClick(like, 'ReceivedLikes')"
            v-for="like in likes"
            :key="like.id"
        >
            <div class="blur-box">
                <div :class="$blurOtherProfile(like.target) && 'blur'" />
                <img class="left" v-lazy="$myDatingUserImg(like)" alt="" />
            </div>

            <div class="right flex-between">
                <BaseProfileInfo :like="like" />
                <div class="bottom" :class="{ checked: !like.checked }" v-if="like.message">
                    <MoreRead :message="like.message" @onClick="onClick(like, 'ReceivedLikes')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MoreRead from '@/components/common/MoreRead'

export default {
    name: 'ReceivedLikes',
    props: {
        likes: Array,
        onClick: Function,
    },
    computed: {
        showRedDot() {
            return this.likes.some(l => !l.checked)
        },
    },
    data: () => ({
        shouldClamp: true,
    }),
    components: {
        MoreRead,
    },
}
</script>

<style scoped lang="scss">
.received-like {
    display: flex;
    padding: 16px;

    &.checked {
        background: rgba($purple-primary, 0.05);
    }
    &:not(:last-child) {
        padding-bottom: 16px;
        border-bottom: 1px solid $grey-01;
    }

    .left {
        max-width: 60px;
        min-width: 60px;
    }

    .right {
        align-self: center;
        width: 100%;
        .bottom {
            margin-top: 10px;
            border-radius: 0 12px 12px 12px;
            background-color: $grey-01;
            padding: 10px 12px;
            font-size: 13px;
            @include spoqa-f-regular;

            &.checked {
                background: white;
            }

            .content {
                color: black;
            }
            .more {
                margin-top: 2px;
                text-decoration: underline;
                color: $purple-primary;
            }
        }
    }

    .blur-box {
        position: relative;

        .blur {
            position: absolute;
            backdrop-filter: blur(6px);
            -webkit-backdrop-filter: blur(6px);
            width: 60px;
            height: 60px;
            border-radius: 24px;
            border: 1px solid #e9e9ed;
            margin-right: 16px;
        }
    }
}
</style>
