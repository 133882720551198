<template>
    <div class="bottom-button" :class="{ nonFixed }">
        <div class="certify" :class="{ checked }" v-if="certifyCheck">
            <span class="f-12" v-html="certifyCheck.text" />
            <i @click="checked = !checked" class="material-icons f-20">{{
                checked ? 'check_box' : 'check_box_outline_blank'
            }}</i>
        </div>
        <button
            @click.stop="onClick"
            class="btn btn-block"
            :class="buttonDisabled ? 'disabled' : btnColor"
            v-html="$translate(label || 'CONFIRM')"
        />
    </div>
</template>

<script>
export default {
    name: 'BottomButton',
    props: {
        label: String,
        disabled: Boolean,
        nonFixed: Boolean && undefined,
        certifyCheck: {
            type: Object,
            required: false,
        },
        btnType: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        checked: false,
    }),
    computed: {
        buttonDisabled() {
            if (this.certifyCheck) {
                return !this.checked || this.disabled
            } else {
                return this.disabled
            }
        },
        btnColor() {
            if (this.btnType === 'grad') return 'btn-grad'
            return 'btn-primary'
        },
    },
    methods: {
        onClick() {
            if (this.certifyCheck) {
                if (this.checked && !this.disabled) this.$emit('click')
                return
            }
            if (!this.disabled) {
                this.$emit('click')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.bottom-button {
    &.nonFixed {
        position: static !important;
        padding: 0;
        border: none !important;
    }
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 20px;
    border-top: 1px solid $grey-02;
    //display: flex;
    //align-items: flex-end;
    background: white;
    z-index: 10;

    .certify {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        &.checked {
            i {
                color: $purple-primary;
            }
        }
    }

    button {
        height: 48px;
        font-size: 15px;

        &.btn-social {
            color: #fff;
            background: $social;
        }
    }
}
</style>
