<template>
    <div class="home">
        <Loading :loading="loading" />
    </div>
</template>
<script>
// import userService from '@/services/user'

export default {
    name: 'HomePage',
    data: () => ({
        me: null,
        terms: null,
        idx: 0,
        isSignup: false,
        loading: true,
    }),
    props: ['signup'],
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            this.isSignup = this.$route.params.signup || false
            try {
                await this.initMe()
                await this.checkAppVersion()
                await this.initTerms()
                const registerDevice = () => {
                    setTimeout(() => {
                        // device id 있는지 5번까지 체크
                        if (this.$store.getters.device) {
                            this.$store.dispatch('registerDeviceId')
                        } else if (this.idx < 5) {
                            this.idx += 1
                            registerDevice()
                        }
                    }, 500)
                }
                registerDevice()

                if (this.checkTerms()) {
                    this.$router.push({
                        name: 'NewTermsPage',
                        params: {
                            updatedServiceTermsVersion: this.terms.service.version,
                            updatedPrivacyTermsVersion: this.terms.privacy.version,
                            updatedPrivacyThirdTermsVersion: this.terms.privacy_third.version,
                            serviceTermsId: this.terms.service.id,
                            privacyTermsId: this.terms.privacy.id,
                            privacyThirdTermsId: this.terms.privacy_third.id,
                        },
                    })
                } else {
                    this.$router.push({ name: 'FeedPage', params: { isDirect: true } })
                }
            } catch (e) {}
        },
        async initMe() {
            try {
                await this.$store.dispatch('loadMe')
                const me = this.$store.getters.me || {}
                this.me = me
            } catch (e) {
                return Promise.resolve()
            }
        },
        async initTerms() {
            await this.$store.dispatch('loadTerms')

            const { terms } = this.$store.getters.terms || {}
            this.terms = terms
        },
        checkTerms() {
            return (
                this.me.service_terms_version < this.terms.service.version ||
                this.me.privacy_terms_version < this.terms.privacy.version ||
                this.me.privacy_third_terms_version < this.terms.privacy_third.version
            )
        },
        checkProfileUpdateRequest() {
            const { profile_update_request: pur } = this.me

            if (pur && ['created', 'confirmed'].indexOf(this.me.profile.status) === -1) {
                this.$router.push({
                    name: 'ProfileUpdateRequestPage',
                    params: { pur },
                })

                throw new Error()
            }
        },
        async checkAppVersion() {
            if (!this.$store.getters.device) {
                // 브라우저인 경우
                return Promise.resolve()
            }
        },
    },
}
</script>
