<template>
    <div class="section">
        <div class="title">보낸 높은 평가</div>
        <div class="send-rating" @click="onClick(rating, 'SendRatings')" v-for="rating in ratings" :key="rating.id">
            <div class="blur-box">
                <div :class="$blurOtherProfile(rating.target) && 'blur'" />
                <img class="left" v-lazy="$myDatingUserImg(rating)" alt="" />
            </div>
            <div class="right flex-between">
                <BaseProfileInfo :like="rating" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SendRatings',
    props: {
        ratings: Array,
        onClick: Function,
    },
}
</script>

<style scoped lang="scss">
.send-rating {
    display: flex;
    margin: 16px;
    &:not(:last-child) {
        padding-bottom: 16px;
        border-bottom: 1px solid $grey-01;
    }

    .left {
        max-width: 60px;
        min-width: 60px;
    }

    .right {
        align-self: center;
        width: 100%;

        .bottom {
            margin-top: 10px;
            border-radius: 0 12px 12px 12px;
            background-color: $grey-01;
            padding: 10px 12px;
            font-size: 13px;
            @include spoqa-f-regular;

            .content {
                color: black;
            }
            .more {
                margin-top: 2px;
                text-decoration: underline;
                color: $purple-primary;
            }
        }
    }

    .blur-box {
        position: relative;

        .blur {
            position: absolute;
            backdrop-filter: blur(6px);
            -webkit-backdrop-filter: blur(6px);
            width: 60px;
            height: 60px;
            border-radius: 24px;
            border: 1px solid #e9e9ed;
            margin-right: 16px;
        }
    }
}
</style>
