<template>
    <div class="header-bar flex-row">
        <div @click="onClickLeftButton" class="left flex-row flex-wrap center">
            <i class="back-button material-icons flex-wrap">
                {{ backIcon }}
            </i>
        </div>
        <div class="title f-medium center" v-html="$translate(hideTitle ? '' : title)" />
    </div>
</template>

<script>
export default {
    name: 'HeaderBar',
    data: () => ({
        title: 'VANILLABRIDGE',
        customTitle: null,
    }),
    watch: {
        $route() {
            this.initTitle()
        },
    },
    computed: {
        backIcon() {
            if (['NPSMainPage', 'NTSMainPage', 'Refund1Page'].indexOf(this.$route.name) > -1) return 'close'

            return 'chevron_left'
        },
        hideTitle() {
            if (!this.$store.getters.pageStack.length) return true

            return this.$store.getters.pageStack[0].name !== 'ChatroomPage'
        },
    },
    methods: {
        initTitle() {
            this.title = this.customTitle || this.$case.toSnake(this.$route.name).toUpperCase()
            this.customTitle = ''
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: '#FFFFFF',
            })
        },
        onClickLeftButton() {
            // 여기에 직접 로직이 들어가는게 좀 그렇긴 하지만 다른 방법이...?
            if (this.$route.name === 'PreferencesIntroPage') {
                this.$router.push({ name: 'HomePage' })
                return
            }

            this.$router.go(-1)
        },
    },
    mounted() {
        this.initTitle()
        this.$hackleClient.onReady(() => {
            const hackleUser = this.$store.getters.hackleUser
            this.testGroup = this.$hackleClient.variation(5, hackleUser)
        })
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFFFFF',
        })
    },
}
</script>

<style lang="scss" scoped>
.header-bar {
    height: $header-height;
    padding-left: $header-left-padding;

    &.bordered {
        border-bottom: 1px solid $grey-02;
    }

    &.dark {
        &.bordered {
            border-bottom: 1px solid $grey-07;
        }
        background-color: $grey-09;
    }

    .back-button {
        color: $grey-08;
    }

    .left,
    .right {
        width: fit-content;
        transition: none;
    }

    .title {
        color: black;
        font-size: 16px;

        &.white {
            color: white;
        }

        ::v-deep .sub {
            border-radius: 6px;
            border: solid 1px $brand-recommended;
            font-size: 11px;
            padding: 2px 6px;
            display: flex;
            align-items: center;
        }

        ::v-deep .material-icons {
            margin-left: 4px;
            font-size: 14px;
            color: $blue-suggest;
        }
    }

    .left {
        justify-content: flex-start;
    }

    .right {
        display: flex;
        align-items: center;
    }

    i {
        font-size: 28px;
        &.white {
            color: white;
        }
    }
}
</style>
