<template>
    <div class="find-email">
        <div class="page-header">
            <div class="title" v-html="$translate('FIND_EMAIL')" />
            <div class="desc" :class="afterTryingConfirm ? 'm-t-30' : 'm-t-10'" v-html="resultDesc" />
        </div>
        <div class="body" v-if="!afterTryingConfirm">
            <div class="require-verification-code flex-row">
                <input
                    class="flex-fill"
                    placeholder="휴대폰 번호 입력 (- 없이)"
                    maxlength="11"
                    :disabled="sent"
                    v-model="phoneNumber"
                    @focus="onFocus"
                    @blur="onBlur"
                />
                <button
                    @click="sendVerificationCode"
                    class="btn btn-primary flex-wrap m-l-8"
                    :disabled="!phoneNumber"
                    v-html="sent ? '재전송' : '전송'"
                />
            </div>
            <div v-if="sent" class="sent">
                <div class="flex-row">
                    <input
                        v-model="code"
                        placeholder="인증번호 4자리 입력"
                        maxlength="4"
                        class="flex-fill"
                        @focus="onFocus"
                        @blur="onBlur"
                    />
                </div>
                <div class="left-seconds">
                    인증번호가 전송되었습니다.
                    <span v-html="`${humanizeLeftSeconds}`" />
                </div>
            </div>
        </div>
        <div v-if="showButton" class="bottom">
            <p class="error m-b-12">오류 발생 시, 카카오톡 플러스친구 <span>"반쪽"</span>으로 문의 바랍니다.</p>
            <div v-if="afterTryingConfirm">
                <button
                    @click="$router.push({ name: 'LoginPage' })"
                    class="btn btn-primary btn-block"
                    v-html="$translate('LOGIN')"
                />
                <button
                    @click="$router.push({ name: 'FindPasswordPage' })"
                    class="btn btn-primary btn-block m-t-12"
                    v-html="$translate('FIND_PASSWORD')"
                />
            </div>
            <button
                v-else
                @click="confirm"
                class="btn btn-primary btn-block"
                :disabled="!sent || !code"
                v-html="$translate('VERIFY')"
            />
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'FindEmailPage',
    data: () => ({
        sent: false,
        code: null,
        phoneNumber: null,
        email: null,
        interv: null,
        leftSeconds: null,
        showButton: true,
    }),
    computed: {
        afterTryingConfirm() {
            return this.email
        },
        resultDesc() {
            if (this.email) return this.$translate('FOUND_EMAIL').replace(/%s/, this.email)

            return this.$translate('FIND_EMAIL_DESC')
        },
        humanizeLeftSeconds() {
            const minutes = '0' + Math.floor(this.leftSeconds / 60).toString()
            let seconds = Math.floor(this.leftSeconds % 60).toString()
            seconds = seconds.length === 2 ? seconds : `0${seconds}`

            return `${minutes}분  ${seconds}초`
        },
    },
    methods: {
        async confirm() {
            try {
                const data = await userService.verificationCode.confirm({
                    code: this.code,
                    phone_number: this.phoneNumber,
                })
                if (data.msg === 'OK') this.email = data.email
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        async sendVerificationCode() {
            if ((this.phoneNumber || '').length < 11 || !(this.phoneNumber || '').startsWith('0')) {
                this.$alert('휴대폰 번호 형식이 올바르지 않습니다. 01012341234 형식으로 입력해주세요.')
                return
            }

            try {
                const data = await userService.verificationCode.create({
                    phone_number: this.phoneNumber,
                })
                this.$toast.success(data)
                this.sent = true
                this.leftSeconds = 180

                if (this.interv) {
                    clearInterval(this.interv)
                    this.interv = null
                }

                this.interv = setInterval(() => {
                    if (this.leftSeconds <= 0) {
                        clearInterval(this.interv)
                        this.leftSeconds = null
                        this.interv = null
                        this.sent = false
                        this.code = null
                        return
                    }
                    this.leftSeconds--
                }, 1000)
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        onFocus() {
            this.showButton = false
        },
        onBlur() {
            setTimeout(() => {
                this.showButton = true
            }, 100)
        },
    },
}
</script>
