// import userService from '@/services/user'

const defaultState = () => ({
    myReceivedLikes: [],
    myReceivedRatings: [],
    mySendLikes: [],
    mySendRatings: [],
})

const state = defaultState()

const getters = {
    myReceivedLikes: state => state.myReceivedLikes.filter(l => l.like_type !== 'manager'),
    myReceivedRatings: state => state.myReceivedRatings,
    mySendLikes: state => state.mySendLikes.filter(l => l.like_type !== 'manager'),
    mySendRatings: state => state.mySendRatings,
}

const actions = {
    updateReceivedLike({ getters }, like) {
        const myReceivedLikes = getters.myReceivedLikes
        myReceivedLikes.forEach(l => {
            if (l.id === like.id) {
                l.checked = true
            }
        })

        state.myReceivedLikes = myReceivedLikes
    },
    updateReceivedRating({ getters }, { rating, open }) {
        const myReceivedLikes = getters.myReceivedRatings
        myReceivedLikes.forEach(r => {
            if (r.id === rating.id) {
                if (open) r.opened = true
                else r.checked = true
            }
        })

        state.myReceivedLikes = myReceivedLikes
    },
}

const mutations = {
    setMyDatings(state, { likes, ratings }) {
        state.myReceivedLikes = likes.receive
        state.mySendLikes = likes.send
        state.myReceivedRatings = ratings.receive
        state.mySendRatings = ratings.send
    },
    setBlockUserDatings(state, userId) {
        ;['myReceivedLikes', 'myReceivedRatings', 'mySendLikes', 'mySendRatings'].forEach(s => {
            state[s] = state[s].filter(elem => {
                return elem.target_id !== userId && elem.target.id !== userId
            })
            console.log(s, state[s].length)
        })
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
