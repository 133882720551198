<template>
    <div id="app">
        <div class="flex">
            <RootHeaderBar v-if="$isRootRoute() && !noheader" />
            <HeaderBar v-else-if="!noheader" class="flex-wrap" />
            <RouterView class="route" :class="{ root: $isRootRoute(), noheader: noheader, nonav: !$isRootRoute() }" />
            <ControlPanel v-if="$isRootRoute() && chatConnectionAllowed" class="flex-wrap" />
        </div>
        <StackRouterView />
        <Toast />
        <Loading :loading="$store.getters.loading.global" />
        <Modal :key="idx" :modal="modal" v-for="(modal, idx) in $store.getters.modals" />
        <HealthCheck />
        <NativeBridge />
        <ChatPump v-if="chatConnectionAllowed" />
    </div>
</template>

<script>
import RootHeaderBar from '@/components/app/RootHeaderBar'
import HeaderBar from '@/components/control-panel/HeaderBar'
import ControlPanel from '@/components/control-panel/ControlPanel'
import ChatPump from '@/routes/chat/chatroom/components/ChatPump'
import Toast from '@/components/app/Toast'
import Modal from '@/components/app/Modal'
import NativeBridge from '@/components/app/NativeBridge'
import HealthCheck from '@/components/app/HealthCheck'
import StackRouterView from '@/components/app/StackRouterView'

export default {
    name: 'App',
    components: {
        HeaderBar,
        ControlPanel,
        ChatPump,
        Toast,
        Modal,
        NativeBridge,
        HealthCheck,
        StackRouterView,
        RootHeaderBar,
    },
    watch: {
        '$store.getters.device': {
            handler() {
                this.initHackle()
            },
        },
    },
    computed: {
        chatConnectionAllowed() {
            return (
                ['FrontPage', 'LoginPage', 'SystemMaintenanceNoticePage'].indexOf(this.$route.name) === -1 &&
                !this.$route.path.includes('/signup/')
            )
        },
        // 단순히 헤더가 정말로 필요 없거나, 커스텀 헤더를 쓰는 페이지들
        noheader() {
            return (
                [
                    'FrontPage',
                    'UserDetailPage',
                    'AgentDetailPage',
                    'ChaiEventPage',
                    'PreferencesPage',
                    'ProfileUpdateRequestPage',
                    'ForceAppUpdatePage',
                    'AgentEvaluationIntroPage',
                    'AgentEvaluationOptionsPage',
                    'SystemMaintenanceNoticePage',
                    'HomePage',
                    'PreferencesIntroPage',
                    'SuggestedDatingPage',
                    'DateCoachPage',
                    'NewTermsPage',
                    'EditProfilePage',
                    'TempAgentInfoPage',
                    'ReuploadPhotoPage',
                    'ManagerWhatIsPage',
                    'ManagerIntroductionPage',
                    'TagPage',
                ].indexOf(this.$route.name) !== -1
            )
        },
    },
    methods: {
        async init() {
            try {
                this.$store.dispatch('loadConstants')
                await this.$store.dispatch('loadAuthToken')
                await this.$store.dispatch('afterAuthCallbacks', { isSignIn: false })
            } catch (e) {
                if (e.status === 401) {
                    this.$store.dispatch('signOut')
                }
            }
        },
        initHackle() {
            this.$hackleClient.onReady(() => {
                const device = this.$store.getters.device
                const { id, ...rest } = device
                const user = {
                    id: device.id,
                    properties: { ...rest },
                }

                this.$store.commit('setHackleUser', user)
            })
        },
    },
    created() {
        this.init()
    },
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'onMountWebView',
        })
        this.$store.commit('setIsAppFirstLoaded', true)
    },
}
</script>

<style lang="scss" scoped>
#app {
    .route {
        height: calc(100vh - #{$header-height} * 2);
        overflow-y: auto;

        &.nonav.noheader {
            height: 100vh;
        }

        &.nonav:not(.noheader),
        &:not(.nonav).noheader {
            height: calc(100vh - #{$header-height});
        }

        &.root {
            //height: calc(100vh - (#{$root-header-height} + #{$control-panel-height}));
        }
    }
}
</style>
