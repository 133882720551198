<template>
    <div />
</template>

<script>
export default {
    name: 'ManagerPage',
    computed: {
        manager() {
            return (this.$store.getters.me || {}).manager || false
        },
        status() {
            if (!this.manager) return ''

            return this.manager.status || ''
        },
        signedPending() {
            return ['applicated_signup', 'rejected_signup'].includes(this.status)
        },
        signUpFin() {
            return this.status === 'accepted_signup'
        },
    },
    methods: {
        navigate() {
            if (this.signedPending) this.$router.push({ name: 'Manager2ConfirmStatusPage' })
            else if (this.signUpFin) this.$router.push({ name: 'Manager3MainPage' })
            // else if (this.manager) this.$router.push({ name: 'ManagerIntroductionPage' })
            else this.$router.push({ name: 'ManagerWhatIsPage' })
        },
    },
    created() {
        this.$store.dispatch('loadMe').then(() => this.navigate())
    },
}
</script>
