import Vue from 'vue'
import axios from '@/modules/axios'
import router from '@/router'
import userService from '@/services/user'

const transformLoginResponseToHeader = ({ user, device }) => ({
    'X-User-Email': user.email,
    'X-User-Token': user.token,
    'X-User-Role': 'user',
    'X-User-OS': device ? device.os : '',
    'X-User-Agent': device ? JSON.stringify(device) : '',
})

const setHeaderAndRedirectToMain = async ({ getters, commit, dispatch, data, noRedirect }) => {
    if (!data) {
        return Promise.reject(data)
    }

    const header = transformLoginResponseToHeader(data)
    axios.setHeader(header)
    commit('setHeader', header)

    await dispatch('afterAuthCallbacks', { isSignIn: true })

    if (!noRedirect) {
        Vue.prototype.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Login',
            },
        })
        router.push({ name: 'HomePage' })
    }
}

const defaultState = () => ({
    me: null,
    profileStatus: null,
    settings: null,
    badges: null,
    header: null,
    notices: null,
    notifications: null,
    device: null,
    items: null,
    thirdPartySignInData: null,
    signupFriends: null,
    facebookData: null,
    feeds: [],
    feedLastScrollTop: 0,
    recommendFeeds: null,
    page: null,
    newFeeds: null,
    feedSortType: null,
})

const state = defaultState()

const getters = {
    me: state => state.me,
    profileStatus: state => state.profileStatus,
    myProfileCanSee: state => ({
        photoCount: state.me.photos.filter(p => p.confirmed).length,
        keywords: state.me.keywords.length,
    }),
    hasStyle: state => {
        if (!state.me) return

        return !!state.me.style
    },
    settings: state => state.settings,
    mainPhoto: state => {
        if (!state.me || (state.me.photos || []).length === 0) return

        return state.me.photos[0].url
    },
    badges: state => state.badges,
    header: state => state.header,
    notices: state => state.notices,
    notifications: state => state.notifications,
    device: state => state.device,
    items: state => state.items,
    thirdPartySignInData: state => state.thirdPartySignInData,
    signupFriends: state => state.signupFriends,
    facebookData: state => state.facebookData,
    introduceCTATestMinId: _ => 188270,
    feeds: state => state.feeds,
    feedLastScrollTop: state => state.feedLastScrollTop,
    recommendFeeds: state => state.recommendFeeds,
    page: state => state.page,
    newFeeds: state => state.newFeeds,
    feedSortType: state => state.feedSortType,
}

const actions = {
    async loadAuthToken({ commit, dispatch, getters }) {
        const header = JSON.parse(window.localStorage.getItem('header'))

        if (!header) {
            window.localStorage.removeItem('header')
            return Promise.reject({ status: 401 })
        }

        if (header['X-User-Role'] !== 'user') return dispatch('signOut')

        if ((!header['X-User-OS'] || !header['X-User-Agent']) && getters.device) {
            header['X-User-OS'] = getters.device.os
            header['X-User-Agent'] = JSON.stringify(getters.device)
        }
        axios.setHeader(header)
        commit('setHeader', header)
    },
    async loadMe({ commit }) {
        try {
            const me = await userService.me()
            commit('setMe', me)
            commit('setProfileStatus', me)
            if (me.is_dormant) {
                router.push({ name: 'DormantPage' })
                return Promise.reject()
            }
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadSettings({ getters, commit }, isSignIn) {
        try {
            const settings = await userService.settings()
            commit('setSettings', settings)
            if (isSignIn && settings.login_reward) {
                const verified = Vue.prototype.$isVerifiedUser()
                const onlyMale = getters.me.gender === 0

                if (verified && onlyMale) {
                    Vue.prototype.$toast.success(
                        `오늘도 찾아주셔서 감사해요 🙂 하트 ${settings.login_reward}개를 드릴게요!`,
                        '',
                        3500
                    )
                }
            }
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadBadges({ state, commit }) {
        if (!state.me) return

        const data = await userService.badges(state.me.id)
        commit('setBadges', data)
    },
    async signIn({ commit, getters, dispatch }, payload) {
        const data = await Vue.prototype.$http.post('v2/users/login', payload)

        if (data.role === 'agent') {
            Vue.prototype.$toast.error(data.msg)
        } else {
            data.device = getters.device

            setHeaderAndRedirectToMain({ getters, commit, dispatch, data, noRedirect: payload.noRedirect })
        }
    },
    async signInThirdParty({ commit, getters, dispatch }, payload) {
        const data = { user: payload.user }
        data.device = getters.device
        setHeaderAndRedirectToMain({ getters, commit, dispatch, data, noRedirect: false })
    },
    async signInPhoneNumber({ commit, getters, dispatch }, payload) {
        const data = { user: payload }
        data.device = getters.device

        setHeaderAndRedirectToMain({ getters, commit, dispatch, data, noRedirect: false })
    },
    async signOut({ commit, getters }) {
        // 디바이스 정보를 잠시 기억해놓고 스토어 지워진 다음 다시 set device 해줘야 다시 로그인할때 디바이스 정보 남아있음
        const device = getters.device
        const email = window.localStorage.getItem('showEmailLogin')

        window.localStorage.clear()
        window.localStorage.setItem('showEmailLogin', email)

        commit('initAppData')
        commit('setDevice', device)
        if (router.currentRoute.name !== 'FrontPage') {
            router.push({ name: 'FrontPage' })
        }

        if (getters.deviceToken) {
            userService.deleteToken(getters.deviceToken)
        }
    },
    async loadNotices({ commit }) {
        try {
            const data = await userService.notices()
            commit('setNotices', data)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadNotifications({ state, commit }, offset) {
        try {
            const data = await userService.chunkedNotifications(
                offset === 0 ? offset : (state.notifications || []).length
            )
            commit('setNotifications', {
                offset: offset,
                items: data.notifications,
            })
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadItems({ commit }) {
        try {
            const { bag } = await userService.items()
            commit('setItems', bag.item)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadFeeds({ commit }) {
        try {
            const res = await userService.all()
            commit('setFeeds', res.users)
            commit('setPage', res.page)
            commit('setNewFeeds', res.new_users)
            commit('setFeedSortType', res.feed_sort_type)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadRecommendFeeds({ state, commit }) {
        try {
            const res = await userService.showMostAcceptable(state.me.id)
            if (res.res === 'fail') {
                return 'fail'
            } else {
                commit('setRecommendFeeds', res.recommend_user)
            }
        } catch (e) {
            return Promise.reject(e)
        }
    },
}

const mutations = {
    setHeader(state, header) {
        state.header = header
    },
    setMe(state, me) {
        state.me = me
    },
    setProfileStatus(state, me) {
        const hasPhoto = (me.photos || []).length !== 0
        const hasIntro = !!me.profile.intro

        if (me.profile.status === 'created') state.profileStatus = 0

        if (!hasPhoto && !hasIntro) state.profileStatus = 1
        else if (!hasPhoto && hasIntro) state.profileStatus = 2
        else if (hasPhoto && !hasIntro) state.profileStatus = 3
        else if (hasPhoto && hasIntro) state.profileStatus = 10
        else state.profileStatus = 0
    },
    setFeeds(state, feeds) {
        if (!feeds) return

        state.feeds = [...feeds]
    },
    setFilter(state, feedFilter) {
        state.filter = feedFilter
    },
    setFeedLastScrollTop(state, value) {
        state.feedLastScrollTop = value
    },
    setRecommendFeeds(state, recommendFeeds) {
        state.recommendFeeds = [...recommendFeeds]
    },
    setPage(state, page) {
        state.page = page
    },
    setNewFeeds(state, newFeeds) {
        state.newFeeds = newFeeds
    },
    setFeedSortType(state, feedSortType) {
        state.feedSortType = feedSortType
    },
    setSettings(state, settings) {
        state.settings = settings
    },
    setBadges(state, badges) {
        state.badges = badges
    },
    setNotices(state, notices) {
        state.notices = notices
    },
    setNotifications(state, payload) {
        if (!payload) return

        if (payload.offset === 0 || !state.notifications) {
            state.notifications = payload.items
        } else {
            state.notifications = state.notifications.concat(payload.items)
        }
    },
    setDevice(state, device) {
        if (!device) return

        state.device = device
    },
    setItems(state, items) {
        state.items = items
    },
    setThirdPartySignInData(state, thirdPartySignInData) {
        state.thirdPartySignInData = thirdPartySignInData
    },
    setFacebookData(state, facebookData) {
        state.facebookData = facebookData
    },
    setSignupFriends(state, signupFriends) {
        state.signupFriends = signupFriends
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
