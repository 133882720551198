import * as $http from 'axios'

export default {
    // get
    all: (limit, lastId) => $http.get(`v2/lounges?limit=${limit || 20}${lastId ? `&last_id=${lastId}` : ''}`),
    mine: (limit, lastId) => $http.get(`v2/lounges/mine?limit=${limit || 20}${lastId ? `&last_id=${lastId}` : ''}`),
    interest: (limit, lastId) =>
        $http.get(`v2/lounges/interest?limit=${limit || 20}${lastId ? `&last_id=${lastId}` : ''}`),

    // post
    create: payload => $http.post('v2/lounges', payload), // payload = { user_id, gender, age, state_id, title, text, photo_url },

    // test
    request: (introductId, payload) => $http.post(`v2/lounges/${introductId}/lounge_requests`, payload),

    // put
    check: (loungeId, loungeRequestId) => $http.put(`v2/lounges/${loungeId}/lounge_requests/${loungeRequestId}/check`),
    hostCheck: loungeId => $http.put(`v2/lounges/${loungeId}/lounge_requests/host_checked`),
    participantCheck: (loungeId, loungeRequestId) =>
        $http.put(`v2/lounges/${loungeId}/lounge_requests/${loungeRequestId}/participant_checked`),
    accept: (loungeId, loungeRequestId) =>
        $http.put(`v2/lounges/${loungeId}/lounge_requests/${loungeRequestId}/accept`),
    confirmMeeting: (loungeId, requestId, payload) =>
        $http.post(`v2/lounges/${loungeId}/lounge_requests/${requestId}/confirm_meeting`, payload), // status = 'meeting' | 'refund'
    confirmRefund: (loungeId, requestId, payload) =>
        $http.put(`v2/lounges/${loungeId}/lounge_requests/${requestId}/confirm_refund`, payload), // { refund_status: 1 | 2, refuse_description }
    updateViewCount: loungeId => $http.put(`v2/lounges/update_view_count`, { lounge_id: loungeId }),
}
