<template>
    <div class="feeds">
        <div class="top-btn" @click.stop="onClickTopBtn" v-if="$store.getters.feedLastScrollTop">
            <i class="material-icons">expand_less</i>
        </div>
        <div v-if="!$isVerifiedUser()" class="banner-box">
            <img
                ref="banner"
                class="banner"
                @click="clickBanner()"
                :src="require('@/assets/images/banner-edit-profile2.png')"
            />
        </div>
        <FeedTopButtons :filterOptions="filterOptions" :stateId="stateId" />
        <div class="feed-box" @scroll="onScroll" v-if="feeds.length">
            <lazy-component :is="`FeedItem`" v-for="feed in feeds" :feed="feed" :key="feed.id" />
        </div>
        <div class="feed-box" v-else>
            <BlankPage :image="blankImage" :title="$translate('FEED_EMPTY')" />
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'
import FeedTopButtons from '@/routes/feed/components/FeedTopButtons'
import FeedItem from '@/routes/feed/components/FeedItem'

export default {
    name: 'FeedPage',
    data: () => ({
        loading: false,
        lastId: null,
        scrollLimit: 20,
        timer: null,
    }),
    methods: {
        async init() {
            try {
                if (this.$route.params.isDirect && !this.profileConfirmed) {
                    this.$goProfileRegister()
                    await this.$store.dispatch('loadFeeds')
                } else {
                    this.$loading(true)
                    if (!this.feeds.length) {
                        await this.$store.dispatch('loadFeeds')
                    }
                    const dom = document.querySelector('.feed-box')
                    dom.scrollTo({ top: this.$store.getters.feedLastScrollTop })
                }
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }

            const readModalCompleteProfile = window.localStorage.getItem('readModalCompleteProfile') || false
            if (!readModalCompleteProfile && this.openCompleteProfile) {
                window.localStorage.setItem('readModalCompleteProfile', true)
                this.$modal.custom({ component: 'ModalCompleteProfile' }).then(button => {
                    switch (button) {
                        case 'goToEditProfile':
                            this.$goSubProfileRegister()
                            break
                    }
                })
            }
        },
        onClickTopBtn() {
            const dom = document.querySelector('.feed-box')
            dom.scrollTo({ top: 0, behavior: 'smooth' })
        },
        throttle(handler, delay) {
            if (!this.timer) {
                this.timer = setTimeout(() => {
                    this.timer = null
                }, delay)

                handler()
            }
        },
        onError(feed) {
            const left = event.target.parentNode
            const blur = left.querySelector('.blur')
            event.target.src = require(`@/assets/images/no_photo_${feed.gender}.jpg`)
            if (blur) {
                blur.classList.remove('blur')
            }
        },
        onScroll({ target }) {
            this.scrollLimit = this.feeds.length
            if (this.feeds.length < this.scrollLimit || this.loading) return
            const { scrollHeight, clientHeight, scrollTop } = target
            this.$store.commit('setFeedLastScrollTop', scrollTop <= 0 ? 0 : scrollTop)

            if (scrollTop + clientHeight > scrollHeight - 300) {
                this.throttle(async () => {
                    this.loading = true
                    await this.loadMore()
                    this.loading = false
                }, 400)
            }
        },
        async loadMore() {
            try {
                this.$loading(true)
                this.lastId = this.feeds[this.feeds.length - 1].id
                this.feeds.forEach(f => {
                    if (f.id < this.lastId) {
                        this.lastId = f.id
                    }
                })
                const res = await userService.all(20, this.lastId, this.page + 1, {
                    state_ids: this.stateId,
                    max_age: this.filterOptions.age[1],
                    min_age: this.filterOptions.age[0],
                    marry_type: this.marryType,
                    feed_sort_type: this.feedSortType,
                })
                this.$store.commit('setPage', res.page)
                if ((res.users || []).length === 0) {
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'FeedPage_See_Bottom',
                            option: {
                                user_id: this.$store.getters.me.id,
                            },
                        },
                    })
                }

                if ((res.users || []).length > 0) {
                    this.$store.commit('setFeeds', [...this.feeds, ...res.users])
                }
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        clickBanner() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FeedPage_Click_Topbanner',
                    option: {
                        user_id: this.$store.getters.me.id,
                    },
                },
            })
            this.$goProfileRegister()
        },
    },
    components: {
        FeedTopButtons,
        FeedItem,
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        feeds() {
            return this.$store.getters.feeds
        },
        page() {
            return this.$store.getters.page
        },
        feedSortType() {
            return this.$store.getters.feedSortType
        },
        profileConfirmed() {
            return (this.me.profile || {}).status !== 'created'
        },
        filterOptions() {
            return this.$store.getters.filter || {}
        },
        stateId() {
            if (!this.filterOptions.distance) return []

            let arr = []
            this.filterOptions.distance.forEach(filter => {
                if (filter.clicked) {
                    arr = arr.concat(filter.id)
                }
            })

            return arr
        },
        marryType() {
            const marryClicked = this.filterOptions.marriage.filter(m => m.clicked)

            return [0, 2].includes(marryClicked.length) ? null : marryClicked[0].id === 1 ? 0 : 1
        },
        blankImage() {
            return {
                type: 'material-icon',
                url: 'person_off',
            }
        },
        openCompleteProfile() {
            return (
                this.me.profile.hometown_id === null &&
                this.me.profile.car_type === '' &&
                this.me.profile.house_style === '' &&
                this.me.profile.house_payment === '' &&
                this.me.profile.exercise === '' &&
                this.me.profile.pet === '' &&
                this.me.keywords.length === 0
            )
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style scoped lang="scss">
.feeds {
    //padding: 16px;
    overflow-y: hidden !important;
    position: relative;

    .top-btn {
        position: absolute;
        right: 16px;
        bottom: 16px;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.1);
        z-index: 3;
        background: white;
        @include center;

        i {
            color: $grey-06;
            font-weight: normal;
        }
    }

    .banner-box {
        z-index: 2;
        background-color: white;
        width: calc(100vw - 32px);
        margin: 16px auto 12px auto;

        .banner {
            width: 100%;
            height: auto;
        }
    }

    .feed-box {
        overflow-y: auto;
        height: calc(100vh - 56px - 56px - 32px);
        padding: 12px 16px 16px 16px;

        &.verified {
            height: calc(100vh - 52px - 44px - 56px);
        }
    }
}
</style>
