<template>
    <div ref="userDetail" class="user-detail" @scroll="onScroll">
        <Loading :loading="loading" />
        <BlackScreen v-if="showBlackScreen" @onClickClose="onClickClose" />
        <template v-if="user">
            <UserDetailHeaderBar
                :from="from"
                :user="user"
                :userId="userId"
                :photos="userPhotos"
                :show="showHiddenHeader"
                :nickname="user.profile.nickname"
                :loungePayloads="loungePayloads"
                :backHandler="backHandler"
            />
            <UserDetailPhotos :photos="userPhotos" :gender="user.gender" />
            <UserDetailBase
                @onRating="onRating"
                :myProfile="myProfile"
                :user="user"
                :from="from"
                :like="like"
                :status="status"
                :starRating="starRating"
                :label="label"
            />
            <UserProfile :user="user" :from="from" />
        </template>
        <div class="from-lounge" v-if="this.loungePayloads">
            <BottomButton :label="bottomButtonLabel" @click="onClickLoungeAccept" />
        </div>
        <div class="from-profile-photo" v-else-if="from === 'ProfilePhoto'">
            <AppearanceRating
                @onRating="onRating"
                v-if="!starRating"
                :starRating="starRating"
                :userId="(user || {}).id"
                :label="label"
                :user="user"
                :from="from"
                :profilePhotoMessage="profilePhotoMessage"
            />
            <TwobuttonsVertical
                v-else
                :myProfile="myProfile"
                :like="like"
                :user="user"
                :from="from"
                :profilePhotoMessage="profilePhotoMessage"
            />
        </div>
        <div class="from-feed" v-else>
            <BottomButton v-if="isTesterChat" :label="bottomButtonLabel" @click="onClickCta" />
            <TwobuttonsVertical v-else :myProfile="myProfile" :like="like" :user="user" :from="from" />
        </div>
    </div>
</template>

<script>
import UserDetailBase from './components/UserDetailBase'
import UserDetailHeaderBar from './components/UserDetailHeaderBar'
import UserDetailPhotos from './components/UserDetailPhotos'
import UserProfile from './components/UserProfile'
import TwobuttonsVertical from '@/components/app/TwobuttonsVertical'
import BlackScreen from '@/routes/user-detail/components/BlackScreen'
import userService from '@/services/user'
import loungeService from '@/services/lounge'

export default {
    name: 'UserDetailPage',
    components: {
        UserDetailBase,
        UserDetailHeaderBar,
        UserDetailPhotos,
        UserProfile,
        TwobuttonsVertical,
        BlackScreen,
    },
    props: {
        from: String,
        loungePayloads: {
            type: Object,
            required: false,
        },
        userId: {
            type: Number,
            required: true,
        },
        photos: {
            type: Array,
            default: () => [],
        },
        status: String,
        label: String,
        profilePhotoMessage: Object,
    },
    data: () => ({
        user: null,
        showHiddenHeader: false,
        loading: false,
        userPhotos: [],
        starRating: 0,
        showBlackScreen: false,
        loungeChatId: null,
    }),
    watch: {
        userId: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.init()
            }
        },
        '$store.getters.pageStack': {
            handler(newVal) {
                if (this.needBackHandler) {
                    if (newVal[newVal.length - 1].name === 'UserDetailPage') {
                        const dom = document.querySelector('.user-detail')
                        dom.scrollTo(0, 0)
                        this.$registerBackHandler(this.backHandler)
                    }
                }
            },
        },
    },
    created() {
        this.init()
    },
    mounted() {
        if (this.needBackHandler) {
            this.$registerBackHandler(this.backHandler)
        }
    },
    beforeDestroy() {
        if (this.needBackHandler) {
            this.$unregisterBackHandler()
        }
    },
    computed: {
        dom() {
            return this.$refs.userDetail
        },
        needBackHandler() {
            return ['FeedPage', 'MyDatingPage'].includes(this.$route.name)
        },
        isTesterChat() {
            if (!this.$isTester()) return false
            else {
                const products = this.$store.getters.me.products
                const currentProduct = products[products.length - 1] || false
                if (!currentProduct) return false

                const diff = this.$moment(currentProduct.valid_until).diff(this.$moment(), 'minutes')
                return diff > 0
            }
        },
        bottomButtonLabel() {
            if (this.loungePayloads) {
                return this.loungeChatId ? '채팅방 이동' : '수락하기'
            }

            return `대화하기`
        },
        like() {
            return this.user.like
        },
        myProfile() {
            return this.user.id === this.$store.getters.me.id
        },
    },
    methods: {
        onClickClose() {
            this.showBlackScreen = !this.showBlackScreen
        },
        async onClickLoungeAccept() {
            if (!this.loungePayloads) return

            const { loungeId, loungeRequestId, card } = this.loungePayloads
            if (this.loungeChatId) {
                const unreadCount = await this.$store.dispatch('loadChat', { chatId: this.loungeChatId, force: true })
                this.$stackRouter.push({
                    name: 'ChatroomPage',
                    props: {
                        unreadCount,
                    },
                })
                return
            }
            try {
                const idx = await this.$modal.basic({
                    title: '수락하기',
                    body: '신청을 수락합니다. 수락 후 대화를 나눌 수 있습니다.',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (!idx) return

                const { msg, chat_id: chatId } = await loungeService.accept(loungeId, loungeRequestId)
                if (chatId) {
                    this.$toast.success(msg)

                    this.loungeChatId = chatId
                    this.$store.commit('updateCardStatus', { ...card, chat_id: chatId })

                    await this.$store.dispatch('loadChats', true)
                }
            } catch (e) {
                if (e.key === 'duplicated_chatroom') {
                    this.$toast.error(e.msg)
                }
            }
        },
        async onClickCta() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'ProfileDetailPage_Click_Chat',
                    option: {
                        target_user_id: this.user.id,
                        channel: this.from || 'myDating',
                    },
                },
            })
            if ((this.like || {}).chat_id) {
                const unreadCount = await this.$store.dispatch('loadChat', {
                    chatId: this.like.chat_id,
                    force: true,
                })

                this.$stackRouter.push({
                    name: 'ChatroomPage',
                    props: { unreadCount },
                })
            } else {
                const res = await userService.sendRequest({
                    target_id: this.user.id,
                    message: '',
                    like_type: 'message_ticket',
                })
                if (res) {
                    try {
                        await this.$store.dispatch('loadChats', true)
                        const unreadCount = await this.$store.dispatch('loadChat', {
                            chatId: res.like.chat_id,
                            force: true,
                        })

                        this.$stackRouter.push({
                            name: 'ChatroomPage',
                            props: { unreadCount },
                        })
                    } catch (e) {
                        console.log(e)
                    }
                    await this.init()
                }
            }
        },
        async backHandler() {
            if (this.showBlackScreen) {
                this.onClickClose()

                return false
            }
            if (!this.myProfile && !this.starRating && !this.loungePayloads) {
                await this.$modal.custom({
                    component: 'ModalStarRating',
                    options: {
                        user: this.user,
                        targetId: this.user.id,
                    },
                })

                this.$stackRouter.pop()
            } else {
                this.$stackRouter.pop()
            }

            return false
        },
        async init() {
            if (!this.userId) {
                this.$stackRouter.pop()
                return
            }
            if (this.loungePayloads) this.loungeChatId = this.loungePayloads.chatId

            this.loading = true
            try {
                this.user = await this.$store.dispatch('loadUserDetail', { userId: this.userId })
                this.starRating = this.user.rating ? this.user.rating.appearance_rate : 0

                if (this.$isTester()) {
                    this.showBlackScreen = false
                } else {
                    if (this.loungePayloads) this.showBlackScreen = false
                    else this.showBlackScreen = !this.myProfile && !this.like && !localStorage.getItem('how-to-use')
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.loading = false
            }

            if (this.photos.length === 0) {
                this.userPhotos = [...this.user.photos] || []
            } else {
                const parsedPhotos = this.photos.map(p => {
                    if (p.url.indexOf('thumb') > 0) {
                        const idx = p.url.indexOf('thumb')
                        const front = p.url.slice(0, idx)
                        const back = p.url.slice(idx + 5)

                        return {
                            url: front + 'medium' + back,
                        }
                    }
                    return { ...p }
                })

                this.userPhotos = [...parsedPhotos]
            }

            this.$scroll.up(this.dom, true)

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Enter_ProfilePage',
                    option: {
                        user_id: this.$store.getters.me.id,
                        target_user_id: this.user.id,
                        channel: this.from,
                        like: (this.like || {}).accepted,
                        rating: this.starRating,
                        profile_status: this.$store.getters.profileStatus,
                        target_profile_status: this.$targetUserProfileStatus(this.user),
                    },
                },
            })
        },
        onScroll(event) {
            this.showHiddenHeader = event.target.scrollTop > window.innerWidth - 56 // 56 is @header-height
        },
        onRating(rating) {
            this.starRating = rating
        },
        asString(obj) {
            return typeof obj === 'string' ? obj : obj.name
        },
    },
}
</script>

<style lang="scss" scoped>
.user-detail {
    overflow-y: auto;

    hr {
        border-bottom: solid 1px $grey-02;
    }

    .user-profile {
        padding-bottom: 85px;
    }

    ::v-deep .bottom-btn-container {
        position: sticky;
        bottom: 0;
        width: 100%;
        background: white;
        border-top: 1px solid $grey-02;
        padding: 16px;
        z-index: 10;

        .desc {
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            margin-bottom: 12px;
        }

        button {
            height: 48px;
            font-size: 15px;
            opacity: 1;

            &.btn-reject {
                width: 48px;
                background: $grey-05;

                i {
                    color: white;
                }
            }
        }

        &.message-btn {
            .btn-brd {
                flex: 1;
                margin-right: 8px;

                &.btn-pass {
                    max-width: 100px;
                }
            }

            .btn-primary {
                flex: 2;
                box-shadow: none;
            }
        }
    }
}
</style>
