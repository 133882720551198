<template>
    <div class="confirm-status">
        <div class="img-wrapper">
            <img :src="confirmStatus.src" alt="" />
        </div>
        <div class="title f-medium text-center f-24 c-black" v-html="confirmStatus.title" />
        <div class="desc text-center f-16 spoqa-f-regular" v-html="confirmStatus.desc" />
        <div class="reject-reason m-t-16 text-center f-14" v-if="status === 'rejected_signup'">
            *일반적으로 프로필 내용과 사진이<br />
            불충분/부적절할 때 반려됩니다.
        </div>
        <div class="bottom-btn">
            <BottomButton
                btnType="grad"
                :nonFixed="true"
                @click="onClickCta"
                v-if="confirmStatus.cta"
                :label="confirmStatus.cta"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Manager2ConfirmStatusPage',
    data: () => ({}),
    methods: {
        onClickCta() {
            this.$router.push({ name: 'ManagerWhatIsPage' })
        },
    },
    computed: {
        pending() {
            return {
                src: require('@/assets/images/manager/manager-pending.png'),
                title: '승인 대기중입니다',
                desc:
                    '가입 신청이 완료되었습니다.<br>가입 승인까지 1~2 영업일이 소요되며<br>승인이 반려될 수 있습니다.',
                cta: '',
            }
        },
        reject() {
            return {
                src: require('@/assets/images/manager/manager-reject.png'),
                title: '승인 반려되었습니다',
                desc: '아쉽지만 가입 승인이 반려되었습니다<br>프로필을 수정한 후 다시 신청해주세요.',
                cta: '다시 신청하기',
            }
        },
        status() {
            return (this.$store.getters.me || {}).manager.status
        },
        confirmStatus() {
            if (this.status === 'applicated_signup') return this.pending

            return this.reject
        },
    },
}
</script>

<style scoped lang="scss">
.confirm-status {
    position: relative;

    .img-wrapper {
        @include center;
        margin: 42px 0 62px 0;

        img {
            width: 111px;
            height: 160px;
            margin: 0 auto;
        }
    }
    .desc {
        line-height: 24px;
        margin-top: 28px;
        color: $grey-09;
    }

    .manager-matching {
        display: flex;
        align-items: center;
        padding: 8px 20px 8px 16px;
        border-radius: 18px;
        border: solid 1px #e9e9ed;
        background-color: #f7f8f9;
        max-width: fit-content;
        font-size: 14px;
        margin: 40px auto 0;
    }
    .bottom-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px;
    }
}
</style>
