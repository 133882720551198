<template>
    <div class="user-detail-base">
        <div class="body">
            <div class="section p-t-20" :class="[myProfile ? 'need-divider' : '']">
                <div
                    v-if="status"
                    class="status m-b-4"
                    v-html="$translate(`SUGGESTED_STATUS_${status.toUpperCase()}`)"
                />
                <div class="name">
                    <div class="left">
                        <span class="m-r-12" v-html="nameOrNick || ''" />
                        <MarryState
                            :size="'small'"
                            :marry="user.profile.marry"
                            :from="'UserDetailBase'"
                            :haveChild="haveChild"
                        />
                    </div>
                </div>
                <div class="basic-info">
                    <div class="flex-row">
                        <div v-html="`${$options.filters.asAge(user.profile.birthday)}세`" />
                        <div class="divider" v-html="'ㅣ'" />
                        <div v-html="`${user.profile.height}cm`" />
                    </div>
                    <div class="flex-row m-t-8">
                        <div v-html="region" />
                        <Distance
                            v-if="showDistanceInfo"
                            :distance="$distance($store.getters.me.location, user.location)"
                        />
                    </div>
                    <div v-if="showMessage" class="like-message m-b-16 m-t-16">
                        <img v-lazy="like.target.photo_url" alt="" />
                        <div class="text">
                            <MoreRead :message="like.message" />
                        </div>
                    </div>
                    <div class="badges">
                        <div
                            class="verification-badge"
                            v-for="badge in (user.verification_badges || [])
                                .filter(b => b.confirmed)
                                .sort((a, b) => {
                                    if (a.position > b.position) return 1
                                    if (a.position < b.position) return -1
                                })"
                            :key="badge.id"
                        >
                            <i class="material-icons-outlined" v-if="[15, 18].includes(badge.id)">{{
                                badge.image_name
                            }}</i>
                            <i class="material-icons" v-else>{{ badge.image_name }}</i>
                            <div>{{ badge.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="user.id !== $store.getters.me.id && (!from || from != 'ProfilePhoto')">
            <AppearanceRating
                @onRating="onRating"
                v-if="!starRating"
                :starRating="starRating"
                :userId="user.id"
                :label="label"
                :user="user"
            />
        </template>
        <div v-if="starRating" class="after-rating">
            <div class="star-wrapper">
                <img :src="require('@/assets/images/user-detail/purple_star.png')" alt="" />
                <span class="f-bold f-15">{{ starRating }}</span>
            </div>
            <span class="rated-info f-regular m-l-12 f-14">매력 평가 완료</span>
        </div>
    </div>
</template>

<script>
import MarryState from '@/components/common/MarryState'
import MoreRead from '@/components/common/MoreRead'

export default {
    name: 'UserDetailBase',
    components: {
        // AcceptButton,
        MarryState,
        MoreRead,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        myProfile: Boolean,
        like: Object && null,
        status: String,
        starRating: Number,
        from: String,
        label: String,
    },
    data: () => ({
        shouldClamp: true,
        rating: null,
        clicked: false,
    }),
    mounted() {
        const dom = this.$refs.agentMessage
        if (dom) {
            this.shouldClamp = dom.scrollHeight - 1 > dom.clientHeight
        }
    },
    computed: {
        showMessage() {
            const isTargetUser = (this.like || {}).target_id === this.$store.getters.me.id

            return isTargetUser && this.like.message
        },
        phoneNumber() {
            return this.$store.getters.me.phone_number
        },
        nameOrNick() {
            if ((this.like || {}).accepted) {
                return `${this.like.target.nickname} ${this.like.target.name ? `(${this.like.target.name})` : ''}`
            }

            return this.user.profile.nickname
        },
        intro() {
            return this.user.profile.intro.trim() || ''
        },
        region() {
            return this.user.profile.region_name || ''
        },
        haveChild() {
            return this.user.profile.have_child || false
        },
        showDistanceInfo() {
            // 본인 프로필 열람 시 거리정보 노출하지 않기
            return this.$store.getters.me.id !== this.user.id
        },
    },
    methods: {
        onRating(rating) {
            this.$emit('onRating', rating)
        },
        onClickParticipate() {
            this.$modal.custom({
                component: 'ModalEnterCampaign',
            })
        },
        getRate(rating) {
            this.rating = rating
        },
        asString(obj) {
            return typeof obj === 'string' ? obj : obj.name
        },
        goToAgentDetail() {
            this.$stackRouter.push({
                name: 'AgentDetailPage',
                props: {
                    agentId: this.agentId,
                    from: 'UserDetailPage',
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.user-detail-base {
    hr {
        height: 1px;
        background-color: $grey-02;
        border: none;
    }

    .like-message {
        display: flex;
        img {
            @include round-image;
            width: 40px;
            height: 40px;
            border-radius: 16px;
        }
        .text {
            width: calc(100% - 40px - 12px);
            background: rgba($purple-primary, 0.08);
            padding: 14px 16px;
            border-radius: 0 16px 16px 16px;
            font-size: 14px;
            color: $grey-09;
        }
    }

    .section {
        padding: 32px 16px;
        //border-bottom: 1px solid $grey-02;
        @include flex;

        &.need-divider {
            border-bottom: 1px solid $grey-02;
            padding-bottom: 32px;
        }
    }

    .status {
        color: $purple-primary;
        font-size: 12px;
        letter-spacing: -0.2px;
        line-height: normal;
        @include f-bold;
    }

    .name {
        line-height: normal;
        font-size: 20px;
        color: black;
        @include between;
        @include f-medium;

        .left {
            display: flex;
            align-items: center;
        }
    }

    .basic-info {
        margin-top: 24px;
        z-index: 3;

        .flex-row > * {
            flex: 0 0 auto;
        }

        .distance {
            margin-left: 6px;
        }
        .badges {
            margin-top: 24px;
            display: flex;
            flex-flow: row wrap;

            .verification-badge {
                display: flex;
                align-items: center;
                padding: 4px 10px 4px 6px;
                border: 1px solid $grey-03;
                border-radius: 14px;
                width: fit-content;
                margin-right: 8px;
                margin-bottom: 8px;

                i {
                    font-size: 14px;
                    margin-right: 6px;
                    color: $grey-05;
                }
                div {
                    font-size: 13px;
                    color: $grey-09;
                }
            }
        }
    }

    .source-introduce {
        white-space: pre-line;

        .title {
            color: black;
            margin-bottom: 16px;
            font-size: 18px;
            @include f-medium;
        }

        .content {
            width: 100%;
            color: $grey-09;
            border-radius: 8px;
            line-height: 24px;
        }
    }

    .after-rating {
        display: flex;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid $grey-02;

        .star-wrapper {
            display: flex;
            align-items: center;
            background: $grey-01;
            border-radius: 12px;
            padding: 1px 12px;

            img {
                width: 14px;
                height: 13px;
            }
            span {
                font-size: 15px;
                color: $purple-primary;
            }
        }

        .rated-info {
            font-size: 14px;
            color: $grey-09;
        }
    }
}
</style>
