<template>
    <div v-if="$store.getters.me" class="control-panel">
        <div class="menu-items">
            <!--            flex-row items-center  flex-fill-->
            <MenuButton
                @click="button.action"
                :icon="button.icon"
                :badgeCount="button.badgeCount"
                :label="button.label"
                :selected="button.selected"
                :key="button.label"
                :openedNew="button.openedNew"
                v-for="button in buttons"
            />
        </div>
    </div>
</template>

<script>
import MenuButton from './MenuButton'

export default {
    components: {
        MenuButton,
    },
    computed: {
        numUnreads() {
            return (this.$store.getters.validChats || []).reduce((total, chat) => total + chat.unread || 0, 0)
        },
        myDatingBadgeCount() {
            const localStorageData = Number(window.localStorage.getItem('myDatingBadge')) || 0

            if (localStorageData) {
                this.$store.commit('setMyDatingBadge', localStorageData)
            }

            return this.$store.getters.myDatingBadge ? 1 : 0
        },
        badges() {
            return this.$store.getters.badges || {}
        },
        userAge() {
            return this.$options.filters.asAge(this.$store.getters.me.profile.birthday)
        },
        buttons() {
            const btns = [
                {
                    action: () => {
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'BottomNav_Click_FeedMenu',
                                option: {
                                    user_id: this.$store.getters.me.id,
                                },
                            },
                        })

                        this.navigate('FeedPage')
                    },
                    icon: this.icon('feed'),
                    label: '반쪽찾기',
                    selected: this.$route.name === 'FeedPage',
                },
                {
                    action: () => {
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'BottomNav_Click_LikeStatusMenu',
                                option: {
                                    user_id: this.$store.getters.me.id,
                                },
                            },
                        })

                        this.navigate('MyDatingPage')
                    },
                    icon: this.icon('dating'),
                    label: '받은호감',
                    badgeCount: this.badges.likes,
                    selected: this.$route.name === 'MyDatingPage',
                },
                {
                    action: () => {
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'BottomNav_Click_ChatMenu',
                                option: {
                                    user_id: this.$store.getters.me.id,
                                },
                            },
                        })

                        this.navigate('ChatsPage')
                    },
                    icon: this.icon('chat'),
                    label: 'CHAT',
                    badgeCount: this.numUnreads,
                    selected: this.$route.name === 'ChatsPage',
                },
                {
                    action: () => {
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'BottomNav_Click_ManagerMenu',
                                option: {
                                    user_id: this.$store.getters.me.id,
                                    gender: this.$store.getters.me.gender,
                                },
                            },
                        })

                        this.navigate('ManagerPage')
                    },
                    icon: this.icon('manager'),
                    label: 'PREMIUM',
                    // badgeCount: this.numUnreads,
                    selected: [
                        'ManagerPage',
                        'ManagerWhatIsPage',
                        'ManagerIntroductionPage',
                        'Manager1RegisterPage',
                        'Manager2ConfirmStatusPage',
                        'Manager3MainPage',
                    ].includes(this.$route.name),
                },
                {
                    action: () => {
                        this.navigate('LoungePage')
                    },
                    icon: this.icon('lounge'),
                    label: '라운지',
                    // badgeCount: this.numUnreads,
                    selected: this.$route.name === 'LoungePage',
                    openedNew: localStorage.getItem('enter-lounge-page'),
                },
            ]

            if (!this.$store.getters.me) return btns

            return btns
        },
    },
    methods: {
        navigate(name) {
            this.$router.push({ name }).catch(e => {
                if (e.name === 'NavigationDuplicated') {
                    let dom
                    if (name === 'FeedPage') {
                        dom = document.querySelector('.feed-box')
                    }
                    if (name === 'LoungePage') {
                        dom = document.querySelector('.main')
                    }
                    if (name === 'ChatsPage') {
                        dom = document.querySelector('.chat-items')
                    }

                    dom.scrollTo({ top: 0, behavior: 'smooth' })
                }
            })
        },
        icon(icon) {
            return {
                active: require(`@/assets/images/tab/icon_${icon}_active@2x.png`),
                inactive: require(`@/assets/images/tab/icon_${icon}_inactive@2x.png`),
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.control-panel {
    z-index: 1;
    position: fixed;
    width: 100%;
    bottom: 0;
    background: white;
    border-top: 1px solid $grey-02;
    height: $header-height;

    .menu-items {
        padding: 6px 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}
</style>
