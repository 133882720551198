<template>
    <ProfileOptionSelector :model="'marry'" :nextRoute="item => (item.next ? 'HaveChildPage' : nextRoute)" />
</template>

<script>
export default {
    name: 'MarryPage',
    data: () => ({
        nextRoute: 'GenderPage',
    }),
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_MarrageStatusPage',
            },
        })
    },
}
</script>

<style scoped></style>
