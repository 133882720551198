import Chat from './chat'
import Common from './common'
import User from './user'
import StackRouter from './stack-router'
import Feedback from './feedback'
import MyDatings from './my-datings'
import Product from './product'
import VerificationBadge from './verification-badge'
import lounge from './lounge'

const merged = {
    state: {},
    getters: {},
    mutations: {},
    actions: {},
}

const objToMerge = [Chat, Common, User, StackRouter, Feedback, MyDatings, Product, VerificationBadge, lounge]

objToMerge.forEach(store => {
    ;['state', 'getters', 'mutations', 'actions'].forEach(storeKey => {
        Object.keys(store[storeKey]).forEach(key => {
            merged[storeKey][key] = store[storeKey][key]
        })
    })
})

merged.mutations.initAppData = state => {
    objToMerge.forEach(store => {
        Object.assign(state, store.defaultState())
    })
}

export default merged
