import commonService from '@/services/common'
import profileService from '@/services/profile'

const defaultState = () => ({
    jobs: null,
    filter: null,
    jobCategories: null,
    provinces: null,
    religions: null,
    schools: null,
    schoolTypes: null,
    categories: null,
    states: null,
    hometowns: null,
    keywordCategories: null,
    defaultKeywords: null,
    eventRegions: null,
    profileOptions: null,
    properties: {},
    lastApiCall: null,
    terms: null,
    payloads: {
        signupData: {
            service_terms_version: '',
            privacy_terms_version: '',
            privacy_third_terms_version: '',
            optional_consent: 1,
            email: '',
            nickname: '',
            password: '',
            provider: '',
            profile_option_ids: [],
            phone_number: '',
            birthday: '',
            gender: '',
        },
        npsData: {
            score: null,
            feedback: '',
        },
        ntsData: {
            score: null,
            feedback: '',
        },
        refundReasons: [],
    },
    keyboardHeight: null,
})

const state = defaultState()

const getters = {
    jobs: state => state.jobs,
    jobCategories: state => state.jobCategories,
    categories: state => state.categories,
    filter: state => state.filter,
    states: state => state.states,
    schools: state => state.schools,
    schoolTypes: state => state.schoolTypes,
    keywordCategories: state => state.keywordCategories,
    defaultKeywords: state => state.defaultKeywords,
    eventRegions: state => state.eventRegions,
    properties: state => state.properties,
    marry: state => state.marry,
    haveChild: state => state.haveChild,
    shapes: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'shape')
    },
    kinds: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'kind')
    },
    religions: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'religion')
    },
    income: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'income')
    },
    asset: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'asset')
    },
    exercise: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'exercise')
    },
    carType: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'car_type')
    },
    pet: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'pet')
    },
    housePayment: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'house_payment')
    },
    houseStyle: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'house_style')
    },
    lastApiCall: state => state.lastApiCall,
    payloads: state => state.payloads,
    appBanners: state => state.appBanners,
    keyboardHeight: state => state.keyboardHeight,
    terms: state => state.terms,
    hometowns: state => state.states,
}

const actions = {
    async loadConstants({ commit }) {
        try {
            const loadConstants = () => commonService.constants()
            const loadProfileOptions = () => profileService.profileOptions()

            const [constants, profileOptions] = await Promise.all([loadConstants(), loadProfileOptions()])

            commit('setConstants', {
                constants,
                profileOptions,
                properties: {},
            })
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadTerms({ commit }) {
        try {
            const data = await commonService.terms()
            commit('setTerms', data)
        } catch (e) {
            return Promise.reject(e)
        }
    },
}

const mutations = {
    setConstants(state, { constants, profileOptions, properties }) {
        state.jobs = constants.jobs
        state.filter = {
            distance: constants.states,
            age: [],
            marriage: [
                { id: 1, name: '초혼', clicked: false },
                { id: 2, name: '재혼', clicked: false },
            ],
        }
        state.jobCategories = constants.job_categories
        state.schools = constants.schools
        state.schoolTypes = constants.school_types
        state.categories = constants.categories
        state.states = constants.states
        state.religions = constants.religions
        state.keywordCategories = constants.keyword_categories
        state.defaultKeywords = constants.default_keywords
        state.profileOptions = profileOptions
        state.eventRegions = constants.event_regions
        if (properties) {
            Object.keys(properties).forEach(key => {
                state.properties[key] = properties[key]
            })
        }
    },
    /*
        USAGE:
        this.$store.commit('setPayloads', {
            signupData: {
                email: 'kispi@naver.com',
            },
        }
    */
    setPayloads(state, payloads) {
        Object.keys(payloads).forEach(payload => {
            Object.keys(payloads[payload]).forEach(key => {
                state.payloads[payload][key] = payloads[payload][key]
            })
        })
    },
    setKeyboardHeight(state, keyboardHeight) {
        state.keyboardHeight = keyboardHeight
    },
    setTerms(state, terms) {
        state.terms = terms
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
