<template>
    <ProfileOptionSelector :model="'haveChild'" :nextRoute="() => nextRoute" />
</template>

<script>
export default {
    name: 'HaveChildPage',
    data: () => ({
        nextRoute: 'GenderPage',
    }),
}
</script>

<style scoped></style>
