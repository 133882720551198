import { render, staticRenderFns } from "./EventBanner.vue?vue&type=template&id=08e6b89c&scoped=true&"
import script from "./EventBanner.vue?vue&type=script&lang=js&"
export * from "./EventBanner.vue?vue&type=script&lang=js&"
import style0 from "./EventBanner.vue?vue&type=style&index=0&id=08e6b89c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e6b89c",
  null
  
)

export default component.exports