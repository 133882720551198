<template>
    <div class="appearance-rating" :class="{ 'profile-photo': from == 'ProfilePhoto' }">
        <div v-if="from == 'ProfilePhoto'" class="m-t-8 c-grey-08 f-12" v-html="profilePhotoText" />
        <div class="rating relative flex">
            <div class="rating-content">
                <StarRating :icon="'star'" @onRating="onRating" />
            </div>
        </div>
        <div v-if="!from || from != 'ProfilePhoto'" class="m-t-8 c-grey-07 f-12">매력을 평가해 주세요</div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'AppearanceRating',
    props: ['userId', 'starRating', 'label', 'user', 'from', 'profilePhotoMessage'],
    mounted() {
        this.init()
    },
    computed: {
        vanilla() {
            return (this.$store.getters.settings || {}).vanilla
        },
        profilePhotoText() {
            return `먼저 <span class='c-primary f-bold'>매력을 평가</span>하고, 수락/패스를 선택해 주세요!`
        },
    },
    data: () => ({
        checkedRating: 0,
    }),
    watch: {
        userId: {
            handler() {
                this.init()
            },
            deep: true,
        },
    },
    methods: {
        async init() {
            this.checkedRating = 0
        },
        async onRating(rating) {
            this.$loading(true)
            try {
                await userService.starRate({
                    target_id: this.userId,
                    rate: rating,
                    from: this.from,
                    message_id: (this.profilePhotoMessage || {}).id,
                })

                if (this.profilePhotoMessage) {
                    const content = this.$mustParse(this.profilePhotoMessage.content)
                    content.opened = true
                    this.profilePhotoMessage.content = JSON.stringify(content)
                }

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Rate',
                        option: {
                            target_user_id: this.userId,
                            channel: 'AppearanceRating',
                            rating: rating,
                            profile_status: this.$store.getters.profileStatus,
                            target_profile_status: this.$targetUserProfileStatus(this.user),
                        },
                    },
                })

                setTimeout(() => {
                    this.afterRating = true
                    this.checkedRating = rating
                    this.$toast.success('매력 평가 완료!')
                })
                this.$store.dispatch('loadBadges')
                this.$emit('onRating', rating)
            } catch (e) {
                this.$toast.error(e.msg)
            }
            this.$loading(false)
        },
    },
}
</script>
<style scoped lang="scss">
.appearance-rating {
    background: $grey-01;
    z-index: 10;
    border-radius: 12px;
    margin: 0 16px;
    padding: 16px 0;

    &.profile-photo {
        margin: 0 0 0 0;
        border-radius: 0;
        border-top: 1px solid $grey-02;
        background: white;
        width: 100%;
        z-index: 10;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 6px 0;
    }

    .c-grey-07,
    .c-grey-08 {
        text-align: center;
    }

    .rating {
        font-size: 15px;
        color: $grey-09;

        &-content {
            width: 100%;
            padding: 14px 0 16px;
        }

        .star-rating,
        .star-rating-wrapper {
            display: flex;
            justify-content: center;

            .star-wrapper:not(:last-child) {
                margin-right: 10px;
            }
        }

        .title {
            margin-bottom: 17px;
            color: $grey-09;
            font-size: 16px;
            line-height: normal;

            @include f-regular;
            @include center;

            em {
                font-style: normal;
                @include f-bold;
            }
        }

        .content {
            margin-top: 14px;
            white-space: nowrap;
            color: $grey-07;
            font-size: 12px;
            @include f-regular;
            @include center;
        }

        .snackbar {
            padding: 14px 16px;
            position: initial;
            max-width: initial;
            border-radius: 12px;
            background-color: $grey-08;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 0 rgba(0, 0, 0, 0.1);

            i {
                color: $grey-05;
                font-size: 16px;
                margin-top: 2px;
            }
        }

        .material-icons {
            font-size: 16px;
            color: $grey-06;
        }
    }
}
</style>
