<template>
    <ProfileOptionSelector :model="'job'" :nextRoute="() => 'IncomePage'" />
</template>

<script>
export default {
    name: 'JobPage',
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_JobPage',
            },
        })
    },
}
</script>
