<template>
    <div class="force-app-update">
        <h1 class="title" v-html="$translate('FORCE_APP_UPDATE_HEADER')" />
        <p class="description" v-html="$translate('FORCE_APP_UPDATE_DESCRIPTION')" />
        <button
            class="btn-primary btn"
            v-html="$translate('GO_TO_UPDATE')"
            @click="openStore"/>
    </div>
</template>
<script>
export default {
    name: 'ForceAppUpdatePage',
    data: () => ({
        storeUrl: null,
    }),
    mounted() {
        this.$registerBackHandler(() => {})
        this.storeUrl = this.$route.params.url
    },
    methods: {
        openStore() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: this.storeUrl,
            })
        },
    },
}
</script>
