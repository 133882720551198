import * as $http from 'axios'

export default {
    constants: () => $http.get('v2/constants'),
    terms: () => $http.get('v2/terms'),
    gitDescriptions: () => $http.get('v2/git_descriptions'),
    properties: params => $http.get('v2/properties', { params }),
    settings: () => $http.get('v2/users/me/settings'),
    refunds: () => $http.get('v2/refunds'),
    createRefund: refund => $http.post('/v2/refunds', refund),
    uploadEvidence: payload => $http.post('v2/evidences', payload),
    ratingOptions: () => $http.get('v2/rating_options'),
    rewards: lastId => $http.get(`v2/rewards?last_id=${lastId}`),
    keywords: (id, name) => $http.get(`v2/keywords?keyword_category_id=${id}&name=${name}`),
    userKeywords: {
        create: ({ keywordId, name, keywordCategoryId }) =>
            $http.post(
                `v2/user_keywords?keyword_id=${keywordId}&name=${name}&keyword_category_id=${keywordCategoryId}`
            ),
        delete: keywordIds => $http.delete(`v2/user_keywords/delete?keyword_ids=${keywordIds}`),
        updateKeywords: payload => $http.post(`v2/user_keywords/update_keywords`, payload),
        // delete: (params) => $http.delete(rubyEP + `new_admin/marketings/${params.id}`, params),
    },
}
