<template>
    <div class="chat-item" :class="isFemale || firstChecked || isDormant ? '' : 'closed'">
        <div class="flex-row">
            <ChatItemPhoto
                class="center flex-wrap m-r-12"
                :src="chatUserPhoto"
                @clickPhoto="onClickUserPhoto"
                :gender="targetUser.gender"
                :isDormant="isDormant"
            />

            <div class="content-wrapper" @click="onClickChatItem">
                <div class="info">
                    <div class="name-and-badge">
                        <div class="name f-medium">
                            {{ chatName }}
                        </div>
                        <img v-if="isLoungeChat" class="lounge-badge" src="@/assets/images/lounge/lounge_badge.png" />
                    </div>
                    <div class="last-message lines-2 m-t-8" v-html="lastMessage" />
                </div>
                <div class="right flex-wrap">
                    <div class="column">
                        <div class="timestamp flex-wrap" :class="{ 'o-0': lastMessageAt === 'NONE' }">
                            {{ lastMessageAt }}
                        </div>
                        <div v-if="showUnreadCount" class="badge badge-count flex-wrap m-t-12">
                            {{ chat.unread }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChatItemPhoto from './ChatItemPhoto'

export default {
    name: 'ChatItem',
    props: {
        chat: {
            type: Object,
            required: true,
        },
    },
    components: { ChatItemPhoto },
    computed: {
        isLoungeChat() {
            return this.chat.chat_type === 'lounge_chat'
        },
        isFemale() {
            return this.$store.getters.me.gender === 1
        },
        opened() {
            return this.chat.opened
        },
        firstChecked() {
            return this.chat.first_checked
        },
        showUnreadCount() {
            return this.chat.unread > 0
        },
        isRequestedDating() {
            return !this.chat.user
        },
        isDormant() {
            if (this.chat.blocked_id) return true

            return this.$isDormant(this.targetUser)
        },
        lastMessage() {
            const lastMessage = this.chat.last_message
            if (!this.opened && !lastMessage) return '호감을 수락했습니다. 인사를 건네보세요!'

            if (!lastMessage) return

            switch (lastMessage.mtype) {
                case 'text':
                    return lastMessage.content
                case 'alimi-basic':
                    return (
                        (this.$mustParse(lastMessage.content) || {}).title ||
                        (this.$mustParse(lastMessage.content) || {}).msg
                    )
                case 'photo':
                    return this.$translate('PHOTO')
                default:
                    return (this.$mustParse(lastMessage.content) || {}).msg
            }
        },
        lastMessageAt() {
            if (!this.opened) return this.$options.filters.formatDate(this.chat.created_at, 'chatList')
            const result =
                this.$options.filters.formatDate((this.chat.last_message || {}).created_at, 'chatList') || 'NONE'
            return result
        },
        chatUserPhoto() {
            if (this.targetUserAvailable) return this.targetUser.photo_url || ''
            else return ''
        },
        targetUser() {
            return this.chat.target_chat_user || {}
        },
        chatName() {
            if (this.targetUserAvailable) return this.$nameAndNick(this.targetUser)
            else return '휴면 회원'
        },
        targetUserAvailable() {
            if (this.chat.blocked_id) return false

            return this.targetUser.enabled && !this.targetUser.hidden && !this.targetUser.is_dormant
        },
    },
    methods: {
        async onClickChatItem() {
            try {
                this.$loading(true)
                const unreadCount = this.chat.unread
                await this.$store.dispatch('loadChat', {
                    chatId: this.chat.id,
                    force: true,
                })

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Enter_Chat',
                        option: {
                            user_id: this.$store.getters.me.id,
                            target_user_id: this.targetUser.id,
                            status: this.firstChecked ? 1 : 0,
                        },
                    },
                })

                if (this.chat.chat_type === 'manager_chat') {
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'Enter_Manager_Chat',
                        },
                    })
                }

                this.$stackRouter.push({
                    name: 'ChatroomPage',
                    props: { unreadCount },
                })
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        async onClickChatItemProfileImage(event) {
            event.stopPropagation()
            const photoUrls = await this.$store.dispatch('loadUserImages', this.chat.user.id)
            if (!photoUrls) return

            this.$modal.images({
                images: photoUrls,
            })
        },
        onClickUserPhoto() {
            if (this.targetUser.is_manager) return

            if (!this.targetUserAvailable) {
                this.$modal.basic({
                    title: '안내',
                    body: `죄송합니다. 상대가 휴면 신청을 하여 프로필을 열람할 수 없습니다.`,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                return
            }

            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    userId: this.targetUser.id,
                    from: 'ChatsPage',
                    // photos: [{ url: feed.photo_url }],
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.chat-item {
    $photo-width: 60px;

    user-select: none;
    padding: 12px 16px;

    &.closed {
        background-color: rgba(138, 116, 255, 0.05);
    }

    .content-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100vw - 32px - 70px - 50px);

        .name-and-badge {
            display: flex;
            align-items: center;
            line-height: normal;
            min-width: 0;

            .lounge-badge {
                margin-left: 8px;
                height: 20px;
                align-self: center;
            }
        }

        .name {
            color: black;
            font-size: 15px;

            @include lines-1;
        }
    }

    .age-region {
        font-size: 11px;
        margin-top: 2px;
        color: $grey-07;
    }

    .last-message {
        font-size: 12px;
        line-height: 16px;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .column {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 50px;
        }

        .timestamp {
            font-size: 11px;
            letter-spacing: -0.4px;
            color: $grey-05;
            text-align: right;
            display: table;
            margin-top: 2px;
        }

        .badge-count {
            height: 18px;
            min-width: 18px;
            border-radius: 9px;
            background: $pink-deep;
            padding: 0 5px;
        }
    }

    &.selected {
        background: rgba(0, 0, 0, 0.06);
    }
}
</style>
