<template>
    <ProfileOptionSelector :model="'smoking'" :nextRoute="() => 'ReligionPage'" />
</template>

<script>
export default {
    name: 'SmokingPage',
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_SmokingPage',
            },
        })
    },
}
</script>
