<template>
    <div class="my-page-menu">
        <ul class="menu">
            <li
                class="item verify"
                v-for="item in verifications"
                :key="item.key"
                @click="onClickMenu(item.route, item)"
            >
                <div class="left">
                    <i v-if="item.id !== 19" class="material-icons-outlined icon">{{ item.icon }}</i>
                    <i v-else class="material-icons icon">{{ item.icon }}</i>
                    <span class="key" v-html="$translate(item.key)" />
                </div>
                <div
                    class="right"
                    :class="{ 'not-verified': item.status === 'NOT_VERIFIED', verified: item.status === 'VERIFIED' }"
                >
                    {{ $translate(item.status) }}
                </div>
            </li>
        </ul>
        <hr class="hr thick" />
        <ul class="menu">
            <li v-show="item.show" class="item" v-for="item in upper" :key="item.key" @click="onClickMenu(item.route)">
                <i class="material-icons-outlined icon">{{ item.icon }}</i>
                <span class="key" v-html="$translate(item.key)" />
            </li>
        </ul>

        <ul class="menu">
            <li class="item" v-for="item in lower" :key="item.key" @click="onClickMenu(item.route)">
                <i class="material-icons-outlined icon">{{ item.icon }}</i>
                <span class="key" v-html="$translate(item.key)" />
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'MyPageMenu',
    mounted() {
        const loadVerificationBadges = () => this.$store.dispatch('loadVerificationBadges')
        const loadMyVerificationBadges = () => this.$store.dispatch('loadMyVerificationBadges')

        Promise.all([loadVerificationBadges(), loadMyVerificationBadges()])
    },
    computed: {
        isFemale() {
            return (this.$store.getters.me || {}).gender === 1
        },
        mvb() {
            return this.$store.getters.myVerificationBadges || []
        },
        verificationBadges() {
            return this.$store.getters.verificationBadges || []
        },
        verifications() {
            return [
                {
                    // 19
                    id: 19,
                    key: 'REALNAME_VERIFY',
                    icon: 'how_to_reg',
                    route: 'VerificationBadgesCreatePage',
                    status: !this.mvb.some(v => v.verification_badge_id === 19)
                        ? 'NOT_VERIFIED'
                        : this.mvb.find(v => v.verification_badge_id === 19).confirmed
                        ? 'VERIFIED'
                        : 'PENDING',
                },
                {
                    // 16
                    id: 16,
                    key: 'JOB_VERIFY',
                    icon: 'work_outline',
                    route: 'VerificationBadgesCreatePage',
                    status: !this.mvb.some(v => v.verification_badge_id === 16)
                        ? 'NOT_VERIFIED'
                        : this.mvb.find(v => v.verification_badge_id === 16).confirmed
                        ? 'VERIFIED'
                        : 'PENDING',
                },
                {
                    // 15
                    id: 15,
                    key: 'UNIVERSITY_VERIFY',
                    icon: 'school',
                    route: 'VerificationBadgesCreatePage',
                    status: !this.mvb.some(v => v.verification_badge_id === 15)
                        ? 'NOT_VERIFIED'
                        : this.mvb.find(v => v.verification_badge_id === 15).confirmed
                        ? 'VERIFIED'
                        : 'PENDING',
                },
                {
                    // 17
                    id: 17,
                    key: 'MARRIAGE_VERIFY',
                    icon: 'wc',
                    route: 'VerificationBadgesCreatePage',
                    status: !this.mvb.some(v => v.verification_badge_id === 17)
                        ? 'NOT_VERIFIED'
                        : this.mvb.find(v => v.verification_badge_id === 17).confirmed
                        ? 'VERIFIED'
                        : 'PENDING',
                },
            ]
        },
        upper() {
            return [
                {
                    key: 'IN_APP_STORE',
                    icon: 'storefront',
                    route: 'InAppStorePage',
                    show: true,
                },
                {
                    key: 'BLOCK_FRIEND',
                    icon: 'person_add_disabled',
                    route: 'BlockContactsPage',
                    show: true,
                },
            ]
        },
        lower() {
            return [
                {
                    key: 'CUSTOMER_CENTER',
                    icon: 'headset_mic',
                    route: 'CustomerCenterPage',
                },
                {
                    key: 'SETTINGS',
                    icon: 'settings',
                    route: 'SettingsPage',
                },
            ]
        },
    },
    methods: {
        onClickMenu(route, badge) {
            if (route === 'CustomerCenterPage') {
                // this.$router.push({ name: route })
                this.$modal.basic({
                    title: 'MODAL_DEFAULT_TITLE',
                    body: this.$translate('CUSTOMER_CENTER_TEXT'),
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                return
            }
            if (route === 'VerificationBadgesCreatePage') {
                if (!this.$isVerifiedUser()) {
                    this.$modal
                        .basic({
                            body: '인증을 진행하시려면, 내 프로필을 등록하세요!',
                            buttons: [
                                {
                                    label: '다음에 하기',
                                    class: 'btn-default',
                                },
                                {
                                    label: '등록하기',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(idx => {
                            if (idx === 1) {
                                this.$goProfileRegister()
                            }
                        })

                    return
                }
                if (badge.status === 'NOT_VERIFIED') {
                    const vb = this.verificationBadges
                    const targetBadge = vb.find(b => b.id === badge.id)
                    if (targetBadge) {
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'ProfileMenuPage_Click_Verif',
                                option: {
                                    label: targetBadge.id === 15 ? 'school' : targetBadge === 16 ? 'job' : 'marriage',
                                },
                            },
                        })

                        this.$stackRouter.push({ name: route, props: { badge: targetBadge } })
                    } else {
                        this.$toast.error('해당 뱃지는 획득할 수 없습니다.')
                    }
                }
                return
            }
            if (route === 'InAppStorePage') {
                this.$stackRouter.push({ name: route, props: { from: 'setting' } })
            } else {
                this.$stackRouter.push({ name: route })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.my-page-menu {
    .hr.thick {
        background: $grey-02;
        border: 0;
        height: 8px;
    }

    .item {
        padding: 24px 16px;
        display: flex;
        align-items: center;
        border-top: solid 1px $grey-02;

        &.verify {
            justify-content: space-between;
            width: 100%;

            .left {
                display: flex;
            }
            .right {
                font-size: 16px;
                @include f-regular;
                color: $grey-06;

                &.verified {
                    color: $grey-05;
                }

                &.not-verified {
                    color: $purple-primary;
                    @include f-medium;
                }
            }
        }

        .icon {
            color: $grey-06;
            margin-right: 20px;
        }

        .key {
            font-size: 16px;
            color: black;
        }
    }
}
</style>
