<template>
    <div class="header-bar">
        <transition name="slide-down">
            <div v-show="show" class="title">
                <img
                    class="img-profile"
                    :src="(photos[0] || {}).url || photos[0] || $blankProfile"
                    @error="$event.target.src = $blankProfile"
                />
                {{ nameOrNickname }}님의 {{ this.from === 'social_meeting' ? '소셜' : ' ' }}프로필
            </div>
        </transition>
        <div @click="onClickLeftBtn" class="left">
            <i class="material-icons" :class="{ 'c-white': !show }">chevron_left</i>
        </div>
        <div v-if="!isMe" class="right" @click="onClickRightBtn">
            <i class="material-icons" :class="{ 'c-white': !show }">more_horiz</i>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'UserDetailHeaderBar',
    props: {
        from: String,
        user: Object,
        userId: Number,
        show: Boolean,
        photos: Array,
        nickname: String,
        backHandler: Function,
        loungePayloads: Object && undefined,
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        isMe() {
            return this.userId === this.me.id
        },
        nameOrNickname() {
            return ['RequestedSuccess', 'ActionRefundStatus'].indexOf(this.from) > -1 ? this.user.name : this.nickname
        },
    },
    methods: {
        onClickReport() {
            this.$stackRouter.push({
                name: 'ReportSelectReasonPage',
                props: {
                    target: {
                        id: this.user.id,
                        name: this.user.name,
                        nickname: this.user.profile.nickname,
                    },
                },
            })
        },
        async onClickBlock() {
            const idx = await this.$modal.basic({
                title: '회원을 차단할까요?',
                body: '차단하면 서로를 더 이상 볼 수 없습니다',
                buttons: [
                    {
                        label: '취소',
                        class: 'btn-default',
                    },
                    {
                        label: '차단하기',
                        class: 'btn-primary',
                    },
                ],
            })
            if (!idx) return

            const { msg } = await userService.blockUser.create(this.user.id)
            await this.$store.dispatch('loadChats', true)

            this.$store.commit(
                'setFeeds',
                (this.$store.getters.feeds || []).filter(f => f.id !== this.user.id)
            )
            this.$store.commit(
                'setNewFeeds',
                (this.$store.getters.newFeeds || []).filter(f => f.id !== this.user.id)
            )
            if (this.loungePayloads) {
                this.$store.commit('blockRequestCard', { postId: this.loungePayloads.loungeId, userId: this.user.id })
            }

            this.$toast.success(msg)
            this.$stackRouter.pop()
        },
        onClickLeftBtn() {
            if (this.backHandler) {
                this.backHandler()

                return
            }
            this.$stackRouter.pop()
        },
        onClickRightBtn() {
            this.$actionSheet({
                buttons: [
                    {
                        label: 'REPORT_LONG',
                        class: '',
                        handler: () => this.onClickReport(),
                    },
                    {
                        label: 'BLOCK_LONG',
                        class: '',
                        handler: () => setTimeout(() => this.onClickBlock(), 400),
                    },
                ],
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.header-bar {
    $header-height: 52px;
    position: sticky;
    left: 0;
    right: 0;
    z-index: 10;
    border-bottom: none;

    .title {
        position: fixed;
        height: $header-height;
        top: 0;
        left: 0;
        right: 0;
        color: black;
        background: white;
        border-bottom: 1px solid $grey-02;
        z-index: inherit;

        @include center;
        @include f-medium;

        .img-profile {
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }
    }

    .left,
    .right {
        z-index: inherit;
        position: fixed;
        top: 0;
        height: $header-height;
        width: $header-height;

        @include center;
        color: white;

        &.show {
            color: #ff3d6b;
        }
    }

    .left {
        left: 0;
    }

    .right {
        right: 0;
    }

    .material-icons {
        color: $grey-08;

        &.c-white {
            color: white;
        }
    }
}
</style>
