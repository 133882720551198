<template>
    <div class="signup basics birthday">
        <ProgressBar step="birthday" />
        <div class="content">
            <div class="title">생년월일</div>
            <div class="body" @click="focus">
                <div
                    class="birth"
                    :class="{ num: !isNaN(Number(str)) }"
                    v-for="(str, idx) in parseBirthday.slice(0, 4)"
                    :key="idx"
                    v-html="str"
                />
                <div class="birth separate">/</div>
                <div
                    class="birth"
                    :class="{ num: !isNaN(Number(str)) }"
                    v-for="(str, idx) in parseBirthday.slice(4, 6)"
                    :key="idx + 4"
                    v-html="str"
                />
                <div class="birth separate">/</div>
                <div
                    class="birth"
                    :class="{ num: !isNaN(Number(str)) }"
                    v-for="(str, idx) in parseBirthday.slice(6)"
                    :key="idx + 6"
                    v-html="str"
                />
            </div>
            <div class="validate c-pink-deep" v-if="msg" v-html="msg" />
        </div>
        <input ref="input" type="number" v-model="birthday" style="opacity: 0" class="image-input" />
        <BottomButton :label="$translate('NEXT')" :disabled="disabled" @click="next" />
    </div>
</template>

<script>
import ProgressBar from '@/routes/signup/components/ProgressBar'

export default {
    name: 'BirthdayPage',
    data: () => ({
        birthday: '',
    }),
    mounted() {
        this.$refs.input.focus()
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_BirthPage',
            },
        })
    },
    components: {
        ProgressBar,
    },
    created() {
        const birthday = JSON.parse(window.localStorage.getItem('signupData') || '{}').birthday
        if (birthday) {
            const arr = birthday.split('-')
            arr.forEach(n => {
                this.birthday += n
            })
        }
    },
    methods: {
        focus() {
            const input = this.$refs.input
            input.focus()
        },
        next() {
            if (this.disabled) return
            const signupData = JSON.parse(window.localStorage.getItem('signupData') || '{}')
            signupData.birthday = `${this.parseBirthday.slice(0, 4)}-${this.parseBirthday.slice(
                4,
                6
            )}-${this.parseBirthday.slice(6)}`

            window.localStorage.setItem('signupData', JSON.stringify(signupData))
            this.$router.push({ name: 'StatePage' })
        },
    },
    computed: {
        parseBirthday() {
            const stringBirthday = String(this.birthday || '')
            let res = ''
            for (let i = 0; i < 8; i++) {
                if (stringBirthday[i]) res += stringBirthday[i]
                else if (i <= 3) res += 'Y'
                else if (i <= 5) res += 'M'
                else res += 'D'
            }

            return res
        },
        notValid() {
            const thirtyOne = [1, 3, 5, 7, 8, 10, 12]
            const year = this.parseBirthday.slice(0, 4)
            const month = this.parseBirthday.slice(4, 6)
            const day = this.parseBirthday.slice(6)

            if (!(year > 1900 && year < 2000)) return true
            if (!(month > 0 && month < 13)) return true

            if (thirtyOne.includes(Number(month))) {
                return !(day > 0 && day < 32)
            } else {
                if (Number(month) === 2) {
                    return !(day > 0 && day < 29)
                }
                return !(day > 0 && day < 31)
            }
        },
        disabled() {
            if (isNaN(Number(this.parseBirthday))) return true
            else return this.notValid
        },
        msg() {
            if (isNaN(Number(this.parseBirthday))) return ''
            else {
                if (this.notValid)
                    return '<i class="material-icons c-pink-deep f-16">error_outline</i>올바른 생년월일을 입력하세요'
                else return ''
            }
        },
    },
}
</script>

<style scoped lang="scss">
.title {
    margin-bottom: 60px !important;
}

.body {
    display: flex;
    justify-content: space-around;

    .birth {
        @include f-regular;
        color: $grey-04;
        font-size: 17px;
        display: flex;
        justify-content: center;
        padding-bottom: 3px;
        border-bottom: 1px solid $grey-04;

        &.num {
            color: black;
        }
        &.separate {
            border: none;
        }
    }
}
.validate {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-top: 13px;
}
</style>
