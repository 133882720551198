<template>
    <div class="event-banner">
        <Carousel :per-page="1" :paginationEnabled="false">
            <Slide class="review-item" :key="idx" v-for="(banner, idx) in banners">
                <BannerImages :idx="idx" :banner="banner" :bannerLength="banners.length" />
            </Slide>
        </Carousel>
    </div>
</template>

<script>
import Vue from 'vue'
import BannerImages from '@/routes/chat/chats/components/BannerImages'

export default {
    name: 'EventBanner',
    components: {
        BannerImages,
    },
    data: () => ({
        banners: [
            {
                src: require('@/assets/images/chat/banners/goodbye_ons.png'),
                handler: () => {
                    Vue.prototype.$stackRouter.push({ name: 'GoodbyeOnsCampaignPage' })
                },
            },
            {
                src: require('@/assets/images/chat/banners/couple_review.png'),
                handler() {
                    Vue.prototype.$stackRouter.push({ name: 'DatingReviewsPage' })
                },
            },
        ],
    }),
}
</script>

<style scoped lang="scss">
.event-banner {
    width: 100%;
    padding: 0 16px 16px 16px;

    ::v-deep .VueCarousel-slide {
        position: relative;
    }
}
</style>
