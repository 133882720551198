import ManagerPage from '@/routes/manager/ManagerPage'
import ManagerWhatIsPage from '@/routes/manager/ManagerWhatIsPage'
import ManagerIntroductionPage from '@/routes/manager/ManagerIntroductionPage'
import Manager1RegisterPage from '@/routes/manager/Manager1RegisterPage'
import Manager2ConfirmStatusPage from '@/routes/manager/Manager2ConfirmStatusPage'
import Manager3MainPage from '@/routes/manager/Manager3MainPage'

export default [
    {
        path: '/manager',
        name: 'ManagerPage',
        component: ManagerPage,
    },
    {
        path: '/manager/whatis',
        name: 'ManagerWhatIsPage',
        component: ManagerWhatIsPage,
    },
    {
        path: '/manager/introduce',
        name: 'ManagerIntroductionPage',
        component: ManagerIntroductionPage,
    },
    {
        path: '/manager/register',
        name: 'Manager1RegisterPage',
        component: Manager1RegisterPage,
    },
    {
        path: '/manager/confirm-status',
        name: 'Manager2ConfirmStatusPage',
        component: Manager2ConfirmStatusPage,
    },
    {
        path: '/manager/main',
        name: 'Manager3MainPage',
        component: Manager3MainPage,
    },
]
