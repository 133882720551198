import * as $http from 'axios'

export default {
    update: profile => $http.put(`v2/users/me/profiles/${profile.id || profile.get('id')}`, profile),
    fix: profile => $http.put(`v2/users/me/profiles/${profile.id}/fix`, profile),

    update_office_region: (profile, payload) =>
        $http.put(`v2/users/me/profiles/${profile.id}/update_office_region`, payload),

    updateSubProfile: (id, payload) => $http.put(`v2/users/me/profiles/${id}/update_sub_profile`, payload),

    me: () => $http.get('v2/users/me/profiles'),
    regions: params => {
        let p = ''
        if (params) {
            if (params.name) p += `name=${params.name}&`
            if (params.district) p += `district=${params.district}`
        }
        return $http.get(`v2/regions${p ? `?${p}` : ''}`)
    },
    stations: ({ region, name }) => $http.get(`v2/stations?region=${region}&name=${name}`),
    schools: () => $http.get('v2/schools'),
    schoolTypes: () => $http.get('v2/school_types'),
    universities: ({ schoolTypeId, name }) => $http.get(`v2/universities?school_type_id=${schoolTypeId}&name=${name}`),
    jobCategories: () => $http.get('v2/job_categories'),
    companies: ({ jobCategoryId, name }) => {
        let p = ''
        p += `job_category_id=${jobCategoryId}`
        if (name) p += `&name=${name}`

        return $http.get(`v2/companies?${p}`)
    },
    jobs: ({ name }) => $http.get(`v2/jobs?name=${name}`),
    jobTypes: ({ name }) => $http.get(`v2/job_types?name=${name}`),
    majors: ({ name }) => $http.get(`v2/majors?name=${name}`),
    profileOptions: () => $http.get('v2/profile_options'),
}
