import loungeService from '@/services/lounge'

const defaultState = () => ({
    lounge: {
        allPosts: [],
        myPosts: [],
        interestedPosts: [],
    },
    loungeLastScrollTop: 0,
})

const state = defaultState()

const getters = {
    lounge: state => state.lounge,
    loungeLastScrollTop: state => state.loungeLastScrollTop,
}

const actions = {
    async loadLoungePosts({ commit }) {
        try {
            const allPosts = await loungeService.all()
            const myPosts = await loungeService.mine()
            const interestedPosts = await loungeService.interest()
            commit('setLounge', { allPosts, myPosts, interestedPosts })
        } catch (e) {
            return Promise.reject(e)
        }
    },

    async reloadForMyPosts({ state, commit }) {
        try {
            const allPosts = await loungeService.all()
            const myPosts = await loungeService.mine()
            commit('setLounge', {
                allPosts,
                myPosts,
                interestedPosts: state.lounge.interestedPosts,
            })
        } catch (e) {
            return Promise.reject(e)
        }
    },
}

const mutations = {
    setLounge(state, lounge) {
        state.lounge = { ...lounge }
    },
    setLoungeAllPosts(state, posts) {
        if (!posts) return

        state.lounge.allPosts = [...posts]
    },
    setLoungeMyPosts(state, posts) {
        if (!posts) return

        state.lounge.myPosts = [...posts]
    },
    setLoungeInterestedPosts(state, posts) {
        if (!posts) return

        state.lounge.interestedPosts = [...posts]
    },
    setLoungeLastScrollTop(state, value) {
        state.loungeLastScrollTop = value
    },
    updateRequestPost(state, { id, request }) {
        // 관심있어요 클릭 시 관심있어요 피드로 카드 이동
        const target = state.lounge.allPosts.find(post => post.id === id)
        target.my_request = request

        const prevInterested = state.lounge.interestedPosts
        state.lounge.interestedPosts = [target, ...prevInterested].sort((a, b) => b.id - a.id)
    },
    updateReadInterest(state, id) {
        // 관심있어요 탭에서 게시글 조회 시 읽음여부 체크
        const allTarget = state.lounge.allPosts.find(post => post.id === id)
        const interestTarget = state.lounge.interestedPosts.find(post => post.id === id)

        allTarget.my_request.participant_checked = true
        interestTarget.my_request.participant_checked = true
    },
    updateCardStatus(state, card) {
        // 카드플립, 채팅방 오픈 등 카드와 관련된 유저 동작 시 상태 업데이트
        const { accept_status: status, lounge_id: postId, id: loungeReqId, chat_id: chatId } = card
        const allPost = state.lounge.allPosts.find(post => post.id === postId) || {}
        const myPost = state.lounge.myPosts.find(post => post.id === postId) || {}
        const changeList = [allPost, myPost]

        if (status === 'request') {
            changeList.forEach(obj => {
                const requests = obj.requests || []
                const request = requests.find(req => req.id === loungeReqId)
                if (request) {
                    request.accept_status = 'checked'
                    request.host_checked = true
                    request.participant_checked = false
                }
            })
        } else if (status === 'checked') {
            changeList.forEach(obj => {
                const requests = obj.requests || []
                const request = requests.find(req => req.id === loungeReqId)
                if (request) {
                    request.accept_status = 'accepted'
                    request.chat_id = chatId
                }
            })
        }
    },
    blockRequestCard(state, { postId, userId }) {
        try {
            const allPost = state.lounge.allPosts.find(post => post.id === postId) || {}
            const myPost = state.lounge.myPosts.find(post => post.id === postId) || {}
            const changeList = [allPost, myPost]
            changeList.forEach(obj => {
                obj.requests = (obj.requests || []).filter(r => r.user_id !== userId)
            })
        } catch (e) {
            console.log(e)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
