import { store as $store } from '@/store'
import './modal'
import blankProfile from '@/assets/images/blank_profile.svg'
import router from '@/router'
// import userService from '@/services/user'

export const Shortcuts = {
    install(Vue) {
        Vue.prototype.$translate = key => Vue.options.filters.translate(key)

        Vue.prototype.$blankProfile = blankProfile

        Vue.prototype.$backHandlers = {}

        // 현재 라우트의 백 버튼에 대한 동작을 오버라이드하는 콜백을 등록한다
        // 스택라우터의 경우 vue-router를 사용하지 않기 때문에 스택라우터에서 백핸들러를 사용하려면 parameter로 라우트 이름을 직접 전달한다
        Vue.prototype.$registerBackHandler = (callback, routeName) => {
            const name = routeName || router.currentRoute.name
            Vue.prototype.$backHandlers[name] = callback
        }

        Vue.prototype.$unregisterBackHandler = routeName => {
            const name = routeName || router.currentRoute.name
            delete Vue.prototype.$backHandlers[name]
        }

        Vue.prototype.$alert = arg => {
            Vue.prototype.$modal.custom({
                component: 'ModalAlert',
                options:
                    typeof arg === 'object'
                        ? {
                              title: arg.title,
                              body: Vue.options.filters.translate(arg.body),
                          }
                        : {
                              title: 'MODAL_DEFAULT_TITLE',
                              body: Vue.options.filters.translate(arg),
                          },
            })
        }

        Vue.prototype.$log = (...args) => {
            console.log(args)
            Vue.prototype.$nativeBridge.postMessage({
                action: 'propWebLogToRN',
                value: args,
            })
        }

        Vue.prototype.$actionSheet = ({ buttons, style }) => {
            return Vue.prototype.$modal.custom({
                component: 'ModalActionSheet',
                options: {
                    buttons,
                    style,
                },
            })
        }

        Vue.prototype.$isRootRoute = () =>
            [
                'FrontPage',
                'ChatsPage',
                'MyDatingPage',
                'MyPage',
                'SystemMaintenanceNoticePage',
                'FeedPage',
                'LoungePage',
                'Manager2ConfirmStatusPage',
                'Manager3MainPage',
            ].indexOf(router.currentRoute.name) !== -1

        Vue.prototype.$isVerifiedUser = () => {
            // profile.status
            // status created: 0(프로필 미입력)
            // no_both : 1 (사진, 자소서 둘다 없는거)
            // no_photo : 2 (사진업슨거)
            // no_intro : 3 (자소서 길이 0인거, 공백만 입력한경우에도 처리)
            // all: 10
            if (!$store.getters.me) return false

            return $store.getters.me.profile.status !== 'created'
        }

        Vue.prototype.$blurOtherProfile = feed => {
            return feed.photo_url && !$store.getters.myProfileCanSee.photoCount
        }

        Vue.prototype.$alternativeImg = gender => {
            return require(`@/assets/images/no_photo_${gender}.jpg`)
        }

        Vue.prototype.$isDormant = (profile, blocked = false) => {
            if (!profile) return false

            if (blocked) return true

            const { enabled, is_dormant: dormant, hidden } = profile
            return !enabled || dormant || hidden
        }

        Vue.prototype.$targetUserProfileStatus = user => {
            const hasPhoto = (user.photos || []).length !== 0
            const hasIntro = !!user.profile.intro

            if (user.profile.status === 'created') return 0

            if (!hasPhoto && !hasIntro) return 1
            else if (!hasPhoto && hasIntro) return 2
            else if (hasPhoto && !hasIntro) return 3
            else if (hasPhoto && hasIntro) return 10
            else return 0
        }

        Vue.prototype.$myDatingUserImg = ({
            target: { enabled, is_dormant: dormant, hidden, photo_url: photo, gender },
        }) => {
            if (!enabled || dormant || hidden) return require('@/assets/images/blank_profile.svg')

            if (photo) return photo
            else {
                return require(`@/assets/images/no_photo_${gender}.jpg`)
            }
        }

        Vue.prototype.$isMale = () => {
            return $store.getters.me.gender === 0
        }
        Vue.prototype.$isTester = () => {
            const { gender, id } = $store.getters.me

            if (id < 4205) return false // 배포 이후 가입자 tester false

            return gender === 0 && !(id % 2)
        }

        Vue.prototype.$numArray = len => Array.apply(null, { length: len }).map(Number.call, Number)

        Vue.prototype.$loading = payload => {
            $store.commit('setLoading', payload)
        }

        Vue.prototype.$copy = obj => JSON.parse(JSON.stringify(obj))

        Vue.prototype.$mustParse = str => {
            let obj
            try {
                obj = JSON.parse(str)
            } catch (e) {}
            return obj
        }

        Vue.prototype.$vbConstants = {
            types: {
                marry: [
                    { title: '초혼만 원함', value: 0 },
                    { title: '재혼만 원함', value: 1 },
                    { title: '둘 다 관계 없음', value: 2 },
                ],
                income: [
                    { title: '소득 수준 관계 없음', value: 0 },
                    { title: '2,000만원 이상', value: 1 },
                    { title: '3,000만원 이상', value: 2 },
                    { title: '4,000만원 이상', value: 3 },
                    { title: '5,000만원 이상', value: 4 },
                    { title: '6,000만원 이상', value: 5 },
                    { title: '7,000만원 이상', value: 6 },
                    { title: '8,000만원 이상', value: 7 },
                    { title: '9,000만원 이상', value: 8 },
                    { title: '1억원 이상', value: 9 },
                ],
                child: [
                    { title: '자녀가 없는 분만 원함', value: 0 },
                    { title: '자녀가 있는 분만 원함', value: 1 },
                    { title: '둘 다 관계 없음', value: 2 },
                ],
                region: [
                    { title: '수도권', value: 0 },
                    { title: '영남권(경상도)', value: 1 },
                    { title: '호남권(전라도)', value: 2 },
                    { title: '충청권', value: 3 },
                    { title: '강원권', value: 4 },
                    { title: '제주', value: 5 },
                ],
            },
        }

        Vue.prototype.$case = Vue.options.filters.$case

        Vue.prototype.$isAndroid = () => {
            return navigator.userAgent.match(/Android/i)
        }

        Vue.prototype.$isIOS = () => {
            return navigator.userAgent.match(/iPhone|iPad|iPod|AppleWebKit/i) && !navigator.userAgent.match(/Android/i)
        }

        Vue.prototype.$nameAndNick = profile => {
            const { name, nickname } = profile
            const isActiveUser = !Vue.prototype.$isDormant(profile)
            if (!isActiveUser) return `휴면회원`

            return `${nickname} ${name ? `(${name})` : ''}`
        }

        Vue.prototype.$waitFor = timeout => new Promise(resolve => setTimeout(resolve, timeout)) // timeout 동안 기다리기

        Vue.prototype.$openGallery = () => {
            Vue.prototype.$nativeBridge.postMessage({ action: 'openGallery' })
        }

        Vue.prototype.$goProfileRegister = () => {
            const signupData = JSON.parse(window.localStorage.getItem('signupData') || '{}')
            router.push({ name: 'MarryPage' })

            if (signupData.marry && signupData.marry.next) router.push({ name: 'HaveChildPage' })
            if (signupData.have_child) router.push({ name: 'GenderPage' })
            if (signupData.gender) router.push({ name: 'BirthdayPage' })
            if (signupData.birthday) router.push({ name: 'StatePage' })
            if (signupData.state && signupData.state.is_station) router.push({ name: 'StationPage' })
            if (signupData.state && !signupData.state.is_station) router.push({ name: 'RegionPage' })
            if (signupData.station || signupData.region) router.push({ name: 'SchoolPage' })
            if (signupData.school && signupData.school.id === 5) router.push({ name: 'JobCategoryPage' })
            if (signupData.school && signupData.school.id !== 5) router.push({ name: 'SchoolTypePage' })
            if (signupData.school_type && signupData.school_type.verification_required)
                router.push({ name: 'UniversityPage' })
            if (signupData.school_type && signupData.school.is_student) router.push({ name: 'IncomePage' })
            if (signupData.school_type && !signupData.school.is_student) router.push({ name: 'JobCategoryPage' })
            if (signupData.job_category && !signupData.job_category.verification_required)
                router.push({ name: 'JobPage' })
            if (signupData.job_category && signupData.job_category.verification_required)
                router.push({ name: 'CompanyPage' })
            if (signupData.company) router.push({ name: 'JobPage' })
            if (signupData.income) router.push({ name: 'AssetPage' })
            if (signupData.asset) router.push({ name: 'HeightPage' })
            if (signupData.height) router.push({ name: 'ShapesPage' })
            if (signupData.shape) router.push({ name: 'KindsPage' })
            if (signupData.kind) router.push({ name: 'SmokingPage' })
            if (signupData.smoking) router.push({ name: 'ReligionPage' })
            if (signupData.religion) router.push({ name: 'IntroduceMyselfPage' })

            // 자기소개 스킵하는 경우도 있기 때문에 해당 경우에는 nickname으로 떨궈줘야 함
            if (signupData.intro !== undefined) router.push({ name: 'NicknamePage' })
        }

        Vue.prototype.$goSubProfileRegister = () => {
            // const signupData = JSON.parse(window.localStorage.getItem('signupData') || '{}')

            router.push({ name: 'HometownPage' })

            // if (signupData.marry && signupData.marry.next) router.push({ name: 'HaveChildPage' })
            // if (signupData.have_child) router.push({ name: 'GenderPage' })
            // if (signupData.gender) router.push({ name: 'BirthdayPage' })
            // if (signupData.birthday) router.push({ name: 'StatePage' })
            // if (signupData.state && signupData.state.is_station) router.push({ name: 'StationPage' })
            // if (signupData.state && !signupData.state.is_station) router.push({ name: 'RegionPage' })
            // if (signupData.station || signupData.region) router.push({ name: 'SchoolPage' })
            // if (signupData.school && signupData.school.id === 5) router.push({ name: 'JobCategoryPage' })
            // if (signupData.school && signupData.school.id !== 5) router.push({ name: 'SchoolTypePage' })
            // if (signupData.school_type && signupData.school_type.verification_required)
            //     router.push({ name: 'UniversityPage' })
            // if (signupData.school_type && signupData.school.is_student) router.push({ name: 'IncomePage' })
            // if (signupData.school_type && !signupData.school.is_student) router.push({ name: 'JobCategoryPage' })
            // if (signupData.job_category && !signupData.job_category.verification_required)
            //     router.push({ name: 'JobPage' })
            // if (signupData.job_category && signupData.job_category.verification_required)
            //     router.push({ name: 'CompanyPage' })

            // 자기소개 스킵하는 경우도 있기 때문에 해당 경우에는 nickname으로 떨궈줘야 함
            // if (signupData.intro !== undefined) router.push({ name: 'NicknamePage' })
        }

        Vue.prototype.$isInstantChat = chat => {
            if (!chat) return false

            if (chat.chat_type === 'real_friend' || chat.chat_type === 'social_group') {
                return false
            } else if (chat.chat_type === 'pro_agent') {
                return !!chat.expire_at
            }
        }
    },
}

export const Toast = {
    install(Vue) {
        Vue.prototype.$toast = {
            // 토스트메시지 위치 조정이 가능하도록 옵션 값 인자로 전달 가능
            success: (message, position, duration) =>
                $store.dispatch('setToast', {
                    message,
                    type: 'success',
                    options: {
                        position,
                        duration,
                    },
                }),
            error: (message, position, duration) =>
                $store.dispatch('setToast', {
                    message,
                    type: 'error',
                    options: {
                        position,
                        duration,
                    },
                }),
        }
    },
}

export const Distance = {
    install(Vue) {
        Vue.prototype.$distance = (loc1, loc2) => {
            if (!loc1 || !loc2) return

            const lat1 = parseFloat(loc1.lat)
            const lon1 = parseFloat(loc1.lng)
            const lat2 = parseFloat(loc2.lat)
            const lon2 = parseFloat(loc2.lng)

            const R = 6371
            const dLat = ((lat2 - lat1) * Math.PI) / 180
            const dLon = ((lon2 - lon1) * Math.PI) / 180
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos((lat1 * Math.PI) / 180) *
                    Math.cos((lat2 * Math.PI) / 180) *
                    Math.sin(dLon / 2) *
                    Math.sin(dLon / 2)
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
            const d = R * c
            return Math.floor(d)
        }
    },
}

export const Scroll = {
    install(Vue) {
        const duration = 50
        const increment = 5

        const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2
            if (t < 1) return (c / 2) * t * t + b
            t--
            return (-c / 2) * (t * (t - 2) - 1) + b
        }

        const animateScroll = (el, currentTime, start, change, limit, direction) => {
            currentTime += increment
            if (direction === 'up' || direction === 'down') {
                el.scrollTop = easeInOutQuad(currentTime, start, change, duration)
            }

            if (direction === 'left' || direction === 'right') {
                el.scrollLeft = easeInOutQuad(currentTime, start, change, duration)
            }

            if (currentTime < duration) {
                if (!limit) {
                    setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                } else {
                    if (direction === 'up' && start - el.scrollTop < limit) {
                        setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                    }
                    if (direction === 'down' && el.scrollTop - start < limit) {
                        setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                    }
                    if (direction === 'left' && start - el.scrollLeft < limit) {
                        setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                    }
                    if (direction === 'right' && el.scrollLeft - start < limit) {
                        setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                    }
                }
            }
        }

        Vue.prototype.$scroll = {
            up: (el, animate, limit) => {
                if (!el) return

                const currentTime = 0
                const start = el.scrollTop
                const change = 0 - start

                if (animate) {
                    animateScroll(el, currentTime, start, change, limit, 'up')
                } else {
                    el.scrollTop = el.scrollTop - limit || 0
                }
            },
            down: (el, animate, limit) => {
                if (!el) return

                const currentTime = 0
                const start = el.scrollTop
                const change = el.scrollHeight - start

                if (animate) {
                    animateScroll(el, currentTime, start, change, limit, 'down')
                } else {
                    el.scrollTop = limit || el.scrollHeight
                }
            },
            left: (el, animate, limit) => {
                if (!el) return

                const currentTime = 0
                const start = el.scrollLeft
                const change = 0 - start

                if (animate) {
                    animateScroll(el, currentTime, start, change, limit, 'left')
                } else {
                    el.scrollLeft = el.scrollLeft - limit || 0
                }
            },
            right: (el, animate, limit) => {
                if (!el) return

                const currentTime = 0
                const start = el.scrollLeft
                const change = el.scrollWidth - start

                if (animate) {
                    animateScroll(el, currentTime, start, change, limit, 'right')
                } else {
                    el.scrollLeft = limit || el.scrollWidth
                }
            },
        }
    },
}

export const NativeBridge = {
    install(Vue) {
        Vue.prototype.$nativeBridge = {
            postMessage: obj => {
                if (!window.ReactNativeWebView) return

                // 스테이징, 개발환경에서 이벤트 안쏘게
                if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
                    if (['sendAirbridgeEvent', 'sendFirebaseEvent'].includes(obj.action)) return
                }

                // firebase 옵션에 유저 id 넣어서 전송
                if (obj.action === 'sendFirebaseEvent') {
                    const signupData = JSON.parse(localStorage.getItem('signupData'))
                    if (signupData) {
                        obj.value.option = {
                            ...obj.value.option,
                            gender:
                                (obj.value.option || {}).gender !== undefined
                                    ? obj.value.option.gender
                                    : signupData.gender
                                    ? signupData.gender.id
                                    : null,
                            user_id: $store.getters.me ? $store.getters.me.id : null,
                        }
                    } else {
                        obj.value.option = {
                            ...obj.value.option,
                            user_id: $store.getters.me ? $store.getters.me.id : null,
                            gender: $store.getters.me ? $store.getters.me.gender : null,
                        }
                    }
                }
                window.ReactNativeWebView.postMessage(JSON.stringify(obj))
            },
        }

        Vue.prototype.$updateAppIconBadgeCount = () => {
            const totalUnreads = ($store.getters.chats || []).reduce((acc, chat) => acc + (chat.unread || 0), 0)

            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        action: 'setIconBadgeCount',
                        value: totalUnreads,
                    })
                )
            }
        }
    },
}

export const StackRouter = {
    install(Vue) {
        Vue.prototype.$stackRouter = {
            push: pageObj => {
                $store.dispatch('stackRouterPush', pageObj)
            },
            pop: () => {
                $store.dispatch('stackRouterPop')
            },
            replace: pageObj => {
                $store.dispatch('stackRouterReplace', pageObj)
            },
            popTo: length => {
                $store.dispatch('stackRouterPopTo', length)
            },
            clear: () => {
                $store.dispatch('stackRouterClear')
            },
        }
    },
}
