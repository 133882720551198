<template>
    <div class="feed" @click="onClickFeed(feed)">
        <div class="top">
            <div class="left">
                <img v-lazy="feed.photo_url ? feed.photo_url : $alternativeImg(feed.gender)" @error="onError(feed)" />
                <div
                    v-if="!$isVerifiedUser() || (feed.photo_url && !$store.getters.myProfileCanSee.photoCount)"
                    class="blur"
                />
                <div class="user-info">
                    <div class="name m-b-3 spoqa-f-medium c-black" v-html="feed.nickname" />
                    <div class="age-region spoqa-f-regular f-13">
                        {{ $options.filters.asAge(feed.profile.birthday) }}세({{ feed.profile.chinese_zodiac }})<span
                            class="c-grey-04"
                            >ㅣ</span
                        >{{ feed.profile.region_shorten }}
                    </div>
                </div>
            </div>
            <div class="right">
                <div v-if="isNewUser(feed)" class="new-badge" v-html="'신규'" />
                <MarryState :marry="feed.profile.marry" />
            </div>
        </div>
        <div class="bottom m-t-16">
            <p class="spoqa-f-regular" v-html="feed.profile.intro" />
        </div>
        <div class="badges">
            <div
                class="verification-badge"
                v-for="badge in (feed.verification_badges || [])
                    .filter(b => b.confirmed)
                    .sort((a, b) => {
                        if (a.position > b.position) return 1
                        if (a.position < b.position) return -1
                    })"
                :key="badge.id"
            >
                <i class="material-icons-outlined" v-if="[15].includes(badge.id)">{{ badge.image_name }}</i>
                <i class="material-icons" v-else>{{ badge.image_name }}</i>
                <div>{{ badge.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FeedItem',
    components: {},
    props: {
        feed: Object,
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
    },
    methods: {
        onClickFeed(feed) {
            if (!this.$isVerifiedUser()) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Alert_ProfileRegister',
                    },
                })
                this.$modal
                    .basic({
                        body: '프로필을 자세히 보려면,<br>내 프로필을 등록하세요!',
                        buttons: [
                            {
                                label: '다음에 하기',
                                class: 'btn-default',
                            },
                            {
                                label: '등록하기',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.$goProfileRegister()
                        }
                    })
            } else {
                this.$stackRouter.push({
                    name: 'UserDetailPage',
                    props: {
                        userId: feed.id,
                        from: 'FeedPage',
                    },
                })
            }
        },
        isNewUser(feed) {
            return (
                this.$moment().diff(feed.created_at, 'hours') <= 72 &&
                this.$moment().diff(feed.created_at, 'hours') >= 0
            )
        },
        onError(feed) {
            const left = event.target.parentNode
            const blur = left.querySelector('.blur')
            event.target.src = require(`@/assets/images/no_photo_${feed.gender}.jpg`)
            blur.classList.remove('blur')
        },
    },
}
</script>

<style scoped lang="scss">
.feed {
    // margin-top: 16px;
    margin-bottom: 12px;
    background: white;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.04);

    .top {
        @include between;

        .left {
            display: flex;
            align-items: center;
            position: relative;

            .blur {
                position: absolute;
                backdrop-filter: blur(6px);
                -webkit-backdrop-filter: blur(6px);
                @include round-image;
            }

            img {
                @include round-image;
            }

            .user-info {
                color: $grey-07;
            }
        }
        .right {
            position: relative;

            .new-badge {
                padding: 4px 8px;
                color: white;
                font-size: 13px;
                background: #b1a3fc;
                border-radius: 6px;
                width: fit-content;
                margin-left: 33px;
                margin-bottom: 4px;

                @include spoqa-f-medium;
            }

            .marry-state {
                padding: 4px 8px;
                color: white;
                font-size: 13px;
                background: #1cc6c6;
                border-radius: 6px;
                width: fit-content;
                margin-left: 33px;

                @include spoqa-f-medium;

                &.marry {
                    background: #ffb300;
                }
            }
        }
    }
    .bottom {
        p {
            line-height: 22px;
            font-size: 14px;
        }
    }
}
.badges {
    margin-top: 24px;
    display: flex;
    flex-flow: row wrap;

    .verification-badge {
        display: flex;
        align-items: center;
        padding: 4px 10px 4px 6px;
        border: 1px solid $grey-02;
        border-radius: 14px;
        width: fit-content;
        margin-right: 6px;
        margin-bottom: 6px;
        background: $grey-01;

        i {
            font-size: 13px;
            margin-right: 4px;
            color: $grey-07;
        }
        div {
            font-size: 12px;
            color: $grey-09;
        }
    }
}
</style>
