<template>
    <div></div>
</template>

<script>
export default {
    name: 'SubProfileOptionSelector',
    props: ['model', 'nextRoute'],
    mounted() {
        this.openModal()
        this.$bus.$on('clickSkipButton', () => this.onClickSkip())
    },
    beforeDestroy() {
        this.$bus.$off('clickSkipButton')
    },
    computed: {
        subProfileData() {
            const subProfileData = window.localStorage.getItem('subProfileData') || '{}'
            return JSON.parse(subProfileData)
        },
    },
    methods: {
        openModal() {
            this.$modal
                .custom({
                    component: 'ModalProfileItemSelector',
                    options: {
                        model: this.model,
                        icon: this.model === 'hometown' ? null : 'chevron_left',
                        profile: this.subProfileData,
                        from: 'subProfile',
                    },
                })
                .then(result => {
                    if (!result || (this.model === 'pet' && result.length === 0)) {
                        this.$router.go(-1)
                        return
                    }

                    const subProfileData = this.subProfileData
                    if (
                        (result.$$selected !== null && result.$$selected !== undefined) ||
                        (this.model === 'pet' && result.length > 0)
                    ) {
                        subProfileData[this.$case.toSnake(this.model)] = result
                    } else if (this.model === 'pet' && result) {
                        subProfileData[this.$case.toSnake(this.model)] = result
                    }

                    window.localStorage.setItem('subProfileData', JSON.stringify(subProfileData))
                    this.$router.push({ name: this.nextRoute(result) })
                })
        },
        onClickSkip() {
            const subProfileData = this.subProfileData
            window.localStorage.setItem('subProfileData', JSON.stringify(subProfileData))

            this.$router.push({ name: this.nextRoute() })
        },
    },
}
</script>
