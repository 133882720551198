<template>
    <div class="basic-profile">
        <h3 class="title" v-if="!managerMatchProfile" v-html="$translate('BASIC_INFORMATION')" />
        <ul class="items" v-for="(group, idx) in profileGroup" :key="`${group}${idx}`">
            <hr v-if="idx > 0 && !managerMatchProfile" class="group-divider" />
            <li class="item" v-for="[key, value] in group" :key="key" v-show="value.content">
                <template v-if="key === 'JOB_DESCRIPTION'">
                    <div class="job-description">
                        <p class="desc" :class="{ 'lines-3': shouldClamp }" v-html="value.content" ref="description" />
                        <span
                            v-if="shouldClamp"
                            class="more"
                            v-html="$translate('MORE')"
                            @click="shouldClamp = false"
                        />
                    </div>
                </template>
                <template v-else>
                    <div class="key" v-html="$translate(key)" />
                    <div class="value">
                        <span class="content">{{ value.content }}</span>
                    </div>
                </template>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'BasicProfile',
    props: {
        profile: {
            type: Object,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
        managerMatchProfile: {
            type: Boolean,
            required: false,
        },
    },
    data: () => ({
        profileGroup: null,
        shouldClamp: true,
    }),
    watch: {
        userId: 'init',
        profile: 'init',
    },
    mounted() {
        this.init()
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        phoneNumber() {
            return this.me.phone_number
        },
        isMe() {
            return this.userId === this.me.id
        },
    },
    methods: {
        init() {
            this.populateProfile()
            this.$nextTick(() => this.checkOverflown())
        },
        populateProfile() {
            const group1 = new Map()
            const group2 = new Map()
            const group3 = new Map()

            group1.set('JOB_NAME', {
                content: this.profile.job_name,
                // verified: this.verificationBadges.some(vb => vb.verification === 'job'),
            })

            let companyString = `${this.profile.job_category.name}`

            if (this.profile.job_type_name) {
                if (this.profile.company_name) {
                    companyString += ` (${this.profile.company_name} - ${this.profile.job_type_name})`
                } else {
                    companyString += ` (${this.profile.job_type_name})`
                }
            }

            group1.set('COMPANY_NAME', {
                content: companyString,
                // verified: this.verificationBadges.some(vb => vb.verification === 'company'),
            })

            group1.set('INCOME', { content: this.profile.income })
            group1.set('ASSET', { content: this.profile.asset })

            group1.set('JOB_DESCRIPTION', { content: this.profile.job_description })

            let schoolString = ''

            // 고등학교 졸업 및 기타의 경우
            if (this.profile.school.id === 5) {
                schoolString = '고등학교 졸업'
            } else {
                schoolString += this.profile.school_type.name

                if (this.profile.university_name && this.profile.major_name) {
                    schoolString += ` (${this.profile.university_name} - ${this.profile.major_name})`
                } else if (this.profile.university_name) {
                    schoolString += ` (${this.profile.university_name})`
                }

                // 대학원 재학/졸업의 경우
                if (this.profile.school_id === 3 || this.profile.school_id === 4) {
                    schoolString += ` ${this.profile.school.name}`
                } else {
                    if (this.profile.is_student) {
                        schoolString += ' 재학'
                    } else {
                        schoolString += ' 졸업'
                    }
                }
            }

            group1.set('UNIVERSITY', {
                content: schoolString,
            })
            if (this.profile.hometown_id) {
                group1.set('HOMETOWN', {
                    content: this.$store.getters.states[this.profile.hometown_id - 1].name,
                })
            }

            group1.set('CHILD', {
                content: this.profile.have_child ? '있음' : '없음',
            })

            group2.set('KIND', { content: this.profile.kind })
            group2.set('SHAPE', { content: this.profile.shape })

            group2.set('SMOKING', { content: !this.profile.smoking ? '비흡연' : '흡연' })
            group2.set('RELIGION_NAME', { content: this.profile.religion_name })
            if (this.profile.exercise) {
                group2.set('EXERCISE', { content: this.profile.exercise })
            }

            if (this.profile.house_style) {
                if (this.profile.house_payment) {
                    group3.set('HOUSE_TYPE', { content: `${this.profile.house_style}(${this.profile.house_payment})` })
                } else {
                    group3.set('HOUSE_TYPE', { content: `${this.profile.house_style}` })
                }
            } else {
                if (this.profile.house_payment) {
                    group3.set('HOUSE_TYPE', { content: `${this.profile.house_payment}` })
                } else {
                    // group3.set('HOUSE_TYPE', { content: `${this.profile.house_style}` })
                }
            }

            if (this.profile.car_type) {
                group3.set('CAR_TYPE', { content: this.profile.car_type })
            }

            if (this.profile.pet) {
                group3.set('PET', { content: this.profile.pet })
            }

            // if (this.profile.marry) {
            //     group3.set('HAVE_CHILD', { content: this.profile.have_child ? '있음' : '없음' })
            // }

            this.profileGroup = this.managerMatchProfile ? [group1] : [group1, group2]
            if (group3.size !== 0) this.profileGroup.push(group3)
        },
        checkOverflown() {
            const dom = this.$refs.description[0]
            if (dom) {
                this.shouldClamp = dom.scrollHeight - 2 > dom.clientHeight
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.basic-profile {
    z-index: 2;
    position: relative;
    overflow: hidden;

    .title {
        margin-bottom: 24px;
        color: black;
        font-size: 18px;
        font-weight: 500;

        @include f-medium;
    }

    .group-divider {
        border-color: $grey-01;
        margin-bottom: 28px;
    }

    .item {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }

    .key {
        font-size: 15px;
        color: $grey-06;
        width: 122px;

        @include flex-wrap;
    }

    .value {
        $content-line-height: 17.25px;
        display: flex;
        align-items: center;
        line-height: $content-line-height;

        .content {
            color: black;
        }
    }

    .job-description {
        margin-top: -12px;
        margin-left: 122px;
        padding: 12px 14px;
        width: 100%;
        border: solid 1px $grey-02;
        border-radius: 0 12px 12px;
        font-size: 14px;
        line-height: normal;
        letter-spacing: -0.2px;
        align-items: flex-end;
        white-space: pre-line;

        @include flex;

        .desc {
            color: $grey-09;
            align-self: flex-start;
        }

        .more {
            color: $purple-primary;
            text-decoration: underline;
        }
    }
}
</style>
