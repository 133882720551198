<template>
    <div class="more-read" @click.stop="updateShouldClamp()">
        <p class="content" :class="{ 'lines-4': shouldClamp }" v-html="message" ref="message" />
        <span v-if="shouldClamp" class="more" v-html="$translate('MORE')" />
    </div>
</template>

<script>
export default {
    name: 'MoreRead',
    props: ['message'],
    data: () => ({
        shouldClamp: true,
    }),
    mounted() {
        this.$nextTick(() => this.checkOverflown())
    },
    methods: {
        checkOverflown() {
            const dom = this.$refs.message
            if (dom) {
                this.shouldClamp = dom.scrollHeight - 2 > dom.clientHeight
            }
        },
        updateShouldClamp() {
            if (this.shouldClamp === true) {
                this.shouldClamp = false
            } else {
                this.$emit('onClick')
            }
        },
    },
}
</script>

<style scoped lang="scss">
.content {
    color: black;
}
.more {
    margin-top: 2px;
    text-decoration: underline;
    color: $purple-primary;
}
</style>
