<template>
    <div class="signup basics introduce-myself">
        <ProgressBar step="introduce" />
        <div class="content w-100">
            <div class="texts">
                <p class="f-medium m-b-8 title">자기소개</p>
            </div>
            <div>
                <TextareaWithX v-model="content" :placeholder="`하시는 일, 성격, 희망 배우자 등에 대해 알려주세요`" />
            </div>
            <div class="warning">
                <span>연락처 및 SNS ID</span>
                를 적는 경우 즉시 영구 정지 처리됩니다
            </div>
        </div>
        <div class="hr"></div>
        <div class="example-box">
            <div class="example-title" v-html="'호감을 많이 받은 소개 글을 참고해보세요!'" />
            <div v-if="genderName === 'MALE'" class="example-photo">
                <img class="example" src="@/assets/images/ex-m-1.png" />
                <img class="example" src="@/assets/images/ex-m-2.png" />
            </div>
            <div v-else class="example-photo">
                <img class="example" src="@/assets/images/ex-w-1.png" />
                <img class="example" src="@/assets/images/ex-w-2.png" />
            </div>
        </div>
        <BottomButton :label="$translate('NEXT')" @click="clickCTA" :disabled="disabled" />
    </div>
</template>

<script>
import ProgressBar from '@/routes/signup/components/ProgressBar'
export default {
    name: 'IntroduceMyselfPage',
    data: () => ({
        content: '',
        testGroup: null,
    }),
    components: {
        ProgressBar,
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        buttons() {
            return [
                {
                    label: this.$translate('NEXT'),
                    class: ['btn-primary', 'm-b-8'],
                    disabled: this.disabled,
                },
                {
                    label: this.$translate('PASS'),
                    class: 'btn-normal',
                    disabled: false,
                },
            ]
        },
        genderName() {
            const gender = JSON.parse(window.localStorage.getItem('signupData') || '{}').gender
            return (gender || {}).name
        },
        disabled() {
            return this.content.length < 1
        },
        signupData() {
            return JSON.parse(window.localStorage.getItem('signupData') || '{}')
        },
    },
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_IntroPage',
            },
        })
        this.$hackleClient.onReady(() => {
            const hackleUser = this.$store.getters.hackleUser
            this.$hackleClient.track(`Enter_IntroPage`, hackleUser)
        })

        if (this.signupData.intro) this.content = this.signupData.intro

        this.$bus.$on('clickSkipButton', () => this.clickCTA(true))
    },
    beforeDestroy() {
        this.$bus.$off('clickSkipButton')
    },
    methods: {
        clickCTA() {
            const signupData = this.signupData
            signupData.intro = this.content
            window.localStorage.setItem('signupData', JSON.stringify(signupData))
            this.$router.push({ name: 'NicknamePage' })
        },
    },
}
</script>

<style lang="scss" scoped>
.introduce-myself {
    .content {
        padding: 16px;

        ::v-deep textarea {
            height: 20vh !important;
        }

        .texts {
            .text-count {
                text-align: right;
                color: $purple-primary;
                font-size: 12px;
                margin-bottom: 8px;
                @include f-medium;

                &.disabled {
                    color: $pink-deep;
                }
            }
        }
        .warning {
            font-size: 12px;
            color: $grey-06;
            margin-top: 8px;
            text-align-last: center;
            span {
                @include f-bold;
            }
        }
        ::v-deep textarea {
            height: 48vh;
        }
    }
    .hr {
        margin: 16px 0 32px 0;
        border-bottom: 1px solid $grey-02;
        // height: 1px;
        // background: $grey-02;
    }

    .example-box {
        padding: 0 20px;
        margin-bottom: 80px;

        .example-title {
            font-size: 18px;
            @include f-medium;
            color: black;
            margin-bottom: 16px;
        }
        .example {
            width: 100%;
            margin-bottom: 12px;
        }
    }
    .test-b {
        ::v-deep textarea {
            //height: 250px !important;
        }
    }
}
</style>
